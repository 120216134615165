import * as React from "react";
import { CharacterSet } from "../graphics-templates/CharacterSet";
import { Screen } from "../graphics-templates/Screen";
import { PersistentScroller } from "./PersistentScroller";
import { logMajorComponentRender } from "../../classes/Logger";


export const Screens: React.FunctionComponent = () => {

    logMajorComponentRender(Screens.name);

    const renderCharsets = (bank: number) => {

        let baseIndex = (3 - bank) * 0x08;

        return (
            <div>
                <h3>Charsets</h3>
                <CharacterSet startAddress={(baseIndex + 0x00) * 0x0800} />
                <CharacterSet startAddress={(baseIndex + 0x01) * 0x0800} />
                <CharacterSet startAddress={(baseIndex + 0x02) * 0x0800} />
                <CharacterSet startAddress={(baseIndex + 0x03) * 0x0800} />
                <CharacterSet startAddress={(baseIndex + 0x04) * 0x0800} />
                <CharacterSet startAddress={(baseIndex + 0x05) * 0x0800} />
                <CharacterSet startAddress={(baseIndex + 0x06) * 0x0800} />
                <CharacterSet startAddress={(baseIndex + 0x07) * 0x0800} />
            </div>
        );
    }

    const renderScreens = (bank: number) => {
        let baseIndex = (3 - bank) * 0x10;

        return (
            <div>
                <h3>Screens</h3>
                <Screen startAddress={(baseIndex + 0x00) * 0x0400} />
                <Screen startAddress={(baseIndex + 0x01) * 0x0400} />
                <Screen startAddress={(baseIndex + 0x02) * 0x0400} />
                <Screen startAddress={(baseIndex + 0x03) * 0x0400} />
                <Screen startAddress={(baseIndex + 0x04) * 0x0400} />
                <Screen startAddress={(baseIndex + 0x05) * 0x0400} />
                <Screen startAddress={(baseIndex + 0x06) * 0x0400} />
                <Screen startAddress={(baseIndex + 0x07) * 0x0400} />
                <Screen startAddress={(baseIndex + 0x08) * 0x0400} />
                <Screen startAddress={(baseIndex + 0x09) * 0x0400} />
                <Screen startAddress={(baseIndex + 0x0a) * 0x0400} />
                <Screen startAddress={(baseIndex + 0x0b) * 0x0400} />
                <Screen startAddress={(baseIndex + 0x0c) * 0x0400} />
                <Screen startAddress={(baseIndex + 0x0d) * 0x0400} />
                <Screen startAddress={(baseIndex + 0x0e) * 0x0400} />
                <Screen startAddress={(baseIndex + 0x0f) * 0x0400} />
            </div>
        );
    }


    return (
        <PersistentScroller name="screen">
            <h2>Bank 3 : $0000-$3fff</h2>
            {renderCharsets(3)}
            {renderScreens(3)}
            <h2>Bank 2 : $4000-$7fff</h2>
            {renderCharsets(2)}
            {renderScreens(2)}
            <h2>Bank 1 : $8000-$bfff</h2>
            {renderCharsets(1)}
            {renderScreens(1)}
            <h2>Bank 0 : $c000-$ffff</h2>
            {renderCharsets(0)}
            {renderScreens(0)}
        </PersistentScroller>
    );
}
import React, { ReactNode } from 'react';
import { SettingsSection } from './SettingsSection'
import { SettingsGroup } from './SettingsGroup';
import { logMajorComponentRender } from '../../classes/Logger';


export const PlaceholderSettingsSection: React.FunctionComponent<{ children: ReactNode }> = (props) => {

    logMajorComponentRender(PlaceholderSettingsSection.name);

    return (
        <SettingsSection name="Details">
            <SettingsGroup>
                <p>{props.children}</p>
            </SettingsGroup>
        </SettingsSection>
    );
}
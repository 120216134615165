import React, { CSSProperties, useEffect, useMemo, useRef } from 'react';
import { GraphicData } from '../../classes/graphics/GraphicData';
import { ViewportLocation } from '../../store/rendererSlice';
import { useOnDraw } from '../../classes/effects/useOnDraw';
import { Utils } from '../../classes/Utils';
import { GraphicCommand } from '../../classes/commands/GraphicCommand';
import { logMinorComponentRender } from '../../classes/Logger';

export type GraphicProps = {
    settings: GraphicCommand,
    location: ViewportLocation,
    focusable: boolean,
    onClick?: (s: GraphicCommand) => void,
    onDoubleClick?: () => void,
    fitToSize?: boolean,
    showLabel?: boolean
}


export const Graphic: React.FunctionComponent<GraphicProps> = (props) => {

    logMinorComponentRender(Graphic.name);

    const divRefSource = useRef<HTMLDivElement>(null);
    const dataRef = useRef<GraphicData>({
        location: props.location,
        left: 0,
        top: 0,
        width: 0,
        height: 0
    });

    const parentAspect = useRef(1);

    const { settings, focusable } = props;

    useEffect(() => {

        const parentRect = divRefSource.current?.parentElement?.parentElement?.getBoundingClientRect();
        if (parentRect == null) { return; }

        parentAspect.current = parentRect.height / parentRect.width;
    }, [divRefSource]);


    useEffect(() => {

        const navigate = (e: CustomEvent<number>) => {

            if (!focusable) { return; }
            if (settings == null) { return; }

            const address = e.detail;
            const gfxStartAddress = settings.address;
            const gfxEndAddress = settings.address + settings.countBytes - 1;
            const shouldBeSelected = (address >= gfxStartAddress) && (address <= gfxEndAddress);

            if (shouldBeSelected) {
                divRefSource.current?.scrollIntoView({ behavior: "smooth", block: "center" });
            }
        }

        document.addEventListener<any>('navigate-to-address', navigate);

        return () => {
            document.removeEventListener<any>('navigate-to-address', navigate);
        }
    }, [divRefSource, settings, focusable]);

    const onDraw = useMemo(() => () => {

        if (divRefSource.current == null || dataRef.current == null) {
            return undefined;
        }

        if (settings == null) { return undefined; }

        const data = dataRef.current;
        const rect = divRefSource.current.getBoundingClientRect();
        data.left = rect.left;
        data.top = rect.top;
        data.width = rect.width;
        data.height = rect.height;
        data.settings = settings ?? undefined;

        return data;

    }, [divRefSource, dataRef, settings]);
    useOnDraw(onDraw);

    let timer: NodeJS.Timeout;

    const onClick = (e: React.MouseEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();

        const { onClick } = props;

        if (onClick == null) { return; }

        if (props.onDoubleClick == null) { onClick(settings); return; }

        if (e.detail !== 1) { return; }
        timer = setTimeout(() => onClick(settings), 200);
    }

    const onDoubleClick = (e: React.MouseEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();

        clearTimeout(timer);

        if (props.onDoubleClick != null) { props.onDoubleClick(); }
    }

    const address = settings?.address ?? 0;
    const countBytes = settings?.countBytes ?? 0;
    const label = `${Utils.to4DigitHexString(address)}+${Utils.toHexAuto(countBytes)}`;

    const scale = settings?.scale ?? 1;

    let fitToSize = false;
    if (props.fitToSize != null) {
        fitToSize = props.fitToSize;
    }

    let showLabel = true;
    if (props.showLabel != null) {
        showLabel = props.showLabel;
    }

    const widthPx = settings?.widthPx ?? 1;
    const heightPx = settings?.heightPx ?? 1;

    if (fitToSize) {

        const aspect = (widthPx / heightPx) * parentAspect.current;
        const height = aspect <= 1 ? "100%" : `${100 * 1 / aspect}%`;
        const width = aspect <= 1 ? `${100 * aspect}%` : "100%";

        return (
            <div style={{ height: height, width: width, display: "inline-block" }} ref={divRefSource} onClick={onClick} onDoubleClick={onDoubleClick} />
        );
    }
    else {

        const outerStyle: CSSProperties = { display: "inline-block", margin: '2px', padding: '0px', fontSize: '0.75em', textAlign: 'left' };
        const innerStyle: CSSProperties = { width: `${widthPx * scale}px`, height: `${heightPx * scale}px` };

        return (
            <div style={outerStyle}>
                {showLabel && <div>{label}</div>}
                <div style={innerStyle} ref={divRefSource} onClick={onClick} onDoubleClick={onDoubleClick} />
            </div>
        );
    }
}

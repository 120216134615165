import React, { MouseEvent } from 'react';
import { Graphic } from '../graphics/Graphic';
import { commandAdded } from '../../store/projectSlice';
import { GraphicCommand } from '../../classes/commands/GraphicCommand';
import { selectActiveTool } from '../../store/toolSlice';
import { ViewportLocation } from '../../store/rendererSlice';
import styles from './PreviewSettingsSection.module.css';
import { previewCommandSelector } from '../../store/graphicsSlice';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { logMajorComponentRender } from '../../classes/Logger';


export const PreviewSettingsSection: React.FunctionComponent = () => {

    logMajorComponentRender(PreviewSettingsSection.name);

    const dispatch = useAppDispatch();

    const activeTool = useAppSelector(selectActiveTool);

    const previewCommand = useAppSelector(previewCommandSelector) ?? undefined;
    if (previewCommand == null) { return null; }

    const handleAdd = (e: MouseEvent, commandToAdd?: GraphicCommand) => {
        e.preventDefault();

        if (commandToAdd == null) { return; }

        dispatch(commandAdded(commandToAdd));
    }

    const disableAddButton = activeTool === 'Map';

    return (
        <div className={styles.panel}>

            <div className={styles.preview}>
                <Graphic settings={previewCommand} location={ViewportLocation.Preview} fitToSize={true} focusable={false} />
            </div>

            <div className={styles.controls}>
                <button className={styles.button} onClick={e => handleAdd(e, previewCommand)} disabled={disableAddButton}>+</button>
            </div>

        </div>
    );
}
import React from 'react';
import { SettingsSection } from './SettingsSection'
import { SettingsGroup } from './SettingsGroup';
import { EnumSetting } from './EnumSetting';
import { MarkedRegionsAppearance, GraphicsState, selectSelectionWidth } from '../../store/graphicsSlice';
import { characterMappedModeSet, interleavedModeSet, continuousModeSet, viewLayoutSet, markedRegionsAppearanceSet, scaleSet, selectionWidthSet } from '../../store/graphicsSlice';
import { AppDispatch } from '../../store';
import { HexNumberInput } from './HexNumberInput';
import { ColourMode } from '../../classes/graphics/GraphicsEnums';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { logMajorComponentRender } from '../../classes/Logger';


const getModeSettings = (settings: GraphicsState, dispatch: AppDispatch) => {

    let order: string[] = ['hires', 'multicol', 'extended'];
    let options: { [label: string]: ColourMode } = {};
    let selectedOption: ColourMode;
    let onChange: (v: ColourMode) => void;

    options['hires'] = 'HiRes';
    options['multicol'] = 'MultiColour';

    switch (settings.ViewLayout) {
        case 'CharacterMapped':
            options['extended'] = 'Extended';
            selectedOption = settings.CharacterMappedMode;
            onChange = v => dispatch(characterMappedModeSet(v));
            break;

        case 'Interleaved':
            selectedOption = settings.InterleavedMode;
            onChange = v => dispatch(interleavedModeSet(v));
            break;

        case 'Continuous':
        default:
            selectedOption = settings.ContinuousMode;
            onChange = v => dispatch(continuousModeSet(v));
            break;
    }

    return { order, options, selectedOption, onChange };
}


export const ViewSettingsSection: React.FunctionComponent = () => {

    logMajorComponentRender(ViewSettingsSection.name);

    const settings = useAppSelector(state => state.graphics);
    const { selectionWidth, layout } = useAppSelector(selectSelectionWidth);
    const dispatch = useAppDispatch();

    const scale = settings.ViewLayout === 'CharacterMapped' ? settings.CharacterMappedScale : (
        settings.ViewLayout === 'Interleaved' ? settings.InterleavedScale : (
            settings.ViewLayout === 'Continuous' ? settings.ContinuousScale : (
                settings.CharacterMappedScale
            )
        )
    );

    const modes = getModeSettings(settings, dispatch);

    return (
        <SettingsSection name="View">

            <SettingsGroup>
                <EnumSetting
                    label="layout"
                    selectedOption={settings.ViewLayout}
                    options={{ 'charmap': 'CharacterMapped', 'bitmap': 'Interleaved', 'sprites': 'Continuous' }}
                    order={['charmap', 'bitmap', 'sprites']}
                    onChange={v => dispatch(viewLayoutSet(v))}
                />
                <EnumSetting
                    label="mode"
                    selectedOption={modes.selectedOption}
                    options={modes.options}
                    order={modes.order}
                    onChange={modes.onChange}
                />
            </SettingsGroup>

            <SettingsGroup>
                <HexNumberInput
                    enabled={true}
                    label="width"
                    number={selectionWidth}
                    unit="chars"
                    onChange={v => dispatch(selectionWidthSet({ width: v, layout }))}
                    forceFourDigits={false}
                />
            </SettingsGroup>

            <SettingsGroup>
                <EnumSetting
                    label="scale"
                    selectedOption={scale}
                    options={{ '1x': 1, '2x': 2, '3x': 3, '4x': 4, '5x': 5, '6x': 6, '7x': 7, '8x': 8 }}
                    order={['1x', '2x', '3x', '4x', '5x', '6x', '7x', '8x']}
                    onChange={scale => dispatch(scaleSet({ layout: settings.ViewLayout, scale }))}
                />
            </SettingsGroup>

            <SettingsGroup>
                <EnumSetting
                    label="marked regions"
                    selectedOption={settings.MarkedRegionsAppearance}
                    options={{ 'normal': MarkedRegionsAppearance.Normal, 'dimmed': MarkedRegionsAppearance.Dimmed }}
                    order={['normal', 'dimmed']}
                    onChange={v => dispatch(markedRegionsAppearanceSet(v))}
                />
            </SettingsGroup>

        </SettingsSection>
    );
}
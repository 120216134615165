import { newCommentCommand } from "./commands/CommentCommandHelpers";
import { newDataCommand } from "./commands/DataCommandHelpers";
import { newEntryPointCommand } from "./commands/EntryPointHelpers";
import { newGraphicCommand } from "./commands/GraphicCommandHelpers";
import { newLabelCommand } from "./commands/LabelCommandHelpers";
import { newPointerCommand } from "./commands/PointerCommandHelpers";
import { Command } from "./commands/types";
import { Palette } from "./Palette";

const createZeroedNumberArray = (count: number) => {
    const data = new Array<number>(count);
    for (let index = 0; index < data.length; index++) {
        data[index] = 0x00;
    }

    return data;
}

const setCircle1 = (id: number, base: number, data: number[]) => {
    data[base + (id * 8) + 0x00] = 0b00011000;
    data[base + (id * 8) + 0x01] = 0b01111110;
    data[base + (id * 8) + 0x02] = 0b11111111;
    data[base + (id * 8) + 0x03] = 0b11111111;
    data[base + (id * 8) + 0x04] = 0b11111111;
    data[base + (id * 8) + 0x05] = 0b11111111;
    data[base + (id * 8) + 0x06] = 0b01111110;
    data[base + (id * 8) + 0x07] = 0b00011000;

    return id;
}

const setChecks = (id: number, base: number, data: number[]) => {
    data[base + (id * 8) + 0x00] = 0b00000101;
    data[base + (id * 8) + 0x01] = 0b00000101;
    data[base + (id * 8) + 0x02] = 0b00000101;
    data[base + (id * 8) + 0x03] = 0b00000101;
    data[base + (id * 8) + 0x04] = 0b10101111;
    data[base + (id * 8) + 0x05] = 0b10101111;
    data[base + (id * 8) + 0x06] = 0b10101111;
    data[base + (id * 8) + 0x07] = 0b10101111;

    return id;
}

const setExclamation = (id: number, base: number, data: number[]) => {
    data[base + (id * 8) + 0x00] = 0b00011000;
    data[base + (id * 8) + 0x01] = 0b00011000;
    data[base + (id * 8) + 0x02] = 0b00011000;
    data[base + (id * 8) + 0x03] = 0b00011000;
    data[base + (id * 8) + 0x04] = 0b00011000;
    data[base + (id * 8) + 0x05] = 0b00000000;
    data[base + (id * 8) + 0x06] = 0b00011000;
    data[base + (id * 8) + 0x07] = 0b00011000;

    return id;
}

const setCross = (id: number, base: number, data: number[]) => {
    data[base + (id * 8) + 0x00] = 0b11000011;
    data[base + (id * 8) + 0x01] = 0b01100110;
    data[base + (id * 8) + 0x02] = 0b00110110;
    data[base + (id * 8) + 0x03] = 0b00011100;
    data[base + (id * 8) + 0x04] = 0b00111000;
    data[base + (id * 8) + 0x05] = 0b01101100;
    data[base + (id * 8) + 0x06] = 0b01100110;
    data[base + (id * 8) + 0x07] = 0b11000011;

    return id;
}


export const PrepareCommands = () => {

    const RAM = createZeroedNumberArray(0x10000);
    const COLRAM = createZeroedNumberArray(0x03e8);
    const commands: Command[] = [];

    const baseScreen = 0x0400;
    const baseColours = 0x0800;
    const baseSprites = 0x0c00;
    const baseCharset = 0x1000;
    const baseBitmap = 0x2000;
    const baseCOLRAM = 0x0000;


    // -------------------------------------------
    //
    // INTERLEAVED HIRES TESTS
    //
    // -------------------------------------------

    const offsetBitmapHires = 0x00;
    const offsetBitmapHiresScreen = 0x10;
    const offsetBitmapMultiColours = offsetBitmapHiresScreen + 0x04;
    setCross(0x00, baseBitmap + offsetBitmapHires, RAM);
    setCross(0x01, baseBitmap + offsetBitmapHires, RAM);
    setCross(0x02, baseBitmap + offsetBitmapHires, RAM);
    setCross(0x03, baseBitmap + offsetBitmapHires, RAM);
    RAM[baseScreen + offsetBitmapHiresScreen + 0x00] = Palette.White.index + (Palette.Red.index << 4);
    RAM[baseScreen + offsetBitmapHiresScreen + 0x01] = Palette.Green.index + (Palette.Yellow.index << 4);
    RAM[baseScreen + offsetBitmapHiresScreen + 0x02] = Palette.Brown.index + (Palette.Orange.index << 4);
    RAM[baseScreen + offsetBitmapHiresScreen + 0x03] = Palette.Blue.index + (Palette.LightBlue.index << 4);
    RAM[baseColours + offsetBitmapMultiColours + 0x00] = Palette.Black.index;
    RAM[baseColours + offsetBitmapMultiColours + 0x01] = Palette.DarkGrey.index;
    RAM[baseColours + offsetBitmapMultiColours + 0x02] = Palette.Grey.index;
    RAM[baseColours + offsetBitmapMultiColours + 0x03] = Palette.LightGrey.index;
    COLRAM[baseCOLRAM + offsetBitmapHiresScreen + 0x00] = Palette.DarkGrey.index;
    COLRAM[baseCOLRAM + offsetBitmapHiresScreen + 0x01] = Palette.Grey.index;
    COLRAM[baseCOLRAM + offsetBitmapHiresScreen + 0x02] = Palette.LightGrey.index;
    COLRAM[baseCOLRAM + offsetBitmapHiresScreen + 0x03] = Palette.White.index;

    // Interleaved, hires, fixed colours
    commands.push(newGraphicCommand({
        graphicsType: 'InterleavedHiresFixed',
        address: baseBitmap + offsetBitmapHires,
        countBytes: 0x20,
        widthPx: 0x20,
        heightPx: 0x08,
        colour00: Palette.Green.index,
        colour01: Palette.LightGreen.index,
        scale: 4
    })!);

    // Interleavevd, hires, colours from screen RAM
    commands.push(newGraphicCommand({
        graphicsType: 'InterleavedHiresRAM',
        address: baseBitmap + offsetBitmapHires,
        countBytes: 0x20,
        widthPx: 0x20,
        heightPx: 0x08,
        referenceAddress1: baseScreen + offsetBitmapHiresScreen,
        scale: 4
    })!);


    // -------------------------------------------
    //
    // INTERLEAVED MULTICOLOUR TESTS
    //
    // -------------------------------------------

    const offsetBitmapMulti = offsetBitmapHires + 0x20;
    setChecks(0x00, baseBitmap + offsetBitmapMulti, RAM);
    setChecks(0x01, baseBitmap + offsetBitmapMulti, RAM);
    setChecks(0x02, baseBitmap + offsetBitmapMulti, RAM);
    setChecks(0x03, baseBitmap + offsetBitmapMulti, RAM);

    // Interleavevd, multicolour, all colours fixed
    commands.push(newGraphicCommand({
        graphicsType: 'InterleavedMultiColourFixedFixed',
        address: baseBitmap + offsetBitmapMulti,
        countBytes: 0x20,
        widthPx: 0x20,
        heightPx: 0x08,
        colour00: Palette.Magenta.index,
        colour01: Palette.Yellow.index,
        colour10: Palette.LightBlue.index,
        colour11: Palette.Cyan.index,
        scale: 4
    })!);

    // Interleavevd, multicolour, 11 colour from RAM
    commands.push(newGraphicCommand({
        graphicsType: 'InterleavedMultiColourFixedRAM',
        address: baseBitmap + offsetBitmapMulti,
        countBytes: 0x20,
        widthPx: 0x20,
        heightPx: 0x08,
        referenceAddress2: baseColours + offsetBitmapMultiColours,
        colour00: Palette.Magenta.index,
        colour01: Palette.Yellow.index,
        colour10: Palette.LightBlue.index,
        scale: 4
    })!);

    // Interleavevd, multicolour, 11 colour from COLRAM
    commands.push(newGraphicCommand({
        graphicsType: 'InterleavedMultiColourFixedCOLRAM',
        address: baseBitmap + offsetBitmapMulti,
        countBytes: 0x20,
        widthPx: 0x20,
        heightPx: 0x08,
        referenceAddress2: baseCOLRAM + offsetBitmapHiresScreen,
        colour00: Palette.Magenta.index,
        colour01: Palette.Yellow.index,
        colour10: Palette.LightBlue.index,
        scale: 4
    })!);

    // Interleavevd, multicolour, 01/10 colours from RAM, 11 colour fixed
    commands.push(newGraphicCommand({
        graphicsType: 'InterleavedMultiColourRAMFixed',
        address: baseBitmap + offsetBitmapMulti,
        countBytes: 0x20,
        widthPx: 0x20,
        heightPx: 0x08,
        referenceAddress1: baseScreen + offsetBitmapHiresScreen,
        colour00: Palette.Magenta.index,
        colour11: Palette.Cyan.index,
        scale: 4
    })!);

    // Interleavevd, multicolour, 01/10 colours from RAM, 11 colour from COLRAM
    commands.push(newGraphicCommand({
        graphicsType: 'InterleavedMultiColourRAMCOLRAM',
        address: baseBitmap + offsetBitmapMulti,
        countBytes: 0x20,
        widthPx: 0x20,
        heightPx: 0x08,
        referenceAddress1: baseScreen + offsetBitmapHiresScreen,
        referenceAddress2: baseCOLRAM + offsetBitmapHiresScreen,
        colour00: Palette.Magenta.index,
        scale: 4
    })!);

    // Interleavevd, multicolour, 01/10 colours from RAM, 11 colour from COLRAM
    commands.push(newGraphicCommand({
        graphicsType: 'InterleavedMultiColourRAMRAM',
        address: baseBitmap + offsetBitmapMulti,
        countBytes: 0x20,
        widthPx: 0x20,
        heightPx: 0x08,
        referenceAddress1: baseScreen + offsetBitmapHiresScreen,
        referenceAddress2: baseColours + offsetBitmapMultiColours,
        colour00: Palette.Magenta.index,
        scale: 4
    })!);


    // -------------------------------------------
    //
    // CHARMAPPED HIRES TESTS
    //
    // -------------------------------------------

    const refCircle1 = setCircle1(0x04, baseCharset, RAM);
    const refChecks = setChecks(0x08, baseCharset, RAM);
    const refExclamation = setExclamation(0x09, baseCharset, RAM);
    const refExclamationECB01 = setExclamation(0x49, baseCharset, RAM);
    const refExclamationECB10 = setExclamation(0x89, baseCharset, RAM);
    const refExclamationECB11 = setExclamation(0xc9, baseCharset, RAM);

    const offsetCharHires = 0x00;
    RAM[baseScreen + offsetCharHires + 0x00] = refCircle1;
    RAM[baseScreen + offsetCharHires + 0x01] = refCircle1;
    RAM[baseScreen + offsetCharHires + 0x02] = refCircle1;
    RAM[baseScreen + offsetCharHires + 0x03] = refCircle1;
    COLRAM[baseCOLRAM + offsetCharHires + 0x00] = Palette.White.index;
    COLRAM[baseCOLRAM + offsetCharHires + 0x01] = Palette.Red.index;
    COLRAM[baseCOLRAM + offsetCharHires + 0x02] = Palette.Cyan.index;
    COLRAM[baseCOLRAM + offsetCharHires + 0x03] = Palette.Magenta.index;
    RAM[baseColours + offsetCharHires + 0x00] = Palette.Red.index;
    RAM[baseColours + offsetCharHires + 0x01] = Palette.Cyan.index;
    RAM[baseColours + offsetCharHires + 0x02] = Palette.Magenta.index;
    RAM[baseColours + offsetCharHires + 0x03] = Palette.Green.index;

    // Char mapped, hires, fixed colours
    commands.push(newGraphicCommand({
        graphicsType: 'CharacterMappedHiresFixed',
        address: baseScreen + offsetCharHires,
        countBytes: 0x04,
        widthPx: 0x20,
        heightPx: 0x08,
        referenceAddress1: baseCharset,
        colour00: Palette.Green.index,
        colour01: Palette.LightGreen.index,
        scale: 4
    })!);

    // Char mapped, hires, 1 colour from colour ram
    commands.push(newGraphicCommand({
        graphicsType: 'CharacterMappedHiresCOLRAM',
        address: baseScreen + offsetCharHires,
        countBytes: 0x04,
        widthPx: 0x20,
        heightPx: 0x08,
        referenceAddress1: baseCharset,
        referenceAddress2: baseCOLRAM + offsetCharHires,
        colour00: Palette.Black.index,
        scale: 4
    })!);

    // Char mapped, hires, 1 colour from main ram
    commands.push(newGraphicCommand({
        graphicsType: 'CharacterMappedHiresRAM',
        address: baseScreen + offsetCharHires,
        countBytes: 0x04,
        widthPx: 0x20,
        heightPx: 0x08,
        referenceAddress1: baseCharset,
        referenceAddress2: baseColours + offsetCharHires,
        colour00: Palette.Black.index,
        scale: 4
    })!);


    // -------------------------------------------
    //
    // CHARMAPPED MULTICOLOUR TESTS
    //
    // -------------------------------------------

    const offsetCharMulti = offsetCharHires + 0x04;
    RAM[baseScreen + offsetCharMulti + 0x00] = refChecks;
    RAM[baseScreen + offsetCharMulti + 0x01] = refChecks;
    RAM[baseScreen + offsetCharMulti + 0x02] = refChecks;
    RAM[baseScreen + offsetCharMulti + 0x03] = refChecks;
    COLRAM[baseCOLRAM + offsetCharMulti + 0x00] = Palette.Red.index;
    COLRAM[baseCOLRAM + offsetCharMulti + 0x01] = Palette.Cyan.index;
    COLRAM[baseCOLRAM + offsetCharMulti + 0x02] = Palette.LightRed.index;
    COLRAM[baseCOLRAM + offsetCharMulti + 0x03] = Palette.DarkGrey.index;
    RAM[baseColours + offsetCharMulti + 0x00] = Palette.Cyan.index;
    RAM[baseColours + offsetCharMulti + 0x01] = Palette.Magenta.index;
    RAM[baseColours + offsetCharMulti + 0x02] = Palette.DarkGrey.index;
    RAM[baseColours + offsetCharMulti + 0x03] = Palette.Grey.index;

    // Char mapped, multicol, fixed colours with foreground col in 0-7
    commands.push(newGraphicCommand({
        graphicsType: 'CharacterMappedMultiColourFixed',
        address: baseScreen + offsetCharMulti,
        countBytes: 0x04,
        widthPx: 0x20,
        heightPx: 0x08,
        referenceAddress1: baseCharset,
        colour00: Palette.Green.index,
        colour01: Palette.Yellow.index,
        colour10: Palette.LightBlue.index,
        colour11: Palette.Red.index,
        scale: 4
    })!);

    // Char mapped, multicol, fixed colours with foreground col in 8-15
    commands.push(newGraphicCommand({
        graphicsType: 'CharacterMappedMultiColourFixed',
        address: baseScreen + offsetCharMulti,
        countBytes: 0x04,
        widthPx: 0x20,
        heightPx: 0x08,
        referenceAddress1: baseCharset,
        colour00: Palette.Green.index,
        colour01: Palette.Yellow.index,
        colour10: Palette.LightBlue.index,
        colour11: Palette.LightRed.index,
        scale: 4
    })!);

    // Char mapped, multicol, 11 colour from colour ram
    commands.push(newGraphicCommand({
        graphicsType: 'CharacterMappedMultiColourCOLRAM',
        address: baseScreen + offsetCharMulti,
        countBytes: 0x04,
        widthPx: 0x20,
        heightPx: 0x08,
        referenceAddress1: baseCharset,
        referenceAddress2: baseCOLRAM + offsetCharMulti,
        colour00: Palette.Green.index,
        colour01: Palette.Yellow.index,
        colour10: Palette.LightBlue.index,
        scale: 4
    })!);

    // Char mapped, multicol, 11 colour from main ram
    commands.push(newGraphicCommand({
        graphicsType: 'CharacterMappedMultiColourRAM',
        address: baseScreen + offsetCharMulti,
        countBytes: 0x04,
        widthPx: 0x20,
        heightPx: 0x08,
        referenceAddress1: baseCharset,
        referenceAddress2: baseColours + offsetCharMulti,
        colour00: Palette.Green.index,
        colour01: Palette.Yellow.index,
        colour10: Palette.LightBlue.index,
        scale: 4
    })!);


    // -------------------------------------------
    //
    // CHARMAPPED EXTENDED COLOUR MODE TESTS
    //
    // -------------------------------------------
    const offsetCharECM = offsetCharMulti + 0x04;
    RAM[baseScreen + offsetCharECM + 0x00] = refExclamation;
    RAM[baseScreen + offsetCharECM + 0x01] = refExclamationECB01;
    RAM[baseScreen + offsetCharECM + 0x02] = refExclamationECB10;
    RAM[baseScreen + offsetCharECM + 0x03] = refExclamationECB11;
    COLRAM[baseCOLRAM + offsetCharECM + 0x00] = Palette.Red.index;
    COLRAM[baseCOLRAM + offsetCharECM + 0x01] = Palette.Cyan.index;
    COLRAM[baseCOLRAM + offsetCharECM + 0x02] = Palette.Magenta.index;
    COLRAM[baseCOLRAM + offsetCharECM + 0x03] = Palette.Green.index;
    RAM[baseColours + offsetCharECM + 0x00] = Palette.Cyan.index;
    RAM[baseColours + offsetCharECM + 0x01] = Palette.Magenta.index;
    RAM[baseColours + offsetCharECM + 0x02] = Palette.Green.index;
    RAM[baseColours + offsetCharECM + 0x03] = Palette.Blue.index;

    // Char mapped, extended, fixed foreground colour
    commands.push(newGraphicCommand({
        graphicsType: 'CharacterMappedExtendedFixed',
        address: baseScreen + offsetCharECM,
        countBytes: 0x04,
        widthPx: 0x20,
        heightPx: 0x08,
        referenceAddress1: baseCharset,
        colour00: Palette.Grey.index,
        colour01: Palette.Yellow.index,
        colour10: Palette.LightBlue.index,
        colour11: Palette.Orange.index,
        extraColour: Palette.LightGreen.index,
        scale: 4
    })!);

    // Char mapped, extended, foreground colour from COLRAM
    commands.push(newGraphicCommand({
        graphicsType: 'CharacterMappedExtendedCOLRAM',
        address: baseScreen + offsetCharECM,
        countBytes: 0x4,
        widthPx: 0x20,
        heightPx: 0x08,
        referenceAddress1: baseCharset,
        referenceAddress2: baseCOLRAM + offsetCharECM,
        colour00: Palette.Grey.index,
        colour01: Palette.Yellow.index,
        colour10: Palette.LightBlue.index,
        colour11: Palette.Orange.index,
        scale: 4
    })!);

    // Char mapped, extended, foreground colour from RAM
    commands.push(newGraphicCommand({
        graphicsType: 'CharacterMappedExtendedRAM',
        address: baseScreen + offsetCharECM,
        countBytes: 0x4,
        widthPx: 0x20,
        heightPx: 0x08,
        referenceAddress1: baseCharset,
        referenceAddress2: baseColours + offsetCharECM,
        colour00: Palette.Grey.index,
        colour01: Palette.Yellow.index,
        colour10: Palette.LightBlue.index,
        colour11: Palette.Orange.index,
        scale: 4
    })!);


    // -------------------------------------------
    //
    // CONTINUOUS HIRES TESTS
    //
    // -------------------------------------------

    const offsetSpriteHiresA = 0x00;
    const spriteHiresA: number[] = [];
    spriteHiresA.push(...[0b11111111, 0b11111111, 0b11111111]);
    spriteHiresA.push(...[0b10000000, 0b00000000, 0b00000001]);
    spriteHiresA.push(...[0b10000000, 0b00000000, 0b00000001]);
    spriteHiresA.push(...[0b10000000, 0b00000000, 0b00000001]);
    spriteHiresA.push(...[0b10000000, 0b00000000, 0b00000001]);
    spriteHiresA.push(...[0b10000000, 0b00000000, 0b00000001]);
    spriteHiresA.push(...[0b10000000, 0b00000000, 0b00000001]);
    spriteHiresA.push(...[0b10000000, 0b11111111, 0b00000001]);
    spriteHiresA.push(...[0b10000000, 0b10000001, 0b00000001]);
    spriteHiresA.push(...[0b10000000, 0b10000001, 0b00000001]);
    spriteHiresA.push(...[0b10000000, 0b10000001, 0b00000001]);
    spriteHiresA.push(...[0b10000000, 0b10000001, 0b00000001]);
    spriteHiresA.push(...[0b10000000, 0b10000001, 0b00000001]);
    spriteHiresA.push(...[0b10000000, 0b11111111, 0b00000001]);
    spriteHiresA.push(...[0b10000000, 0b00000000, 0b00000001]);
    spriteHiresA.push(...[0b10000000, 0b00000000, 0b00000001]);
    spriteHiresA.push(...[0b10000000, 0b00000000, 0b00000001]);
    spriteHiresA.push(...[0b10000000, 0b00000000, 0b00000001]);
    spriteHiresA.push(...[0b10000000, 0b00000000, 0b00000001]);
    spriteHiresA.push(...[0b10000000, 0b00000000, 0b00000001]);
    spriteHiresA.push(...[0b11111111, 0b11111111, 0b11111111]);
    spriteHiresA.forEach((b, i) => RAM[baseSprites + offsetSpriteHiresA + i] = b);

    // Continuous, hires, standard sprite size and offset
    commands.push(newGraphicCommand({
        graphicsType: 'ContinuousHires',
        address: baseSprites + offsetSpriteHiresA,
        countBytes: 0x3f,
        widthPx: 0x18,
        heightPx: 0x15,
        colour00: Palette.Grey.index,
        colour01: Palette.Yellow.index,
        scale: 3
    })!);


    // -------------------------------------------
    //
    // CONTINUOUS MULTICOLOUR TESTS
    //
    // -------------------------------------------

    const offsetSpriteMultiA = offsetSpriteHiresA + 0x40;
    const spriteMultiA: number[] = [];
    spriteMultiA.push(...[0b11111111, 0b11111111, 0b11111111]);
    spriteMultiA.push(...[0b11111111, 0b11111111, 0b11111111]);
    spriteMultiA.push(...[0b11010101, 0b10101010, 0b01010111]);
    spriteMultiA.push(...[0b11010101, 0b10101010, 0b01010111]);
    spriteMultiA.push(...[0b11010101, 0b10101010, 0b01010111]);
    spriteMultiA.push(...[0b11010101, 0b10101010, 0b01010111]);
    spriteMultiA.push(...[0b11010101, 0b10101010, 0b01010111]);
    spriteMultiA.push(...[0b11101010, 0b11111111, 0b10101011]);
    spriteMultiA.push(...[0b11101010, 0b11111111, 0b10101011]);
    spriteMultiA.push(...[0b11101010, 0b11000011, 0b10101011]);
    spriteMultiA.push(...[0b11101010, 0b11000011, 0b10101011]);
    spriteMultiA.push(...[0b11101010, 0b11000011, 0b10101011]);
    spriteMultiA.push(...[0b11101010, 0b11111111, 0b10101011]);
    spriteMultiA.push(...[0b11101010, 0b11111111, 0b10101011]);
    spriteMultiA.push(...[0b11010101, 0b10101010, 0b01010111]);
    spriteMultiA.push(...[0b11010101, 0b10101010, 0b01010111]);
    spriteMultiA.push(...[0b11010101, 0b10101010, 0b01010111]);
    spriteMultiA.push(...[0b11010101, 0b10101010, 0b01010111]);
    spriteMultiA.push(...[0b11010101, 0b10101010, 0b01010111]);
    spriteMultiA.push(...[0b11111111, 0b11111111, 0b11111111]);
    spriteMultiA.push(...[0b11111111, 0b11111111, 0b11111111]);
    spriteMultiA.forEach((b, i) => RAM[baseSprites + offsetSpriteMultiA + i] = b);

    const offsetSpriteMultiB = offsetSpriteMultiA + 0x40;
    const spriteMultiB: number[] = [];
    spriteMultiB.push(...[0b11111111, 0b11111111]);
    spriteMultiB.push(...[0b11111111, 0b11111111]);
    spriteMultiB.push(...[0b11000000, 0b00000011]);
    spriteMultiB.push(...[0b11000000, 0b00000011]);
    spriteMultiB.push(...[0b11000000, 0b00000011]);
    spriteMultiB.push(...[0b11000000, 0b00000011]);
    spriteMultiB.push(...[0b11000010, 0b01000011]);
    spriteMultiB.push(...[0b11000010, 0b01000011]);
    spriteMultiB.push(...[0b11000001, 0b10000011]);
    spriteMultiB.push(...[0b11000001, 0b10000011]);
    spriteMultiB.push(...[0b11000000, 0b00000011]);
    spriteMultiB.push(...[0b11000000, 0b00000011]);
    spriteMultiB.push(...[0b11000000, 0b00000011]);
    spriteMultiB.push(...[0b11000000, 0b00000011]);
    spriteMultiB.push(...[0b11111111, 0b11111111]);
    spriteMultiB.push(...[0b11111111, 0b11111111]);
    spriteMultiB.forEach((b, i) => RAM[baseSprites + offsetSpriteMultiB + 0x10 + i] = b);

    // Continuous, multicolour, standard sprite size and offset
    commands.push(newGraphicCommand({
        graphicsType: 'ContinuousMultiColour',
        address: baseSprites + offsetSpriteMultiA,
        countBytes: 0x3f,
        widthPx: 0x18,
        heightPx: 0x15,
        colour00: Palette.Grey.index,
        colour01: Palette.Cyan.index,
        colour10: Palette.Red.index,
        colour11: Palette.Yellow.index,
        scale: 3
    })!);

    // Continuous, multicolour, custom size and offset
    commands.push(newGraphicCommand({
        graphicsType: 'ContinuousMultiColour',
        address: baseSprites + offsetSpriteMultiB + 0x10,
        countBytes: 0x20,
        widthPx: 0x10,
        heightPx: 0x10,
        colour00: Palette.Grey.index,
        colour01: Palette.Cyan.index,
        colour10: Palette.Red.index,
        colour11: Palette.Yellow.index,
        scale: 3
    })!);

    // Code test : Spindizzy cheat
    const spindizzyCheatStart = 0x8000;
    const cheatStrAdd = spindizzyCheatStart + 26;
    const data = [
        0x78,                                       // SEI
        0xa9, 0x34,                                 // LDA #$34
        0x85, 0x01,                                 // STA $01
        0xa2, 0x06,                                 // LDX #$06
        0xbd, cheatStrAdd & 0xff, cheatStrAdd >> 8, // **: LDA $*+26,X
        0x9d, 0x00, 0xdf,                           // STA $DF00,X
        0xca,                                       // DEX
        0x10, 0xf7,                                 // BPL **
        0xa9, 0x37,                                 // LDA #$37
        0x85, 0x01,                                 // STA $01
        0x58,                                       // CLI
        0x60,                                       // RTS
        0x00, 0x00, 0x00, 0x00,
        0x49, 0x20, 0x4c, 0x49, 0x45, 0x44, 0x21    // 'i lied!
    ];
    for (let index = 0; index < data.length; index++) {
        RAM[spindizzyCheatStart + index] = data[index];
    }

    commands.push(newEntryPointCommand(spindizzyCheatStart));
    commands.push(newLabelCommand(spindizzyCheatStart, "Spindizzy cheat"));
    commands.push(newLabelCommand(spindizzyCheatStart + 0x07, "Compare char in string"));
    commands.push(newDataCommand(cheatStrAdd, 7, "Spindizzy cheat string"));
    commands.push(newCommentCommand(spindizzyCheatStart + 0x07, 'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less~ normal distribution of letters, as opposed to using'));
    commands.push(newCommentCommand(spindizzyCheatStart - 0x10, 'This is an example comment added to unknown space and it should look correct'));

    // Label and pointer test
    const labelTestStart = 0x0460;
    const labelTestLabelAddress = labelTestStart + 0x32;

    RAM[labelTestStart + 0x00] = labelTestLabelAddress & 0xff; // separated 8-bit lo reference
    RAM[labelTestStart + 0x02] = labelTestLabelAddress >> 8; // separated 8-bit hi reference

    RAM[labelTestStart + 0x08] = labelTestLabelAddress & 0xff; // 16-bit reference
    RAM[labelTestStart + 0x09] = labelTestLabelAddress >> 8;

    commands.push(newLabelCommand(labelTestLabelAddress, "Some Data Table"));
    commands.push(newPointerCommand(labelTestStart + 0x00, labelTestLabelAddress, "lo"));
    commands.push(newPointerCommand(labelTestStart + 0x02, labelTestLabelAddress, "hi"));
    commands.push(newPointerCommand(labelTestStart + 0x08, labelTestLabelAddress, "16bit"));
    commands.push(newPointerCommand(labelTestStart + 0x0f, labelTestLabelAddress, "lo")); // Should fail as memory value doesn't match target?

    testPointersInCode(0x04c0, RAM, commands);

    return { commands, RAM, COLRAM };
}

const testPointersInCode = (testStart: number, RAM: number[], commands: Command[]) => {

    const target = testStart + 0x20;
    const vector = testStart + 0x10;
    const vectorLo = vector;
    const vectorHi = vector + 1;

    const code = [
        0xa9, target & 0xff,                    // LDA #<target
        0x9d, vectorLo & 0xff, vectorLo >> 8,   // STA $0314
        0xea,                                   // NOP
        0xa9, target >> 8,                      // LDA #>target
        0x9d, vectorHi & 0xff, vectorHi >> 8,   // STA $0315
        0x60                                    // RTS
    ];

    for (let index = 0; index < code.length; index++) {
        RAM[testStart + index] = code[index];
    }

    commands.push(newEntryPointCommand(testStart));
    commands.push(newEntryPointCommand(testStart + 0x02));
    commands.push(newPointerCommand(testStart + 0x01, target, "lo"));
    commands.push(newPointerCommand(testStart + 0x07, target, "hi"));
    commands.push(newLabelCommand(vectorLo + 0, "< Current level"));
    commands.push(newLabelCommand(vectorLo + 1, "> Current level"));
    commands.push(newDataCommand(target, 0x20, "Level 1"));
    commands.push(newLabelCommand(target + 0x10, "Enemies"));
    commands.push(newLabelCommand(target + 0x14, "Pickups"));
}
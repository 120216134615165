import { useEffect, useState } from 'react';
import styles from './Ranges.module.css';
import { logMajorComponentRender } from '../../classes/Logger';

export type Filter = { visual: JSX.Element, isActive: boolean, toggle: (selectOnly: boolean) => void };

export interface ISearchableListProps<T> {
    items: T[];
    renderItem: (c: T) => JSX.Element;
    onSearchTermChanged: (searchTerm: string) => void;
    filters: Filter[];
}

export function SearchableList<T>(props: ISearchableListProps<T>) {

    logMajorComponentRender(SearchableList.name);

    const [searchTerm, setSearchTerm] = useState('');

    const { onSearchTermChanged, items, renderItem } = props;

    useEffect(() => {
        onSearchTermChanged(searchTerm.toLowerCase());
    }, [searchTerm, onSearchTermChanged]);

    const results = items.map((item, index) =>
        <li className={styles.li} key={index}>
            {renderItem(item)}
        </li>
    )

    const filterJsx = props.filters.map((filter, i) =>
        <span key={i} style={{ alignItems: 'center', display: 'inline-flex', marginRight: '4px' }}>
            {filter.visual}
            <input value='true' type='checkbox' checked={filter.isActive} onChange={e => {
                // @ts-ignore
                filter.toggle(e.nativeEvent.shiftKey)
            }} style={{ margin: 0 }} />
        </span>
    );

    return (
        <>
            <div className={styles.search}>
                <input className={styles.input} type="text" value={searchTerm} onChange={e => setSearchTerm(e.currentTarget.value)} />
                <input className={styles.button} type="button" value="X" onClick={e => setSearchTerm('')} />
            </div>

            <div className={styles.filters}>
                {filterJsx}
            </div>

            <div className={styles.list}>
                <ul className={styles.ul}>
                    {results}
                </ul>
            </div>
        </>
    );
}
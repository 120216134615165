import React, { CSSProperties, PropsWithChildren, createRef, useEffect } from 'react';
import styles from './Modal.module.css';
import { logMajorComponentRender } from '../../classes/Logger';

interface IModalProps extends PropsWithChildren {
    style?: CSSProperties,
    onClose: () => void
}

export const Modal: React.FC<IModalProps> = (props) => {

    logMajorComponentRender(Modal.name);

    const modalRef = createRef<HTMLDivElement>();
    const { onClose } = props;

    useEffect(() => {
        const handleEscapeKey = (e: KeyboardEvent) => {
            if (e.key !== 'Escape') { return; }
            onClose();
        }

        const handleOutsideClick = (e: MouseEvent) => {

            if (modalRef?.current?.contains(e.target as Element)) { return; }
            onClose();
        }

        document.addEventListener('click', handleOutsideClick, false);
        document.addEventListener('keyup', handleEscapeKey, false);

        return () => {
            document.removeEventListener('click', handleOutsideClick, false);
            document.removeEventListener('keyup', handleEscapeKey, false);
        };
    }, [modalRef, onClose])

    return (
        <>
            <div className={styles.background} />
            <div className={styles.modal} style={props.style} ref={modalRef}>
                {props.children}
            </div>
        </>
    );
}
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from ".";
import { logReducer } from "../classes/Logger";

export type UIState = {
    recentlyClickedAddress: number;
    memoryMapHeight: number;
}

const initialState: UIState = {
    recentlyClickedAddress: -1,
    memoryMapHeight: -1
}

const uiSlice = createSlice({
    name: 'ui',
    initialState,
    reducers: {
        recentlyClickedAddressAdded(state, action: PayloadAction<number>) {
            logReducer('uiSlice', 'recentlyClickedAddressAdded');
            state.recentlyClickedAddress = action.payload;
        },

        memoryMapHeightSet(state, action: PayloadAction<number>) {
            logReducer('uiSlice', 'memoryMapHeightSet');
            state.memoryMapHeight = action.payload;
        }
    }
});

export const { recentlyClickedAddressAdded, memoryMapHeightSet } = uiSlice.actions;
export default uiSlice.reducer;

export const selectRecentlyClickedAddresses = (state: RootState) => state.ui.recentlyClickedAddress;
import * as React from "react";
import { Sprite } from "./../graphics-templates/Sprite";
import { PersistentScroller } from "./PersistentScroller";
import { selectSelectionWidth } from "../../store/graphicsSlice";
import { useAppSelector } from "../../store/hooks";
import { logMajorComponentRender } from "../../classes/Logger";


export const Sprites: React.FunctionComponent = () => {

    logMajorComponentRender(Sprites.name);

    const { selectionWidth } = useAppSelector(selectSelectionWidth);

    const calculateSpriteInfo = () => {

        let heightsAndSkip = [[32, 0], [32, 0], [21, 1], [32, 0], [25, 3], [21, 2], [18, 2], [32, 0]];

        const widthChars = Math.min(selectionWidth, 8);

        const [heightPx, skipBytes] = heightsAndSkip[widthChars - 1];

        const countBytes = widthChars * heightPx;
        const spritesPerBank = Math.floor(0x4000 / (countBytes + skipBytes));
        const spriteCount = spritesPerBank * 4;

        return { widthChars, countBytes, skipBytes, spritesPerBank, spriteCount };
    }

    const renderSprites = (bank: number, spritesPerBank: number, widthChars: number, countBytes: number, rowSkipBytes: number) => {

        let baseIndex = (3 - bank) * spritesPerBank;

        let s: JSX.Element[] = [];
        for (let index = 0; index < spritesPerBank; index++) {
            const totalIndex = (baseIndex + index);
            const startAddress = (countBytes + rowSkipBytes) * totalIndex;
            s.push(<Sprite key={totalIndex} startAddress={startAddress} widthChars={widthChars} countBytes={countBytes} />)
        }

        return s;
    }

    const { widthChars, countBytes, skipBytes, spritesPerBank } = calculateSpriteInfo();

    return (
        <PersistentScroller name="sprites">
            <h2>Bank 3 : $0000-$3fff</h2>
            {renderSprites(3, spritesPerBank, widthChars, countBytes, skipBytes)}
            <h2>Bank 2 : $4000-$7fff</h2>
            {renderSprites(2, spritesPerBank, widthChars, countBytes, skipBytes)}
            <h2>Bank 1 : $8000-$bfff</h2>
            {renderSprites(1, spritesPerBank, widthChars, countBytes, skipBytes)}
            <h2>Bank 0 : $c000-$ffff</h2>
            {renderSprites(0, spritesPerBank, widthChars, countBytes, skipBytes)}
        </PersistentScroller>
    );
}
import React, { ReactNode } from 'react';
import styles from './SettingsSection.module.css';
import { logMinorComponentRender } from '../../classes/Logger';

export const SettingsSection: React.FunctionComponent<{ children: ReactNode, name: string }> = (props) => {

    logMinorComponentRender(SettingsSection.name);

    return (
        <>
            <h4 className={styles.h4}>{props.name}</h4>
            {props.children}
        </>
    );
}

import React from 'react';
import styles from './App.module.css';
import { LogoPanel } from './components/LogoPanel';
import { SettingsTypePanel } from './components/SettingsTypePanel';
import { SettingsPanel } from './components/SettingsPanel';
import { MainPanel } from './components/MainPanel';
import { ProjectNamePanel } from './components/ProjectNamePanel';
import { GraphicsCanvas } from './components/graphics/GraphicsCanvas';
import { GraphicsRenderer } from './components/graphics/GraphicsRenderer';
import { logAnalyticsEvent } from './classes/code/Firebase';
import { UserPanel } from './components/UserPanel';
import { GraphicsViewport } from './components/graphics/GraphicsViewport';
import { MemoryMap } from './components/MemoryMap';
import { ViewportLocation } from './store/rendererSlice';
import { useAppSelector } from './store/hooks';
import { logMajorComponentRender } from './classes/Logger';

const style: React.CSSProperties = {
    minHeight: '100vh',
    minWidth: '100vw',
    display: 'grid',
    gridTemplateAreas: '"logo main map" "user main map" "project-name main map" "settings-type main map" "settings main map"',
    gridTemplateColumns: "350px auto 100px",
    gridTemplateRows: "auto auto auto auto 1fr"
};

const App: React.FunctionComponent = () => {

    logMajorComponentRender(App.name)

    logAnalyticsEvent("App started");

    const glAvailable = useAppSelector(state => state.renderer.glAvailable);

    return (
        <div className={styles.app} style={style} >

            <GraphicsCanvas />

            {glAvailable &&
                <>
                    <GraphicsRenderer />
                    <LogoPanel gridArea="logo" />
                    <UserPanel gridArea="user" />
                    <ProjectNamePanel gridArea="project-name" />
                    <SettingsTypePanel gridArea="settings-type" />
                    <SettingsPanel gridArea="settings" />

                    <MainPanel gridArea="main" />

                    <GraphicsViewport location={ViewportLocation.MemoryMap} ignoreScrollbars={{ h: true, v: true }} show={{ marked: false, selected: true }} render={r =>
                        <div style={{ gridArea: 'map' }} ref={r}>
                            <MemoryMap />
                        </div>
                    } />
                </>
            }

        </div>
    );
}

export default App;
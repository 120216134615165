import { Utils } from '../Utils';
import { MemoryLocation, ReferencePart } from '../code/MemoryLocation';
import { PointerCommand } from './PointerCommand';
import { Range } from "../code/Range";

const hex = Utils.to4DigitHexString;

export const version = "1";

export const newPointerCommand = (address: number, target: number, part: ReferencePart): PointerCommand => {
    return {
        type: 'pointer',
        address,
        target,
        part
    }
}

export const markUpMemory = (command: PointerCommand, memory: MemoryLocation[], ranges: Range[]) => {

    const labelIsValid = () => {
        const labelRefTarget = command.target;
        const labelRefByteLo = labelRefTarget & 0xff;
        const labelRefByteHi = labelRefTarget >> 8;

        const thisByte = memory[command.address].Value;
        const nextByte = memory[command.address + 1].Value;
        const labelRefPart = command.part;
        const isLoAndLoByteMatches = (labelRefPart === 'lo') && (labelRefByteLo === thisByte);
        const isHiAndHiByteMatches = (labelRefPart === 'hi') && (labelRefByteHi === thisByte);
        const is16BitAndBothBytesMatch = (labelRefPart === '16bit') && (labelRefByteLo === thisByte) && (labelRefByteHi === nextByte);
        return isLoAndLoByteMatches || isHiAndHiByteMatches || is16BitAndBothBytesMatch;
    }

    memory[command.address].OutgoingPointer = { target: command.target, part: command.part }

    const isValid = labelIsValid();
    memory[command.address].OutgoingPointerIsValid = isValid;

    if (isValid) {
        memory[command.target].IncomingPointers.push(command.address);
    }
}

export const equals = (command: PointerCommand, otherCommand: PointerCommand): boolean => {

    let res = true;
    res &&= (command.address === otherCommand.address);
    res &&= (command.target === otherCommand.target);
    res &&= (command.part === otherCommand.part);

    return res;
}

export const serialise = (command: PointerCommand): string => {
    const partShort = (command.part === 'hi') ? 'h' : (
        command.part === 'lo' ? 'l' : 'a'
    );
    return `pt|${hex(command.address)}|${hex(command.target)}|${partShort}`;
}

const deserialise_v01 = (components: string[]): PointerCommand | undefined => {
    if (components.length !== 3) { return; }
    const address = Number(`0x${components[0]}`);
    const target = Number(`0x${components[1]}`);
    const part: ReferencePart | undefined =
        components[2] === 'h' ? 'hi' : (
            components[2] === 'l' ? 'lo' : (
                components[2] === 'a' ? '16bit' : undefined
            )
        );

    if (isNaN(address) || isNaN(target) || part == null) { return; }

    return newPointerCommand(address, target, part);
}

export const deserialisers: { [version: string]: (c: string[]) => PointerCommand | undefined } = {
    '1': deserialise_v01
};


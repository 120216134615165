import React from 'react';
import { SettingsSection } from './SettingsSection'
import { SettingsGroup, SettingsRow } from './SettingsGroup';
import { countAddedToAddress, selectionAddressSet, selectionCountSet, countTakenFromAddress, selectActiveTool, selectSelection } from '../../store/toolSlice';
import { HexNumberInput } from './HexNumberInput';
import { Utils } from '../../classes/Utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { selectMarkedUpMemory } from '../../store/projectSlice';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { logMajorComponentRender } from '../../classes/Logger';


export const SelectionSettingsSection: React.FunctionComponent = () => {

    logMajorComponentRender(SelectionSettingsSection.name);

    const { selectionAddress, selectionCount } = useAppSelector(selectSelection);
    const activeTool = useAppSelector(selectActiveTool);
    const { memory } = useAppSelector(selectMarkedUpMemory);
    const dispatch = useAppDispatch();

    const disableFocusAddressButton = activeTool === 'Map';

    const handleNavigate = () => {
        document.dispatchEvent(new CustomEvent<number>('navigate-to-address', { detail: selectionAddress }));
    }

    const handleCopy = () => {

        const values = memory
            .slice(selectionAddress, selectionAddress + selectionCount)
            .map(m => `0x${Utils.to2DigitHexString(m.Value)}`);

        const perLine = 16;
        const numLines = Math.ceil(values.length / perLine);
        const lines: string[] = [];
        for (let index = 0; index < numLines; index++) {
            const startIndex = index * perLine;
            const endIndexFullLine = (index + 1) * perLine - 1;
            const endIndex = Math.min(endIndexFullLine, values.length - 1);
            const thisLine = values.slice(startIndex, endIndex + 1).join(", ");
            lines.push(thisLine);
        }

        const bytes = lines.join(",\n\t\t");

        const json = `const bytes = {\n\t"address": 0x${Utils.to4DigitHexString(selectionAddress)},\n\t"bytes": [\n\t\t${bytes}\n\t]\n};`;

        navigator.clipboard.writeText(json);
    }

    return (
        <SettingsSection name="Selection">

            <SettingsGroup>
                <SettingsRow label="address">
                    <HexNumberInput
                        enabled={true}
                        label="address"
                        number={selectionAddress}
                        onChange={v => dispatch(selectionAddressSet(v))}
                        forceFourDigits={true}
                    />
                    <button onClick={handleNavigate} disabled={disableFocusAddressButton}>-&gt;</button>
                    <button onClick={handleCopy} disabled={disableFocusAddressButton}><FontAwesomeIcon icon={faCopy} /></button>
                </SettingsRow>

                <SettingsRow label="count">
                    <HexNumberInput
                        enabled={true}
                        label="count"
                        number={selectionCount}
                        unit="bytes"
                        onChange={v => dispatch(selectionCountSet(v))}
                        forceFourDigits={false}
                    />
                    <button onClick={() => dispatch(countTakenFromAddress())}>&lt;&lt;</button>
                    <button onClick={() => dispatch(countAddedToAddress())}>&gt;&gt;</button>
                </SettingsRow>
            </SettingsGroup>

        </SettingsSection>
    );
}
import React from 'react';
import { ISettingProps } from './ISettingProps';
import styles from './Setting.module.css';
import cx from 'classnames';
import { logMinorComponentRender } from '../../classes/Logger';


export interface ITextSettingProps extends ISettingProps {
    enabled: boolean;
    text: string;
    unit?: string;
    onChange: (v: string) => void;
}

export const TextSetting: React.FunctionComponent<ITextSettingProps> = (props) => {

    logMinorComponentRender(TextSetting.name);

    let n = props.unit == null ? null : <span className={styles.suffix}>{props.unit}</span>;

    let disabledStyle = !props.enabled ? styles.disabled : '';

    return (
        <><p className={cx(styles.p, disabledStyle)}>{props.text}{n}</p></>
    );
}

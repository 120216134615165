import * as React from "react";

import styles from './LineRenderer.module.css';


import { LineData, BigLabelLineData, BigCodeLabelLineData, SmallCodeLabelLineData, ByteLineData, CodeLineData, GapLineData, LineType, borderWidthPx, marginPx, GraphicsRangeData, ElipsisLineData, GraphicsLineData, LabelLineData, CommentLineData } from '../../classes/code/LineDataManager';
import { Utils } from '../../classes/Utils';
import { MemoryLocation } from '../../classes/code/MemoryLocation';
import { OpCodes, OpCodePrologues, OpCodeEpilogues } from '../../classes/code/OpCodes';
import { colourCode, colourData, colourGfx } from "../MemoryMap";
import { Graphic } from "../graphics/Graphic";
import { ViewportLocation } from "../../store/rendererSlice";
import { Byte } from "./Byte";
import { References } from "./References";

const getLineTypeColour = (type: LineType, alpha: number = 0.3) => {
    const cssUnknown = `#00000000`;
    const cssCode = `rgb(${colourCode[0]}, ${colourCode[1]}, ${colourCode[2]}, ${alpha})`;
    const cssData = `rgb(${colourData[0]}, ${colourData[1]}, ${colourData[2]}, ${alpha})`;
    const cssGraphics = `rgb(${colourGfx[0]}, ${colourGfx[1]}, ${colourGfx[2]}, ${alpha})`;

    const rgb = (type === LineType.Code) ? cssCode : (
        (type === LineType.Data) ? cssData : (
            (type === LineType.Graphics) ? cssGraphics : cssUnknown
        )
    )

    return rgb;
}

export class LineRenderer {

    constructor(
        private readonly memory: MemoryLocation[],
        private readonly bytesPerLine: number,
        private readonly handleClick: (e: React.MouseEvent<HTMLElement>, address: number) => void,
        private readonly handleArgumentClick: (sourceAddress: number, targetAddress: number) => void
    ) { }


    renderLine(lineData: LineData) {

        let lineDOM: JSX.Element = <></>;
        let lineStyle: string = '';

        if (lineData instanceof LabelLineData) {
            lineDOM = this.renderLabelLine(lineData as LabelLineData);
            lineStyle = styles.bigLabelLine;
        }

        if (lineData instanceof CommentLineData) {
            lineDOM = this.renderCommentLine(lineData as CommentLineData);
            lineStyle = styles.bigLabelLine;
        }

        if (lineData instanceof BigLabelLineData) {
            lineDOM = this.renderBigLabelLine(lineData as BigLabelLineData);
            lineStyle = styles.bigLabelLine;
        }

        if (lineData instanceof BigCodeLabelLineData) {
            lineDOM = this.renderBigCodeLabelLine(lineData as BigCodeLabelLineData);
            lineStyle = styles.bigLabelLine;
        }

        if (lineData instanceof SmallCodeLabelLineData) {
            lineDOM = this.renderSmallCodeLabelLine(lineData as SmallCodeLabelLineData);
            lineStyle = styles.smallLabelLine;
        }

        if (lineData instanceof ByteLineData) {
            lineDOM = this.renderByteLine(lineData as ByteLineData);
            lineStyle = styles.byteLine;
        }

        if (lineData instanceof GraphicsLineData) {
            lineDOM = this.renderGraphicsLine(lineData as GraphicsLineData);
            lineStyle = styles.byteLine;
        }

        if (lineData instanceof ElipsisLineData) {
            lineDOM = this.renderElipsisLine(lineData as ElipsisLineData);
            lineStyle = styles.byteLine;
        }

        if (lineData instanceof CodeLineData) {
            lineDOM = this.renderCodeLine(lineData as CodeLineData);
            lineStyle = styles.byteLine;
        }

        if (lineData instanceof GraphicsRangeData) {
            lineDOM = this.renderGraphicsRange(lineData as GraphicsRangeData);
            lineStyle = styles.range;
        }

        if (lineData instanceof GapLineData) {
            // lineDOM = this.renderGapLine(lineData as GapLineData);
            // lineStyle = styles.gapLine;
        }

        return { lineDOM, lineStyle };
    }

    private renderGraphicsRange(range: GraphicsRangeData) {
        const contents = range.lineDatas.map((l, i) => <div key={i} style={{ height: l.heightPx, top: l.offsetPx, position: 'absolute' }}>{this.renderLine(l).lineDOM}</div>);

        const dataWidthPx = 290;
        const padding = 12;
        const graphicWidthPx = range.command.widthPx * range.command.scale;
        const extraHeight = marginPx + borderWidthPx + borderWidthPx + marginPx;

        const width = dataWidthPx + graphicWidthPx + padding;
        const height = range.heightPx - extraHeight;

        const style: React.CSSProperties = {
            borderWidth: `${borderWidthPx}px`,
            borderColor: getLineTypeColour(LineType.Graphics, 0.8),
            backgroundColor: getLineTypeColour(LineType.Graphics, 0.1),
            height: `${height}px`,
            width: `${width}px`,
            marginTop: `${marginPx}px`,
            position: 'relative',
            display: 'grid',
            gridTemplateAreas: '"bytes graphic"',
            gridTemplateColumns: `${dataWidthPx}px ${graphicWidthPx + padding}px`
        };

        return (
            <div className={styles.range} style={style}>
                <div style={{ gridArea: "bytes" }}>{contents}</div>
                <div style={{ gridArea: "graphic" }}><Graphic settings={range.command} location={ViewportLocation.Main} focusable={false} showLabel={false} /></div>
            </div>
        )
    }

    private renderGraphicsLine(lineData: GraphicsLineData) {

        const baseAddress = lineData.baseAddress;
        const count = lineData.count;

        const address = this.renderAddress(baseAddress, false);
        const { bytes } = this.renderBytesAndChars(count, baseAddress, this.bytesPerLine, false);

        return (
            <span className={styles.byteLine}>{address}{bytes}</span>
        );
    }

    private renderCodeLine(lineData: CodeLineData) {

        const baseAddress = lineData.baseAddress;
        const count = lineData.count;

        const address = this.renderAddress(baseAddress, false);
        const { bytes } = this.renderBytesAndChars(count, baseAddress, 3, false);
        const code = this.renderCode(baseAddress);

        const style: React.CSSProperties = {
            borderColor: getLineTypeColour(lineData.type, 0.8),
            borderWidth: '0px 1px',
            borderStyle: 'solid',
            backgroundColor: getLineTypeColour(lineData.type, 0.1),
            height: lineData.heightPx,
            display: 'inline-block'
        };

        return (
            <span style={style} className={styles.byteLine}>{address}{bytes}{code}</span>
        );
    }


    private renderByteLine(lineData: ByteLineData) {

        const baseAddress = lineData.baseAddress;
        const count = lineData.count;

        const address = this.renderAddress(baseAddress, lineData.isUndefined);
        const { bytes, chars } = this.renderBytesAndChars(count, baseAddress, this.bytesPerLine, true);
        const refs = this.renderAllRefs(baseAddress, [], [], [], this.memory[baseAddress].IncomingReads, this.memory[baseAddress].IncomingWrites, this.memory[baseAddress].IncomingPointers, 1);

        const style: React.CSSProperties = {
            borderColor: getLineTypeColour(lineData.type, 0.8),
            borderWidth: '0px 1px',
            borderStyle: 'solid',
            backgroundColor: getLineTypeColour(lineData.type, 0.1),
            height: lineData.heightPx,
            display: "inline-block"
        };

        return (
            <span style={style} className={styles.byteLine}>{address}{bytes}{chars}{refs}</span>
        );
    }


    private renderElipsisLine(lineData: ElipsisLineData) {

        const elipsis = <span className={styles.segment}>...</span>;

        return (
            <span className={styles.byteLine}>{elipsis}</span>
        );
    }


    private renderBigLabelLine(lineData: BigLabelLineData) {

        const style: React.CSSProperties = {
            borderColor: getLineTypeColour(lineData.type, 0.8),
            backgroundColor: getLineTypeColour(lineData.type, 0.1),
        };

        return (
            <div style={style} className={styles.bigLabelInner}>
                <span className={styles.bigLabelWrapper}>
                    <span className={styles.labelAddress}>{Utils.to4DigitHexString(lineData.baseAddress)}</span>
                    <span className={styles.labelLength}>+{Utils.to4DigitHexString(lineData.endAddress - lineData.baseAddress + 1)}</span>
                    {lineData.label &&
                        <span className={styles.labelTitle}>{lineData.label}</span>
                    }
                </span >
                {this.renderAllRefs(lineData.baseAddress, [], [], [], [], [], [], 5)}
            </div >
        );
    }

    private renderLabelLine(lineData: LabelLineData) {

        if (lineData.isBig) {
            const style: React.CSSProperties = {
                borderColor: getLineTypeColour(lineData.type, 0.8),
                backgroundColor: getLineTypeColour(lineData.type, 0.1),
            };

            return (
                <div style={style} className={styles.bigLabelInner}>
                    <span className={styles.labelAddress}>{Utils.to4DigitHexString(lineData.baseAddress)}</span>
                    <span className={styles.labelLength}>+{Utils.to4DigitHexString(lineData.endAddress + 1 - lineData.baseAddress)}</span>
                    <span className={styles.labelTitle}>{lineData.label}</span>
                    {this.renderAllRefs(lineData.baseAddress, [], [], [], lineData.incomingReads, lineData.incomingWrites, lineData.incomingPointers, 3)}
                </div >
            );
        }
        else {
            const style: React.CSSProperties = {
                borderColor: getLineTypeColour(lineData.type, 0.8),
                borderWidth: '0px 1px 0px 4px',
                borderStyle: 'solid',
                backgroundColor: getLineTypeColour(lineData.type, 0.1),
                height: lineData.heightPx - 6,
                display: "inline-block"
            };

            return (
                <div style={style} className={styles.smallLabelInner}>
                    <span>{lineData.label}</span>
                    {this.renderAllRefs(lineData.baseAddress, [], [], [], lineData.incomingReads, lineData.incomingWrites, lineData.incomingPointers, 3)}
                </div >
            );

        }
    }

    private renderCommentLine(lineData: CommentLineData) {
        const style: React.CSSProperties = {
            borderColor: getLineTypeColour(lineData.type, 0.8),
            borderWidth: '0px 1px 0px 4px',
            borderStyle: 'solid',
            backgroundColor: getLineTypeColour(lineData.type, 0.1),
            height: lineData.heightPx,
            display: "inline-block"
        };

        return (
            <div style={style} className={styles.comment}>
                <span className={styles.commentText}>{lineData.text}</span>
            </div >
        );
    }


    private renderBigCodeLabelLine(lineData: BigCodeLabelLineData) {

        const style: React.CSSProperties = {
            borderColor: getLineTypeColour(lineData.type, 0.8),
            backgroundColor: getLineTypeColour(lineData.type, 0.1),
        };

        return (
            <div style={style} className={styles.bigLabelInner}>
                <span className={styles.bigLabelWrapper}>
                    <span className={styles.labelAddress}>{lineData.label}</span>
                </span >
                {this.renderAllRefs(lineData.baseAddress, lineData.incomingBranches, lineData.incomingJMPs, lineData.incomingJSRs, [], [], lineData.incomingPointers, 5)}
            </div >
        );
    }


    private renderSmallCodeLabelLine(lineData: SmallCodeLabelLineData) {

        const style: React.CSSProperties = {
            borderColor: getLineTypeColour(lineData.type, 0.8),
            borderWidth: '0px 1px 0px 4px',
            borderStyle: 'solid',
            backgroundColor: getLineTypeColour(lineData.type, 0.1),
            height: lineData.heightPx - 6,
            display: "inline-block"
        };

        return (
            <div style={style} className={styles.smallLabelInner}>
                <span>{lineData.label}</span>
                {this.renderAllRefs(lineData.baseAddress, lineData.incomingBranches, lineData.incomingJMPs, lineData.incomingJSRs, [], [], [], 3)}
            </div >
        );
    }


    private renderGapLine(lineData: GapLineData): null {

        return null;
    }


    /** ----------------------------- */
    /** ----------------------------- */


    private renderAllRefs(sourceAddress: number, branches: number[], JMPs: number[], JSRs: number[], reads: number[], writes: number[], pointers: number[], maxRefs: number) {

        const refs = [
            { prefix: 'branch', addresses: branches },
            { prefix: 'jmp', addresses: JMPs },
            { prefix: 'jsr', addresses: JSRs },
            { prefix: 'read', addresses: reads },
            { prefix: 'write', addresses: writes },
            { prefix: 'pointer', addresses: pointers },
        ];

        return <References maxRefs={maxRefs} sourceAddress={sourceAddress} refs={refs} />;
    }

    private renderAddress(address: number, isUndefined: boolean) {

        const addressStr = Utils.to4DigitHexString(address);

        const addressClass = styles.segment + (isUndefined ? ' ' + styles.isUndefined : '');

        return (
            <span className={addressClass}>{addressStr}</span>
        );
    }

    private preparePointerDecoration(pointerAddress: number) {
        const outgoingPointer = this.memory[pointerAddress].OutgoingPointer;

        const outgoingPointerIsValid = this.memory[pointerAddress].OutgoingPointerIsValid;
        const outgoingPointerTarget = outgoingPointer?.target ?? 0;
        const prologue = outgoingPointer?.part === '16bit' ? '→ ' : (outgoingPointer?.part === 'hi' ? '→ >' : '→ <');

        const targetLocation = this.memory[outgoingPointerTarget];
        const targetLabel = targetLocation.Label;

        let targetBlockLabel: string | undefined = undefined;
        let targetCountWithinBlock: number | undefined = undefined;
        if (targetLocation.IsData || targetLocation.IsGfx) {
            targetCountWithinBlock = -targetLocation.CountWithinBlock;
            targetBlockLabel = this.memory[outgoingPointerTarget - targetCountWithinBlock].Label;
        }

        let label = '';
        if (targetLabel != null) {
            label = `${prologue}${targetLabel}`;
        }
        else if (targetBlockLabel != null && targetCountWithinBlock != null) {
            label = `${prologue}${targetBlockLabel} + $${Utils.toHexAuto(targetCountWithinBlock)}`
        }
        else {
            label = `${prologue}$${Utils.to4DigitHexString(outgoingPointerTarget)}`;
        }

        return { label, outgoingPointerIsValid, outgoingPointerTarget };
    }

    private decorate(value: JSX.Element | string, label: string, labelIsValid: boolean, address: number, labelTarget: number, argumentIsClickable: boolean) {

        const onClick = (e: React.MouseEvent<HTMLSpanElement>) => {
            this.handleArgumentClick(address, labelTarget);
        }

        const validStyle = labelIsValid ? styles.labelValid : styles.labelInvalid;
        const outerStyle = `${styles.labelOuter} ${validStyle}`;
        const innerStyle = `${styles.label} ${validStyle}`;
        const onClickOuter = argumentIsClickable ? undefined : onClick;
        const onClickInner = argumentIsClickable ? onClick : undefined;
        return <span className={outerStyle} onClick={onClickOuter}>{value}{label && <span className={innerStyle} onClick={onClickInner}>{label}{!labelIsValid && '?'}</span>}</span>
    }



    private renderBytesAndChars(count: number, baseAddress: number, bytesPerLine: number, highlightValidChars: boolean) {

        const bytesArr: JSX.Element[] = [];
        const charsArr: JSX.Element[] = [];

        for (let i = 0; i < count; i++) {

            const address = baseAddress + i;
            if (address >= 0x10000)
                break;

            const location = this.memory[address];

            bytesArr.push(
                <Byte
                    key={i}
                    address={address}
                    location={location}
                    highlightValidChars={highlightValidChars}
                    onClick={e => this.handleClick(e, address)}
                />
            );

            const byte = location.Value;
            const { char, valid } = Utils.toChar(byte);
            const isValid = valid && highlightValidChars;

            let extraStyle: string;
            const selected = false; // This prevents chars from being highlighted during selection...
            if (selected)
                extraStyle = isValid ? ' ' + styles.selected : ' ' + styles.selectedinvalid;
            else
                extraStyle = isValid ? '' : ' ' + styles.invalid;

            const charStyle = styles.char + extraStyle;
            charsArr.push(
                <span key={i} className={charStyle} onClick={e => this.handleClick(e, address)}>{char}</span>
            );
        };

        const outgoingPointer = this.memory[baseAddress].OutgoingPointer;
        const hasOutgoingPointer = outgoingPointer != null;

        const extraCount = hasOutgoingPointer ? 0 : bytesPerLine - count;
        if (extraCount > 0) {
            for (let i = 0; i < extraCount; i++) {
                bytesArr.push(<span key={`x${i}`} className={styles.byte}>&nbsp;&nbsp;</span>);
                charsArr.push(<span key={`x${i}`} className={styles.char}>&nbsp;</span>);
            }
        }

        const getBytes = () => {
            if (hasOutgoingPointer) {
                const { label, outgoingPointerIsValid, outgoingPointerTarget } = this.preparePointerDecoration(baseAddress);
                return this.decorate(<>{bytesArr}</>, label, outgoingPointerIsValid, baseAddress, outgoingPointerTarget, true);
            }
            else {
                return <span className={styles.segment}>{bytesArr}</span>;
            }
        };

        const getChars = () => {
            if (hasOutgoingPointer) { return undefined; }
            return <span className={styles.segment}>{charsArr}</span>;
        };


        return { bytes: getBytes(), chars: getChars() };
    }

    private renderCode(baseAddress: number) {
        const location = this.memory[baseAddress];
        const opcode = OpCodes[location.Value];
        const operator = opcode.OpName;
        const prologue = OpCodePrologues[opcode.Mode];
        const epilogue = OpCodeEpilogues[opcode.Mode];

        const getArgument = () => {
            if (location.Argument == null) { return undefined; }

            const argument = '$' + ((location.ArgumentLength === '8bit') ? Utils.to2DigitHexString(location.Argument) : Utils.to4DigitHexString(location.Argument));
            const argIsAddress = opcode.OperandReference !== "None";
            const pointerAddress = baseAddress < 0xffff ? baseAddress + 1 : baseAddress;
            const outgoingPointer = this.memory[pointerAddress].OutgoingPointer;
            const argIsPointer = opcode.OperandReference === "None" && opcode.Mode === 'Immediate' && outgoingPointer != null;

            if (argIsAddress) {
                const targetAddress = location.Argument;
                const targetLocation = this.memory[targetAddress];

                let label = targetLocation.Label ?? '';

                if (label === '' && (targetLocation.IsData || targetLocation.IsGfx)) {
                    const countWithinBlock = -targetLocation.CountWithinBlock;
                    const targetBlockStartLocation = this.memory[targetAddress - countWithinBlock];
                    label += `${targetBlockStartLocation.Label} + $${Utils.toHexAuto(countWithinBlock)}`;
                }

                return this.decorate(argument, label, true, baseAddress, targetAddress, false);

            }
            else if (argIsPointer) {
                const { label, outgoingPointerIsValid, outgoingPointerTarget } = this.preparePointerDecoration(pointerAddress);
                return this.decorate(argument, label, outgoingPointerIsValid, baseAddress, outgoingPointerTarget, false);

            }
            else {
                return argument;
            }
        }

        return (
            <span>{operator} {prologue}{getArgument()}{epilogue}</span>
        );
    }
}
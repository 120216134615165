import React, { ReactNode } from 'react';
import styles from './SettingsPanelTab.module.css';
import { logMajorComponentRender } from '../classes/Logger';


export interface ISettingsPanelTabProps {
    gridArea: string;
    children?: ReactNode;
}

export const SettingsPanelTab: React.FunctionComponent<ISettingsPanelTabProps> = ((props) => {

    logMajorComponentRender(SettingsPanelTab.name);

    const panelStyle = `${styles.panel} ${styles.scroll}`;

    return (
        <div style={{ gridArea: props.gridArea }} className={panelStyle}>
            <div className={styles.inner}>
                {props.children}
            </div>
        </div>
    );
});

import { GraphicCommand } from "../commands/GraphicCommand";
import { GraphicsTypes } from "../graphics/GraphicsEnums";

export class DataRange {

    constructor(
        readonly Name: string,
        readonly StartAddress: number,
        readonly EndAddress: number
    ) { }
}

export class GraphicsRange {

    constructor(
        readonly Name: string,
        readonly Command: GraphicCommand,
        readonly Type: GraphicsTypes = Command.graphicsType ?? 'Unknown',
        readonly StartAddress: number = Command.address,
        readonly EndAddress: number = Command.address + Command.countBytes - 1
    ) { }
}


export class CodeRange {

    constructor(
        readonly StartAddress: number,
        readonly EndAddress: number,
        readonly Branches: number[],
        readonly JMPs: number[],
        readonly JSRs: number[],
        readonly Pointers: number[],
        readonly Flow?: number
    ) { }
}


export type Range = DataRange | CodeRange | GraphicsRange;
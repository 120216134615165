import React, { MouseEvent } from 'react';
import { ISettingProps } from './ISettingProps';
import styles from './ColourSwatch.module.css';
import settingsStyles from './Setting.module.css';
import cx from 'classnames';
import { Utils } from '../../classes/Utils';
import { logMinorComponentRender } from '../../classes/Logger';


export interface IColourSwatchProps extends ISettingProps {
    colour: number;
    enabled: boolean;
    beingPicked: boolean;
    onClick: () => void;
}

const handleClick = (e: MouseEvent, action: () => void) => {
    e.preventDefault();
    e.stopPropagation();
    action();
}

const colourCssName = (paletteIndex: number) => `col${Utils.to2DigitHexString(paletteIndex)}`;

export const ColourSwatch: React.FunctionComponent<IColourSwatchProps> = (props) => {

    logMinorComponentRender(ColourSwatch.name);

    const disabledStyle = props.enabled ? '' : styles.disabled;



    return (
        <button className={cx(styles.swatches, disabledStyle, settingsStyles.button)} onClick={e => handleClick(e, props.onClick)} >
            <div className={cx(disabledStyle, styles.swatch, styles[colourCssName(props.colour)])} />{props.label}
            {props.beingPicked &&
                <div className={styles.beingPicked} />
            }
        </button>
    );
}


export interface IBigColourSwatchProps {
    colour: number;
    onClick: () => void;
}

export const BigColourSwatch: React.FunctionComponent<IBigColourSwatchProps> = (props) => {

    return (
        <div className={cx(styles.bigSwatch, styles[colourCssName(props.colour)])} onClick={e => handleClick(e, props.onClick)} />
    );
}
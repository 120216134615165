import React, { ReactNode } from 'react';
import { ISettingProps } from './ISettingProps';
import styles from './SettingsGroup.module.css';
import { logMinorComponentRender } from '../../classes/Logger';

export const SettingsGroup: React.FunctionComponent<{ children: ReactNode }> = (props) => {

    logMinorComponentRender(SettingsGroup.name);

    let rows = React.Children.map(props.children, (child) => {

        const setting = child as React.ReactElement<ISettingProps>;
        if (!setting)
            return null;

        let label = setting.props.label;
        return <tr className={styles.tr}><td className={styles.td}>{label}</td><td className={styles.td}>{child}</td></tr>;
    });

    return <table className={styles.settingsGroup}><tbody>{rows}</tbody></table>;
}

export const SettingsRow: React.FunctionComponent<{ children: ReactNode, label: string }> = (props) => {

    logMinorComponentRender(SettingsRow.name);

    const cells = React.Children.map(props.children, child => {
        return <span style={{ display: 'inline-block' }}>{child}</span>;
    });

    return <div>{cells}</div>;
}

export const SettingsComment: React.FunctionComponent<{ children: ReactNode }> = (props) => {

    logMinorComponentRender(SettingsComment.name);

    return <div className={styles.settingsGroup}>{props.children}</div>

}
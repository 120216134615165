import { ProjectDescription } from "./ProjectDescription";
import { version as vep, serialise as serialiseEntryPointCommand, deserialisers as entryPointCommandDeserialisers } from './commands/EntryPointHelpers';
import { version as vdr, serialise as serialiseDataCommand, deserialisers as dataCommandDeserialisers } from './commands/DataCommandHelpers';
import { version as vft, serialise as serialiseFormattingCommand, deserialisers as formattingCommandDeserialisers } from './commands/FormattingCommandHelpers';
import { version as vpt, serialise as serialisePointerCommand, deserialisers as pointerCommandDeserialisers } from './commands/PointerCommandHelpers';
import { version as vlb, serialise as serialiseLabelCommand, deserialisers as labelCommandDeserialisers } from './commands/LabelCommandHelpers';
import { version as vgr, serialise as serialiseGraphicCommand, deserialisers as graphicCommandDeserialisers } from './commands/GraphicCommandHelpers';
import { version as vcm, serialise as serialiseCommentCommand, deserialisers as commentCommandDeserialisers } from './commands/CommentCommandHelpers';
import { Command } from "./commands/types";
import { EntryPointCommand } from "./commands/EntryPointCommand";
import { DataCommand } from "./commands/DataCommand";
import { Utils, splitToKV } from "./Utils";
import { FormattingCommand } from "./commands/FormattingCommand";
import { PointerCommand } from "./commands/PointerCommand";
import { LabelCommand } from "./commands/LabelCommand";
import { GraphicCommand } from "./commands/GraphicCommand";
import { CommentCommand } from "./commands/CommentCommand";

const hex4 = Utils.to4DigitHexString;
const hex2 = Utils.to2DigitHexString;

const vra = 1;
const vcr = 1;


export const newProjectDescription = (name: string, guid: string, RAM: number[], COLRAM: number[], commands: Command[]): ProjectDescription => {
    return {
        magic: '46c',
        name,
        guid,
        RAM,
        COLRAM,
        commands
    }
}


const buildCommandList = (commands: Command[]): string[] => {
    if (commands == null) { return []; }

    const list = commands.map(c => {
        if (c.type === 'data') { return serialiseDataCommand(c as DataCommand); }
        else if (c.type === 'entry-point') { return serialiseEntryPointCommand(c as EntryPointCommand); }
        else if (c.type === 'formatting') { return serialiseFormattingCommand(c as FormattingCommand); }
        else if (c.type === 'graphic') { return serialiseGraphicCommand(c as GraphicCommand); }
        else if (c.type === 'label') { return serialiseLabelCommand(c as LabelCommand); }
        else if (c.type === 'pointer') { return serialisePointerCommand(c as PointerCommand); }
        else if (c.type === 'comment') { return serialiseCommentCommand(c as CommentCommand) }
        else { return ''; }
    }).filter(c => c !== '');

    return list.length > 0 ? list : [];
}

const buildByteArray = (name: string, bytes: number[]): string[] => {

    const lines: string[] = [];

    let address = 0;
    while (address < bytes.length) {
        const startAddress = address;
        const bytesThisLine = Math.min(0x80, bytes.length - address);

        const bytesAsHex: string[] = [];
        for (let i = 0; i < bytesThisLine; i++) {
            bytesAsHex.push(hex2(bytes[address]));
            address++;
        }

        lines.push(`${name}|${hex4(startAddress)}|${hex2(bytesThisLine)}|${bytesAsHex.join('')}`);
    }

    return lines;
}

export const buildHeader = (description: ProjectDescription, time: number) => {

    const escapedName = description.name.replaceAll('|', '-');

    return [
        `46c|1`,
        `name|${escapedName}`,
        `guid|${description.guid}`,
        `t|${time}`
    ];
}

export const serialise = (description: ProjectDescription, time: number) => {

    const contents = [
        ...buildHeader(description, time),
        `vra|${vra}`,
        `vcr|${vcr}`,
        `vep|${vep}`,
        `vdr|${vdr}`,
        `vft|${vft}`,
        `vpt|${vpt}`,
        `vlb|${vlb}`,
        `vgr|${vgr}`,
        `vcm|${vcm}`,
        ...buildCommandList(description.commands),
        ...buildByteArray("ra", description.RAM),
        ...buildByteArray("cr", description.COLRAM),
    ].join('\n');

    return contents;
}

export const deserialise = (contents: string | null): ProjectDescription | undefined => {
    if (contents == null) { return; }

    const lines = contents.split(/[\r\n]+/);
    if (lines.length < 1) { return; }

    const firstline = splitToKV(lines.shift());
    if (firstline == null) { return; }

    const [magic, magicArgs] = firstline;
    if (magic !== '46c') { return; }
    if (magicArgs.length !== 1) { return; }
    const version = magicArgs[0];

    if (version === '1') { return deserialise_v01(lines) };
}

const setMemory = (values: string[], buffer: number[]) => {

    if (values.length !== 3) { return; }

    const address = Number(`0x${values[0]}`);
    const count = Number(`0x${values[1]}`);
    const bytesStr = values[2];

    for (let i = 0; i < count; i++) {
        const digit0 = bytesStr[2 * i + 0];
        const digit1 = bytesStr[2 * i + 1];
        const byte = Number(`0x${digit0}${digit1}`);
        buffer[address + i] = byte;
    }
};

const deserialise_v01 = (lines: string[]): ProjectDescription | undefined => {

    const contents = {
        name: '',
        guid: '',
        RAM: new Array(0x10000),
        COLRAM: new Array(0x03e8),
        epDeserialiser: (c: string[]): EntryPointCommand | undefined => { return; },
        drDeserialiser: (c: string[]): DataCommand | undefined => { return; },
        ftDeserialiser: (c: string[]): FormattingCommand | undefined => { return; },
        ptDeserialiser: (c: string[]): PointerCommand | undefined => { return; },
        lbDeserialiser: (c: string[]): LabelCommand | undefined => { return; },
        grDeserialiser: (c: string[]): GraphicCommand | undefined => { return; },
        cmDeserialiser: (c: string[]): CommentCommand | undefined => { return; },
        commands: new Array<Command>()
    };

    const addCommandIfValid = (command?: Command) => {
        if (command != null) { contents.commands.push(command); }
    }

    lines
        .map(l => splitToKV(l))
        .filter((kv): kv is [string, string[]] => kv != null)
        .forEach(kv => {
            const key = kv[0];
            const values = kv[1];

            if (key === 'name') { contents.name = values[0]; }
            if (key === 'guid') { contents.guid = values[0]; }
            if (key === 'ra') { setMemory(values, contents.RAM); }
            if (key === 'cr') { setMemory(values, contents.COLRAM); }
            if (key === 'vep') { contents.epDeserialiser = entryPointCommandDeserialisers[values[0]]; }
            if (key === 'vlb') { contents.lbDeserialiser = labelCommandDeserialisers[values[0]]; }
            if (key === 'vft') { contents.ftDeserialiser = formattingCommandDeserialisers[values[0]]; }
            if (key === 'vpt') { contents.ptDeserialiser = pointerCommandDeserialisers[values[0]]; }
            if (key === 'vdr') { contents.drDeserialiser = dataCommandDeserialisers[values[0]]; }
            if (key === 'vgr') { contents.grDeserialiser = graphicCommandDeserialisers[values[0]]; }
            if (key === 'vcm') { contents.cmDeserialiser = commentCommandDeserialisers[values[0]]; }

            if (key === 'ep') { addCommandIfValid(contents.epDeserialiser(values)); }
            if (key === 'lb') { addCommandIfValid(contents.lbDeserialiser(values)); }
            if (key === 'ft') { addCommandIfValid(contents.ftDeserialiser(values)); }
            if (key === 'pt') { addCommandIfValid(contents.ptDeserialiser(values)); }
            if (key === 'dr') { addCommandIfValid(contents.drDeserialiser(values)); }
            if (key === 'gr') { addCommandIfValid(contents.grDeserialiser(values)); }
            if (key === 'cm') { addCommandIfValid(contents.cmDeserialiser(values)); }
        });

    if (contents.name === '' || contents.guid === '') { return; }

    return newProjectDescription(contents.name, contents.guid, contents.RAM, contents.COLRAM, contents.commands);
}
import React, { ChangeEvent, useEffect, useState } from 'react';
import { fromSnapshot as epFromSnapshot } from '../../classes/commands/EntryPointHelpers';
import { deserialise as deserialiseProjectDescription, newProjectDescription } from '../../classes/ProjectDescriptionHelpers';
import { SnapshotLoader } from '../../classes/SnapshotLoader';
import { PrepareCommands } from '../../classes/TestData';
import { descriptionSet, dirtySet } from '../../store/projectSlice';
import { activeToolSet } from '../../store/toolSlice';


import styles from './FilePicker.module.css';
import settingsStyles from './Setting.module.css';
import { ProjectInfo } from '../../classes/ProjectInfo';
import { deleteAsync, getAsync, listAsync as listLocalAsync, saveAsync } from '../../classes/LocalStorageHelper';
import { deleteAsync as deleteCloudAsync, getAsync as getCloudAsync, listAsync as listCloudAsync, saveAsync as saveCloudAsync } from '../../classes/CloudStorageHelper';
import { newGraphicCommandsFromSnapshot } from '../../classes/commands/GraphicCommandHelpers';
import { makeCopy, makeEmpty, makeGuid } from '../../classes/ProjectDescription';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faCloud, faCopy, faFloppyDisk, faFolderOpen, faTrash } from '@fortawesome/free-solid-svg-icons';
import { getAuth } from 'firebase/auth';
import { useAppDispatch } from '../../store/hooks';
import { logError, logMajorComponentRender } from '../../classes/Logger';

interface IFilePickerProps {
    onClose: () => void;
}

export const FilePicker: React.FC<IFilePickerProps> = (props) => {

    logMajorComponentRender(FilePicker.name);

    const dispatch = useAppDispatch();
    const [isReady, setIsReady] = useState<boolean>(true);
    const [fileInfos, SetFileInfos] = useState<ProjectInfo[]>([]);
    const [fileInfosCloud, SetFileInfosCloud] = useState<ProjectInfo[]>([]);

    const fileInputRef = React.createRef<HTMLInputElement>();

    const refresh = async () => {

        try {
            const cloudFileInfos = await listCloudAsync();
            if (cloudFileInfos != null) {
                cloudFileInfos.sort((a, b) => b.timestamp - a.timestamp);
                SetFileInfosCloud(cloudFileInfos);
            }

            const localFileInfos = await listLocalAsync();
            localFileInfos.sort((a, b) => b.timestamp - a.timestamp);
            SetFileInfos(localFileInfos);
        } catch (error) {
            logError(String(error));
        }
    };

    useEffect(() => {
        (async () => {
            setIsReady(false);
            await refresh();
            setIsReady(true);
        })();
    }, []);

    const handleOpen = async (guid: string, isCloud: boolean) => {
        const getMethod = isCloud ? getCloudAsync : getAsync;
        const file = await getMethod(guid);
        if (file == null) { return; }

        dispatch(descriptionSet({ description: file, isCloud }));
        props.onClose();
    }

    const handleDelete = async (guid: string, isCloud: boolean) => {
        const deleteMethod = isCloud ? deleteCloudAsync : deleteAsync;
        setIsReady(false);
        await deleteMethod(guid);
        await refresh();
        setIsReady(true);
    }

    const handleMakeACopy = async (guid: string, isCloud: boolean, toOtherTarget: boolean = false) => {
        setIsReady(false);

        try {
            const getMethod = isCloud ? getCloudAsync : getAsync;
            const file = await getMethod(guid);
            if (file == null) { return; }

            const copy = makeCopy(file);

            const targetIsCloud = (isCloud !== toOtherTarget);
            const saveMethod = targetIsCloud ? saveCloudAsync : saveAsync;
            await saveMethod(copy, Date.now());

            await refresh();
        }
        catch (error) {
            if (!(error instanceof Error)) { throw error }

            logError(`${error.name} : ${error.message}`);
        }

        setIsReady(true);
    }

    const forwardClickToInputElement = () => {
        fileInputRef.current!.click();
    };

    const handleNew = () => {
        dispatch(descriptionSet({ description: makeEmpty(), isCloud: false }));
        props.onClose();
    }

    const handleTest = () => {
        const { commands, RAM, COLRAM } = PrepareCommands();
        const desc = newProjectDescription('TEST DATA', makeGuid(), RAM, COLRAM, commands);
        dispatch(descriptionSet({ description: desc, isCloud: false }));

        dispatch(activeToolSet('Map'));
        props.onClose();
    }

    const handleCancel = () => {
        props.onClose();
    }

    const handleAddFromFile = (e: ChangeEvent<HTMLInputElement>) => {

        if (e.target.files == null) { return; }
        if (e.target.files.length === 0) { return; }

        const file = e.target.files[0];

        const reader = new FileReader();

        const isProject = file.name.endsWith(".46c");
        if (isProject) {
            reader.onload = (event: ProgressEvent<FileReader>) => addFromProjectFile(event.target?.result as string);
            reader.readAsText(file);
        }

        const isSnapshot = file.name.endsWith(".vsf");
        if (isSnapshot) {
            reader.onload = (event: ProgressEvent<FileReader>) => addFromSnapshotFile(event.target?.result as ArrayBuffer, file.name);
            reader.readAsArrayBuffer(file);
        }
    }

    const addFromProjectFile = (contents: string) => {

        var description = deserialiseProjectDescription(contents);
        if (description == null) { return; }

        dispatch(descriptionSet({ description, isCloud: false }));
        dispatch(dirtySet(true));
        props.onClose();
    }

    const addFromSnapshotFile = (bin: ArrayBuffer, name: string) => {

        const data = new Uint8Array(bin);
        const snapshot = new SnapshotLoader().LoadFromBytes(data);
        if (snapshot == null) { return; }

        const { RAM, COLRAM, graphics, cpu } = snapshot;
        const graphicCommands = newGraphicCommandsFromSnapshot(graphics);
        const entryPointCommand = epFromSnapshot(cpu);
        const description = newProjectDescription(name, makeGuid(), Array.from(RAM), Array.from(COLRAM), [...graphicCommands, entryPointCommand]);
        dispatch(descriptionSet({ description, isCloud: false }));

        dispatch(dirtySet(true));

        props.onClose();
    }


    const getRow = (p: ProjectInfo, index: number) => {

        const timestamp = new Date(p.timestamp);
        const count = isNaN(p.commandCount) ? '-' : p.commandCount;
        const isCloud = p.location === 'cloud';
        const symbol = isCloud ? faCloud : faFloppyDisk;
        const otherSymbol = isCloud ? faFloppyDisk : faCloud;

        const isSignedIn = (getAuth().currentUser != null);

        return (
            <tr key={index} className={styles.itemRow}>
                <td className={styles.itemDetails}>
                    <FontAwesomeIcon icon={symbol} />
                    <span className={styles.name}> &quot;{p.name}&quot;</span>
                    <br />
                    <span className={styles.date}>{count} cmds, saved {timestamp.toLocaleString()}</span>
                </td>
                <td className={styles.itemOperations}>
                    <button className={settingsStyles.button} onClick={() => handleOpen(p.guid, isCloud)}><FontAwesomeIcon icon={faFolderOpen} /></button>
                    <span className={settingsStyles.spacer}>/</span>
                    <button className={settingsStyles.button} onClick={() => handleMakeACopy(p.guid, isCloud)}><FontAwesomeIcon icon={faCopy} /></button>
                    <span className={settingsStyles.spacer}>/</span>
                    {isSignedIn && <>
                        <button className={settingsStyles.button} onClick={() => handleMakeACopy(p.guid, isCloud, true)}><FontAwesomeIcon icon={faCopy} /> <FontAwesomeIcon icon={faArrowRight} /> <FontAwesomeIcon icon={otherSymbol} /></button>
                        <span className={settingsStyles.spacer}>/</span>
                    </>}
                    <button className={settingsStyles.button} onClick={() => handleDelete(p.guid, isCloud)}><FontAwesomeIcon icon={faTrash} /></button>
                </td>
            </tr>
        );
    }

    const getControls = () => {

        return (
            <tr key={'controls'} className={styles.itemRow}>
                <td className={styles.itemDetails}>
                    <button className={settingsStyles.button} onClick={handleTest}>Load test</button>
                </td>
                <td className={styles.itemOperations}>
                    <button className={settingsStyles.button} onClick={handleCancel}>Cancel</button>
                    <span className={settingsStyles.spacer}>/</span>
                    <button className={settingsStyles.button} onClick={forwardClickToInputElement}>Add from file and open...</button>
                    <input className={settingsStyles.hidden} ref={fileInputRef} type="file" onChange={handleAddFromFile}></input>
                    <span className={settingsStyles.spacer}>/</span>
                    <button className={settingsStyles.button} onClick={handleNew}>New</button>
                </td>
            </tr>
        );
    }

    const getItems = (items: ProjectInfo[]) => {
        return items.map((item, index) => getRow(item, index));
    }

    return (
        <>
            {!isReady && <div className={styles.overlay} />}
            <h4 className={styles.section}>Cloud</h4>
            <table className={styles.list}><tbody>{getItems(fileInfosCloud)}</tbody></table>
            <h4 className={styles.section}>Local storage</h4>
            <table className={styles.list}><tbody>{getItems(fileInfos)}</tbody></table>
            <table className={styles.list}><tbody>{getControls()}</tbody></table>
        </>
    );
}
import { Utils } from "../Utils";
import { MemoryLocation } from "../code/MemoryLocation";
import { LabelCommand } from "./LabelCommand";
import { Range } from "../code/Range";

const hex = Utils.to4DigitHexString;

export const version = "1";

export const newLabelCommand = (address: number, text: string): LabelCommand => {
    return {
        type: 'label',
        address,
        text
    }
}

export const markUpMemory = (command: LabelCommand, memory: MemoryLocation[], ranges: Range[]) => {
    memory[command.address].Label = command.text;
}

export const equals = (command: LabelCommand, otherCommand: LabelCommand): boolean => {

    let res = true;
    res &&= (command.address === otherCommand.address);
    res &&= (command.text === otherCommand.text);

    return res;
}

export const serialise = (command: LabelCommand): string => {
    const escapedText = command.text.replaceAll('|', '-');
    return `lb|${hex(command.address)}|${escapedText}`;
}

const deserialise_v01 = (components: string[]): LabelCommand | undefined => {
    if (components.length !== 2) { return; }
    const address = Number(`0x${components[0]}`);
    const text = components[1];

    if (isNaN(address) || text == null) { return; }

    return newLabelCommand(address, text);
}

export const deserialisers: { [version: string]: (c: string[]) => LabelCommand | undefined } = {
    '1': deserialise_v01
};


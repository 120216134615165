import React from 'react';
import { RootState } from '../../store';
import { SettingsGroup } from './SettingsGroup';
import { SettingsSection } from './SettingsSection';
import { TextSetting } from './TextSetting';
import { ButtonSetting } from './ButtonSetting';
import { newProjectDescription, serialise as serialiseProjectDescription } from '../../classes/ProjectDescriptionHelpers';
import { Utils } from '../../classes/Utils';
import { useAppSelector } from '../../store/hooks';
import { logMajorComponentRender } from '../../classes/Logger';

export interface IProjectDetailsSettingsSectionSettings {
}


export const ProjectDetailsSettingsSection: React.FunctionComponent<IProjectDetailsSettingsSectionSettings> = (props) => {

    logMajorComponentRender(ProjectDetailsSettingsSection.name);

    const { name, guid, RAM, COLRAM, commands, dirty } = useAppSelector((state: RootState) => state.project);

    const handleExport = () => {

        const contents = serialiseProjectDescription(newProjectDescription(name, guid, RAM, COLRAM, commands), Date.now());

        Utils.downloadText(contents, name);
    }

    return (
        <SettingsSection name="Details">

            <SettingsGroup>
                <TextSetting label="name" text={name} enabled={true} onChange={() => { }} />
                <TextSetting label="guid" text={guid} enabled={true} onChange={() => { }} />
                <TextSetting label="dirty" text={String(dirty)} enabled={true} onChange={() => { }} />
                <TextSetting label="commands count" text={String(commands.length)} enabled={true} onChange={() => { }} />
                <ButtonSetting label="" buttonText="→ export" onClick={handleExport} />

            </SettingsGroup>

        </SettingsSection>
    );
}

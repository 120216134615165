import * as React from "react";
import { Bitmap } from "../graphics-templates/Bitmap";
import { BitmapScreen } from "../graphics-templates/BitmapScreen";
import { PersistentScroller } from "./PersistentScroller";
import { logMajorComponentRender } from "../../classes/Logger";


export const Bitmaps: React.FunctionComponent = (props) => {

    logMajorComponentRender(Bitmaps.name);

    const renderBitmaps = (bank: number) => {

        let baseIndex = (3 - bank) * 0x02;
        const screen0Address = (baseIndex + 0) * 0x2000;
        const screen1Address = (baseIndex + 1) * 0x2000;

        return (
            <div>
                <h3>Bitmaps</h3>
                <Bitmap startAddress={screen0Address} />
                <Bitmap startAddress={screen1Address} />
            </div>
        );
    }

    const renderBitmapScreens = (bank: number) => {
        let baseIndex = (3 - bank) * 0x10;

        return (
            <div>
                <h3>Colours</h3>
                <BitmapScreen startAddress={(baseIndex + 0x00) * 0x0400} />
                <BitmapScreen startAddress={(baseIndex + 0x01) * 0x0400} />
                <BitmapScreen startAddress={(baseIndex + 0x02) * 0x0400} />
                <BitmapScreen startAddress={(baseIndex + 0x03) * 0x0400} />
                <BitmapScreen startAddress={(baseIndex + 0x04) * 0x0400} />
                <BitmapScreen startAddress={(baseIndex + 0x05) * 0x0400} />
                <BitmapScreen startAddress={(baseIndex + 0x06) * 0x0400} />
                <BitmapScreen startAddress={(baseIndex + 0x07) * 0x0400} />
                <BitmapScreen startAddress={(baseIndex + 0x08) * 0x0400} />
                <BitmapScreen startAddress={(baseIndex + 0x09) * 0x0400} />
                <BitmapScreen startAddress={(baseIndex + 0x0a) * 0x0400} />
                <BitmapScreen startAddress={(baseIndex + 0x0b) * 0x0400} />
                <BitmapScreen startAddress={(baseIndex + 0x0c) * 0x0400} />
                <BitmapScreen startAddress={(baseIndex + 0x0d) * 0x0400} />
                <BitmapScreen startAddress={(baseIndex + 0x0e) * 0x0400} />
                <BitmapScreen startAddress={(baseIndex + 0x0f) * 0x0400} />
            </div>
        );
    }

    return (
        <PersistentScroller name="bitmaps">
            <h2>Bank 3 : $0000-$3fff</h2>
            {renderBitmapScreens(3)}
            {renderBitmaps(3)}
            <h2>Bank 2 : $4000-$7fff</h2>
            {renderBitmapScreens(2)}
            {renderBitmaps(2)}
            <h2>Bank 1 : $8000-$bfff</h2>
            {renderBitmapScreens(1)}
            {renderBitmaps(1)}
            <h2>Bank 0 : $c000-$ffff</h2>
            {renderBitmapScreens(0)}
            {renderBitmaps(0)}
        </PersistentScroller>
    );
}
import * as React from "react";
import { GraphicCommand } from "../../classes/commands/GraphicCommand";
import { allSettingsUpdatedFromCommand, previewCommandSelector } from "../../store/graphicsSlice";
import { ViewportLocation } from "../../store/rendererSlice";
import { currentCommandIndexSet, selectCurrentCommandIndex, commandRemovedById, commandUpdatedById, selectIndexedGraphicsCommands } from "../../store/projectSlice";
import { selectionSet } from "../../store/toolSlice";
import { Graphic } from "../graphics/Graphic";
import { PersistentScroller } from "./PersistentScroller";
import { CSSProperties, useCallback, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { equals } from "../../classes/commands/GraphicCommandHelpers";
import { logMajorComponentRender, logMinorComponentRender } from "../../classes/Logger";


export const Map: React.FunctionComponent = () => {

    logMajorComponentRender(Map.name);

    const dispatch = useAppDispatch();
    const indexedGraphicCommands = useAppSelector(selectIndexedGraphicsCommands);
    const currentCommandIndex = useAppSelector(selectCurrentCommandIndex);
    const previewCommand = useAppSelector(previewCommandSelector);


    // Clear current command index when switching away from this panel
    useEffect(() => {
        return (() => {
            dispatch(currentCommandIndexSet(undefined));
        });

    }, [dispatch]);


    // If the preview has changed (as a result of gfx settings being changed) and is now
    // different to the currently selected command, then update the command
    useEffect(() => {
        if (previewCommand == null) { return; }
        if (currentCommandIndex == null) { return; }

        const currentCommand = indexedGraphicCommands.find(c => c.command.id === currentCommandIndex);
        if (currentCommand == null) { return; }
        if (currentCommand && equals(currentCommand.command, previewCommand)) { return; }

        dispatch(commandUpdatedById({ commandIdToUpdate: currentCommandIndex, command: previewCommand }));

    }, [previewCommand, indexedGraphicCommands, currentCommandIndex, dispatch])


    // Delete button above a graphic has been clicked
    const handleRemove = useCallback((commandId: number) => {
        dispatch(commandRemovedById(commandId));
    }, [dispatch]);


    // A graphic has been clicked 
    const handleSelectCommand = useCallback((command: GraphicCommand) => {
        dispatch(currentCommandIndexSet(command.id));
        dispatch(allSettingsUpdatedFromCommand(command));
        dispatch(selectionSet({ startAddress: command.address, count: command.countBytes }));
    }, [dispatch]);

    const graphicsSettings = indexedGraphicCommands
        .sort((a, b) => a.command.address - b.command.address)
        .map(s => <WrappedGraphicMemo key={s.command.id} selected={s.command.id === currentCommandIndex} command={s.command} onSelect={handleSelectCommand} onRemove={handleRemove} />);

    return (
        <PersistentScroller name="map">
            {graphicsSettings}
        </PersistentScroller>
    )
}



const wrappedGraphicStyle: CSSProperties = {
    display: "inline-grid",
    gridTemplateAreas: '"controls" "graphic"',
    gridTemplateColumns: "auto",
    gridTemplateRows: "auto auto",
    borderWidth: "2px",
    borderStyle: "solid",
    borderColor: "#00000030",
    borderRadius: "6px",
    padding: "2px",
    margin: "6px"
};

const wrappedGraphicStyleSelected: CSSProperties = {
    ...wrappedGraphicStyle,
    borderColor: 'red'
};

const graphicUnselectedStyle: CSSProperties = { gridArea: "graphic", fontWeight: 'normal' }
const graphicSelectedStyle: CSSProperties = { gridArea: "graphic", fontWeight: 'bold' }

const controlsStyle: CSSProperties = { gridArea: "controls" };


const WrappedGraphic: React.FC<{ selected: boolean, command: GraphicCommand, onSelect: (command: GraphicCommand) => void, onRemove: (commandId: number) => void }> = (props) => {

    logMinorComponentRender(WrappedGraphic.name);

    return (
        <div style={props.selected ? wrappedGraphicStyleSelected : wrappedGraphicStyle}>
            <div style={controlsStyle}>
                <button onClick={() => props.onRemove(props.command.id)}>X</button>
            </div>
            <div style={props.selected ? graphicSelectedStyle : graphicUnselectedStyle}>
                <Graphic settings={props.command} location={ViewportLocation.Main} onClick={props.onSelect} showLabel={false} focusable={true} />
            </div>
        </div>
    );
}

const WrappedGraphicMemo = React.memo(WrappedGraphic, (prev, next) => (prev.selected === next.selected) && equals(prev.command, next.command) && (prev.onSelect === next.onSelect) && (prev.onRemove === next.onRemove));
import React from 'react';

import { ViewSettingsSection } from './SettingsPanel/ViewSettingsSection';
import { ColoursSettingsSection } from './SettingsPanel/ColoursSettingsSection';
import { SelectionSettingsSection } from './SettingsPanel/SelectionSettingsSection';
import { CharacterSetSettingsSection } from './SettingsPanel/CharacterSetSettingsSection';
import { SettingsPanelTab } from './SettingsPanelTab';
import { PreviewSettingsSection } from './SettingsPanel/PreviewSettingsSection';
import { PlaceholderSettingsSection } from './SettingsPanel/PlaceholderSettingsSection';
import { ProjectDetailsSettingsSection } from './SettingsPanel/ProjectDetailsSettingsSection';
import { CodeHistorySettingsSection } from './SettingsPanel/CodeHistorySettingsSection';
import { SettingsPanelWithPreviewTab, SplitPanelWithFixedUpper } from './SettingsPanelWithPreviewTab';
import { selectActiveTool } from '../store/toolSlice';
import { MarkupSettingsSection } from './SettingsPanel/MarkupSettingsSection';
import { RangesSettingsSection } from './SettingsPanel/RangesSettingsSection';
import { useAppSelector } from '../store/hooks';
import { logMajorComponentRender } from '../classes/Logger';


export type SettingsPanelProps = {
    gridArea: string;
}


export const SettingsPanel: React.FunctionComponent<SettingsPanelProps> = ((props) => {

    const activeTool = useAppSelector(selectActiveTool);

    logMajorComponentRender(SettingsPanel.name, `with active tool ${activeTool} in gridArea ${props.gridArea}`);

    return (
        <>
            {activeTool === 'About' &&
                <SettingsPanelTab gridArea={props.gridArea}>
                    <PlaceholderSettingsSection>Info about 46c goes here</PlaceholderSettingsSection>
                </SettingsPanelTab>
            }

            {activeTool === 'Project' &&
                <SettingsPanelTab gridArea={props.gridArea}>
                    <ProjectDetailsSettingsSection />
                </SettingsPanelTab>
            }

            {activeTool === 'Graphics' &&
                <SettingsPanelWithPreviewTab gridArea={props.gridArea}
                    settings={
                        <>
                            <SelectionSettingsSection />
                            <ViewSettingsSection />
                            <ColoursSettingsSection />
                            <CharacterSetSettingsSection />
                        </>
                    }
                    preview={
                        <PreviewSettingsSection />
                    }
                />
            }

            {activeTool === 'Code' &&
                <SplitPanelWithFixedUpper
                    gridArea={props.gridArea}
                    upperHeight='400px'
                    scroll={false}
                    upper={
                        <>
                            <SelectionSettingsSection />
                            <CodeHistorySettingsSection />
                            <MarkupSettingsSection />
                        </>
                    }
                    lower={
                        <RangesSettingsSection />
                    }
                />
            }

            {activeTool === 'Map' &&
                <SettingsPanelWithPreviewTab gridArea={props.gridArea}
                    settings={
                        <>
                            <SelectionSettingsSection />
                            <ViewSettingsSection />
                            <ColoursSettingsSection />
                            <CharacterSetSettingsSection />
                        </>
                    }
                    preview={
                        <PreviewSettingsSection />
                    }
                />
            }
        </>
    );
})
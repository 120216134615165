import React from 'react';
import { ISettingProps } from './ISettingProps';
import styles from './ColourSetting.module.css';
import { ColourProperties, pickingColourStarted, selectPickingColour } from '../../store/graphicsSlice';
import { Palette } from '../../classes/Palette';
import { ColourSwatch } from './ColourSwatch';
import { ColourPicker } from './ColourPicker';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { logMinorComponentRender } from '../../classes/Logger';


export interface IColourSettingProps extends ISettingProps {
    swatches: { [label: string]: { colour: number, prop: ColourProperties } };
    order: string[];
}


export const ColourSetting: React.FunctionComponent<IColourSettingProps> = (props) => {

    logMinorComponentRender(ColourSetting.name);

    const currentPickingColour = useAppSelector(selectPickingColour);
    const dispatch = useAppDispatch();

    let swatches = props.order.map((key, index) => {

        let enabled = key in props.swatches;

        let colour = enabled ? props.swatches[key].colour : Palette.None.index;
        let colourProperty = enabled ? props.swatches[key].prop : ColourProperties.None;
        let startPicking = () => {
            if (!enabled) { return; }
            dispatch(pickingColourStarted(colourProperty));
        }

        let beingPicked = enabled ? (currentPickingColour === colourProperty) : false;

        return (
            <ColourSwatch
                key={index}
                label={key}
                colour={colour}
                enabled={enabled}
                beingPicked={beingPicked}
                onClick={startPicking}
            />);
    });

    const colourProperties = props.order.filter(s => s in props.swatches).map(s => props.swatches[s].prop);
    const showPicker = colourProperties.includes(currentPickingColour);

    return (
        <div className={styles.swatches}>
            {swatches}
            {showPicker &&
                <ColourPicker />
            }
        </div>
    );
}
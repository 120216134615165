import React from 'react';
import { newGraphicCommand } from '../../classes/commands/GraphicCommandHelpers';
import { selectInterleavedScale, selectionWidthSet } from '../../store/graphicsSlice';
import { ViewportLocation } from '../../store/rendererSlice';
import { selectionSet } from '../../store/toolSlice';
import { Graphic } from '../graphics/Graphic';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { logMinorComponentRender } from '../../classes/Logger';

export const Bitmap: React.FunctionComponent<{ startAddress: number }> = (props) => {

    logMinorComponentRender(Bitmap.name);

    const dispatch = useAppDispatch();

    const scale = useAppSelector(selectInterleavedScale);

    const { startAddress } = props;

    const settings = newGraphicCommand({ address: startAddress, countBytes: 0x1f40, widthPx: 320, heightPx: 200, scale });

    const handleOnClick = () => {
        dispatch(selectionSet({ startAddress, count: 0x1f40 }));
        dispatch(selectionWidthSet({ width: 40, layout: 'Interleaved' }));
    }

    return <Graphic settings={settings} location={ViewportLocation.Main} onClick={handleOnClick} focusable={true} />
}
import { createSelector } from "@reduxjs/toolkit";
import { selectionEndAddressSet, selectionSet } from "./toolSlice";
import { selectMarkedUpMemory } from "./projectSlice";
import { LineDataManager } from "../classes/code/LineDataManager";
import { store } from ".";
import { addressesAdded } from "./codeSlice";
import { LineRenderer } from "../components/MainPanel/LineRenderer";
import { logSelector } from "../classes/Logger";

export const selectLineDatas = createSelector(
    selectMarkedUpMemory,
    ({ memory, ranges }) => {
        logSelector('extraSelectors', 'lineDatas');
        const bytesPerLine = 8;
        const lineDatas = LineDataManager.makeLineDatas(memory, ranges, bytesPerLine);
        const itemSizes = lineDatas.map((l, i) => {
            return (i < lineDatas.length - 1) ? lineDatas[i + 1].offsetPx - l.offsetPx : l.heightPx;
        });
        const totalItemSize = itemSizes.length > 0 ? itemSizes.reduce((a, b) => a + b) : 0;

        const handleArgumentClick = (sourceAddress: number, argumentAddress: number) => {
            store.dispatch(addressesAdded([sourceAddress, argumentAddress]));
        }

        const handleClick = (e: React.MouseEvent<HTMLElement>, address: number) => {
            if (e.shiftKey) {
                store.dispatch(selectionEndAddressSet(address));
            } else {
                store.dispatch(selectionSet({ startAddress: address, count: 1 }));
            }
        }

        const lr = new LineRenderer(memory, bytesPerLine, handleClick, handleArgumentClick);
        const renderLine = (i: number) => lr.renderLine(lineDatas[i]).lineDOM;


        return { lineDatas, itemSizes, totalItemSize, renderLine };
    }
)
import { ProjectDescription, makeCopy } from "./ProjectDescription";
import { serialise as serialiseProjectDescription, deserialise as deserialiseProjectDescription } from './ProjectDescriptionHelpers';
import { ProjectInfo, serialise as serialiseProjectInfo, deserialise as deserialiseProjectInfo } from "./ProjectInfo";

const supported = () => (typeof window['localStorage'] != "undefined") && (window['localStorage'] != null);

export const listAsync = async () => {

    if (!supported()) { return []; }

    const list = Object.keys(localStorage)
        .filter(key => key.endsWith(".meta"))
        .map(key => deserialiseProjectInfo(localStorage.getItem(key)))
        .filter((p): p is ProjectInfo => p != null)
        .map(i => { return { ...i, location: 'local' } as ProjectInfo; });

    return list;
};

export const getAsync = async (guid: string) => {

    if (!supported()) { return null; }

    const item = localStorage.getItem(`${guid}.46c`);
    if (item != null) { return deserialiseProjectDescription(item); }
}

export const duplicateAsync = async (guid: string) => {

    if (!supported()) { return; }

    const file = await getAsync(guid);
    if (file == null) { return; }

    const copy = makeCopy(file);

    await saveAsync(copy, Date.now());
}

export const saveAsync = async (p: ProjectDescription, time: number) => {

    if (!supported()) { return; }

    localStorage.setItem(`${p.guid}.meta`, serialiseProjectInfo(p, time));
    localStorage.setItem(`${p.guid}.46c`, serialiseProjectDescription(p, time));
}

export const deleteAsync = async (guid: string) => {

    if (!supported()) { return; }

    localStorage.removeItem(`${guid}.meta`);
    localStorage.removeItem(`${guid}.46c`);
}
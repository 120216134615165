import { Command } from "./commands/types";
import { newProjectDescription } from "./ProjectDescriptionHelpers";
import { Utils } from "./Utils";

export const makeGuid = () => Utils.makeId(8);

export const makeEmpty = () => newProjectDescription('', makeGuid(), new Array(0x10000), new Array(0x03e8), new Array<Command>());

export const clone = (description: ProjectDescription) => {
    return newProjectDescription(description.name, description.guid, description.RAM, description.COLRAM, description.commands);
}

export const makeCopy = (description: ProjectDescription) => {

    const copy = clone(description);
    copy.guid = makeGuid();
    copy.name += '-copy';

    return copy;
}


export type ProjectDescription = {
    magic: string,
    name: string,
    guid: string,
    RAM: number[],
    COLRAM: number[],
    commands: Command[]
}


import { ColourMode, ColourSource, GraphicsTypes, ViewLayout } from "./graphics/GraphicsEnums";

export const splitStringToMaxWidthLines = (text: string, maxWidth: number, css: string) => {

    const words = text.split(/\s/);

    const { body } = document;
    const div = document.createElement('div');

    div.setAttribute('style', `position: absolute; width:auto; left:-999999px; ${css}`);
    body.appendChild(div);

    const lines: string[] = [];
    let lineStartIndex = 0;
    let prevLine = '';
    let prevLineWidth = 0;
    for (let index = 0; index < words.length; index++) {

        let thisLine = words.slice(lineStartIndex, index + 1).join(' ');
        div.innerText = thisLine;
        let thisLineWidth = div.offsetWidth;

        const hasOverflown = prevLineWidth <= maxWidth && thisLineWidth > maxWidth;
        if (hasOverflown) {
            lines.push(prevLine);
            lineStartIndex = index;

            // Remeasure, starting with overflown word
            thisLine = words.slice(lineStartIndex, index + 1).join(' ');
            div.innerText = thisLine;
            thisLineWidth = div.offsetWidth;
        }

        prevLine = thisLine;
        prevLineWidth = thisLineWidth;
    }

    lines.push(prevLine);

    // Remove element
    body.removeChild(div);

    return lines;
}

export const splitToKV = (line: string | undefined): [string, string[]] | undefined => {
    if (line == null) { return; }
    if (line.indexOf('|') === -1) { return; }
    const values = line.split('|');
    const key = values.shift();
    if (key == null) { return; }
    return [key, values];
}

export const graphicsTypeToComponents = (graphicsType: GraphicsTypes) => {
    let layout: ViewLayout = 'CharacterMapped';
    let mode: ColourMode = 'HiRes';
    let mainSource: ColourSource = 'Fixed';
    let otherSource: ColourSource = 'Fixed';
    if (graphicsType === 'CharacterMappedHiresFixed') { layout = 'CharacterMapped'; mode = 'HiRes'; mainSource = 'Fixed'; };
    if (graphicsType === 'CharacterMappedHiresCOLRAM') { layout = 'CharacterMapped'; mode = 'HiRes'; mainSource = 'ColourRAM'; };
    if (graphicsType === 'CharacterMappedHiresRAM') { layout = 'CharacterMapped'; mode = 'HiRes'; mainSource = 'MainRAM'; };
    if (graphicsType === 'CharacterMappedMultiColourFixed') { layout = 'CharacterMapped'; mode = 'MultiColour'; mainSource = 'Fixed'; };
    if (graphicsType === 'CharacterMappedMultiColourCOLRAM') { layout = 'CharacterMapped'; mode = 'MultiColour'; mainSource = 'ColourRAM'; };
    if (graphicsType === 'CharacterMappedMultiColourRAM') { layout = 'CharacterMapped'; mode = 'MultiColour'; mainSource = 'MainRAM'; };
    if (graphicsType === 'CharacterMappedExtendedFixed') { layout = 'CharacterMapped'; mode = 'Extended'; mainSource = 'Fixed'; };
    if (graphicsType === 'CharacterMappedExtendedCOLRAM') { layout = 'CharacterMapped'; mode = 'Extended'; mainSource = 'ColourRAM'; };
    if (graphicsType === 'CharacterMappedExtendedRAM') { layout = 'CharacterMapped'; mode = 'Extended'; mainSource = 'MainRAM'; };

    if (graphicsType === 'InterleavedHiresFixed') { layout = 'Interleaved'; mode = 'HiRes'; mainSource = 'Fixed'; };
    if (graphicsType === 'InterleavedHiresRAM') { layout = 'Interleaved'; mode = 'HiRes'; mainSource = 'MainRAM'; };
    if (graphicsType === 'InterleavedMultiColourFixedFixed') { layout = 'Interleaved'; mode = 'MultiColour'; mainSource = 'Fixed'; otherSource = 'Fixed'; };
    if (graphicsType === 'InterleavedMultiColourFixedCOLRAM') { layout = 'Interleaved'; mode = 'MultiColour'; mainSource = 'Fixed'; otherSource = 'ColourRAM'; };
    if (graphicsType === 'InterleavedMultiColourFixedRAM') { layout = 'Interleaved'; mode = 'MultiColour'; mainSource = 'Fixed'; otherSource = 'MainRAM'; };
    if (graphicsType === 'InterleavedMultiColourRAMFixed') { layout = 'Interleaved'; mode = 'MultiColour'; mainSource = 'MainRAM'; otherSource = 'Fixed'; };
    if (graphicsType === 'InterleavedMultiColourRAMCOLRAM') { layout = 'Interleaved'; mode = 'MultiColour'; mainSource = 'MainRAM'; otherSource = 'ColourRAM'; };
    if (graphicsType === 'InterleavedMultiColourRAMRAM') { layout = 'Interleaved'; mode = 'MultiColour'; mainSource = 'MainRAM'; otherSource = 'MainRAM'; };

    if (graphicsType === 'ContinuousHires') { layout = 'Continuous'; mode = 'HiRes'; mainSource = 'Fixed'; };
    if (graphicsType === 'ContinuousMultiColour') { layout = 'Continuous'; mode = 'MultiColour'; mainSource = 'Fixed'; };

    if (graphicsType === 'ColoursDoubleMemory') { layout = 'DoubleMemory'; };

    return { layout, mode, mainSource, otherSource };
}

export const componentsToGraphicsType = (viewLayout: ViewLayout, colourMode: ColourMode, mainColourSource: ColourSource, otherColourSource: ColourSource) => {
    let graphicsType: GraphicsTypes | undefined;

    if (viewLayout === 'CharacterMapped' && colourMode === 'HiRes' && mainColourSource === 'Fixed') { graphicsType = 'CharacterMappedHiresFixed'; }
    else if (viewLayout === 'CharacterMapped' && colourMode === 'HiRes' && mainColourSource === 'MainRAM') { graphicsType = 'CharacterMappedHiresRAM'; }
    else if (viewLayout === 'CharacterMapped' && colourMode === 'HiRes' && mainColourSource === 'ColourRAM') { graphicsType = 'CharacterMappedHiresCOLRAM'; }
    else if (viewLayout === 'CharacterMapped' && colourMode === 'MultiColour' && mainColourSource === 'Fixed') { graphicsType = 'CharacterMappedMultiColourFixed'; }
    else if (viewLayout === 'CharacterMapped' && colourMode === 'MultiColour' && mainColourSource === 'MainRAM') { graphicsType = 'CharacterMappedMultiColourRAM'; }
    else if (viewLayout === 'CharacterMapped' && colourMode === 'MultiColour' && mainColourSource === 'ColourRAM') { graphicsType = 'CharacterMappedMultiColourCOLRAM'; }
    else if (viewLayout === 'CharacterMapped' && colourMode === 'Extended' && mainColourSource === 'Fixed') { graphicsType = 'CharacterMappedExtendedFixed'; }
    else if (viewLayout === 'CharacterMapped' && colourMode === 'Extended' && mainColourSource === 'MainRAM') { graphicsType = 'CharacterMappedExtendedRAM'; }
    else if (viewLayout === 'CharacterMapped' && colourMode === 'Extended' && mainColourSource === 'ColourRAM') { graphicsType = 'CharacterMappedExtendedCOLRAM'; }
    else if (viewLayout === 'Interleaved' && colourMode === 'HiRes' && mainColourSource === 'Fixed') { graphicsType = 'InterleavedHiresFixed'; }
    else if (viewLayout === 'Interleaved' && colourMode === 'HiRes' && mainColourSource === 'MainRAM') { graphicsType = 'InterleavedHiresRAM'; }
    else if (viewLayout === 'Interleaved' && colourMode === 'MultiColour' && mainColourSource === 'Fixed' && otherColourSource === 'Fixed') { graphicsType = 'InterleavedMultiColourFixedFixed'; }
    else if (viewLayout === 'Interleaved' && colourMode === 'MultiColour' && mainColourSource === 'MainRAM' && otherColourSource === 'Fixed') { graphicsType = 'InterleavedMultiColourRAMFixed'; }
    else if (viewLayout === 'Interleaved' && colourMode === 'MultiColour' && mainColourSource === 'Fixed' && otherColourSource === 'MainRAM') { graphicsType = 'InterleavedMultiColourFixedRAM'; }
    else if (viewLayout === 'Interleaved' && colourMode === 'MultiColour' && mainColourSource === 'MainRAM' && otherColourSource === 'MainRAM') { graphicsType = 'InterleavedMultiColourRAMRAM'; }
    else if (viewLayout === 'Interleaved' && colourMode === 'MultiColour' && mainColourSource === 'Fixed' && otherColourSource === 'ColourRAM') { graphicsType = 'InterleavedMultiColourFixedCOLRAM'; }
    else if (viewLayout === 'Interleaved' && colourMode === 'MultiColour' && mainColourSource === 'MainRAM' && otherColourSource === 'ColourRAM') { graphicsType = 'InterleavedMultiColourRAMCOLRAM'; }
    else if (viewLayout === 'Continuous' && colourMode === 'HiRes') { graphicsType = 'ContinuousHires'; }
    else if (viewLayout === 'Continuous' && colourMode === 'MultiColour') { graphicsType = 'ContinuousMultiColour'; }
    else if (viewLayout === 'DoubleMemory') { graphicsType = 'ColoursDoubleMemory' }
    else { graphicsType = 'ColoursSingle'; }

    return graphicsType;
}

export class Utils {

    public static testBitZeroIndexed(number: number, bit: number) {
        return (number & (1 << bit)) !== 0;
    }

    public static to2DigitHexString(number: number) {
        return ("00" + Math.floor(number).toString(16).toUpperCase()).substr(-2);
    }


    public static to4DigitHexString(number: number) {
        return ("0000" + Math.floor(number).toString(16).toUpperCase()).substr(-4);
    }

    public static toHexAuto(number: number) {
        return this.toHexString(number, false);
    }

    public static toHexString(number: number, forceFull: boolean = false) {
        if (number < 0x0100 && !forceFull)
            return this.to2DigitHexString(number);
        else
            return this.to4DigitHexString(number);
    }

    public static toBinary8String(number: number) {
        return ("00000000" + Math.floor(number).toString(2).toUpperCase()).substr(-8);
    }

    public static splitLeadingZeros(number: string) {
        if (number.startsWith('0000'))
            return { zeros: '0000', number: number.substring(4) };
        else if (number.startsWith('000'))
            return { zeros: '000', number: number.substring(3) };
        else if (number.startsWith('00'))
            return { zeros: '00', number: number.substring(2) };
        else if (number.startsWith('0'))
            return { zeros: '0', number: number.substring(1) };
        else
            return { zeros: '', number: number };
    }

    public static leadingZeroCount(number: number) {
        if (number < 0x0000)
            return 0;
        else if (number === 0x0000)
            return 4;
        else if (number < 0x0010)
            return 3;
        else if (number < 0x0100)
            return 2;
        else if (number < 0x1000)
            return 1;
        else
            return 0;
    }

    public static hexStringToNumber(hex: string) {

        // Strip leading $ if it exists
        if (hex.startsWith('$'))
            hex = hex.substr(1, hex.length - 1);

        // Add hex prefix
        hex = `0x${hex}`;

        // Try and turn this into a number
        let value = Number(hex);
        let valid = !isNaN(value) && (value >= 0) && (value < 0x10000);

        return { value: value, valid: valid };
    }

    public static valid16BitNumberOrUndefined(numberString: string): number | undefined {
        return this.validNumberInRangeInclusiveOrUndefined(numberString, 0x0000, 0xffff);
    }

    public static validNumberInRangeInclusiveOrUndefined(numberString: string, lowest: number, highest: number) {
        let value = Number(numberString);
        let valid = !isNaN(value) && (value >= lowest) && (value <= highest);
        return valid ? value : undefined;
    }

    public static getScrollbarSize = () => {
        const { body } = document;
        const scrollDiv = document.createElement('div');

        // Append element with defined styling
        scrollDiv.setAttribute('style', 'width: 1337px; height: 1337px; position: absolute; left: -9999px; overflow: scroll;')
        body.appendChild(scrollDiv);

        // Collect width & height of scrollbar
        const scrollbarWidth = scrollDiv[`offsetWidth`] - scrollDiv[`clientWidth`];
        const scrollbarHeight = scrollDiv[`offsetHeight`] - scrollDiv[`clientHeight`];

        // Remove element
        body.removeChild(scrollDiv);

        return {
            width: scrollbarWidth,
            height: scrollbarHeight
        };
    };

    public static clamp = (value: number, min: number, max: number) => Math.max(min, Math.min(value, max));

    public static makeId = (count: number) => {
        let text = "";
        let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";

        for (var i = 0; i < count; i++)
            text += possible.charAt(Math.floor(Math.random() * possible.length));

        return text;
    }

    public static downloadJson = (json: string, name: string) => {

        let downloadName = Utils.makeJsonFilename(name);
        let blob = new Blob([json], { type: 'application/json' });
        Utils.downloadBlob(blob, downloadName);
    }

    public static downloadText = (text: string, name: string) => {

        let downloadName = Utils.makeJsonFilename(name);
        let blob = new Blob([text], { type: 'application/text' });
        Utils.downloadBlob(blob, downloadName);
    }

    private static makeJsonFilename = (name: string) => {
        if (name === '') {
            name = 'unnamed';
        }

        if (!name.endsWith('.46c')) {
            name += '.46c';
        }

        return name;
    }

    private static downloadBlob = (blob: Blob, name: string) => {

        let url = URL.createObjectURL(blob);

        let anchor = document.createElement('a');
        anchor.href = url;
        anchor.target = "_blank";
        anchor.download = name;

        anchor.click();
        URL.revokeObjectURL(url);

        anchor.remove();

    }

    public static stringHashCyrb53 = (str: string, seed: number = 0) => {
        /* From here : https://stackoverflow.com/a/52171480 */
        let h1 = 0xdeadbeef ^ seed, h2 = 0x41c6ce57 ^ seed;
        let ch: number;
        let charCount = str.length;
        for (let i = 0; i < charCount; i++) {
            ch = str.charCodeAt(i);
            h1 = Math.imul(h1 ^ ch, 2654435761);
            h2 = Math.imul(h2 ^ ch, 1597334677);
        }
        h1 = Math.imul(h1 ^ (h1 >>> 16), 2246822507) ^ Math.imul(h2 ^ (h2 >>> 13), 3266489909);
        h2 = Math.imul(h2 ^ (h2 >>> 16), 2246822507) ^ Math.imul(h1 ^ (h1 >>> 13), 3266489909);
        return 4294967296 * (2097151 & h2) + (h1 >>> 0);
    };


    private static readonly charCodes =
        '................' + // 0x00 - 0x0f
        '................' + // 0x10 - 0x1f
        '\u00A0!"#$%&\'()*+,-./' + // 0x20 - 0x2f
        '0123456789:;<=>?' + // 0x30 - 0x3f
        '@abcdefghijklmno' + // 0x40 - 0x4f
        'pqrstuvwxyz[£]..' + // 0x50 - 0x5f
        '-ABCDEFGHIJKLMNO' + // 0x60 - 0x6f
        'PQRSTUVWXYZ.....' + // 0x70 - 0x7f
        '................' + // 0x80 - 0x8f
        '................' + // 0x90 - 0x9f
        '................' + // 0xa0 - 0xaf
        '................' + // 0xb0 - 0xbf
        '.ABCDEFGHIJKLMNO' + // 0xc0 - 0xcf
        'PQRSTUVWXYZ.....' + // 0xd0 - 0xdf
        '................' + // 0xe0 - 0xef
        '................';  // 0xf0 - 0xff


    public static toChar(charCode: number) {

        if (charCode < 0 || charCode > 0xff) {
            return { char: ".", valid: false };
        }
        else {
            const char = this.charCodes[charCode];
            const isValid = (charCode === 0x2e) || (char !== '.');
            return { char: char, valid: isValid };
        }
    }

    public static groupByToMap<T, Q>(array: T[], predicate: (value: T, index: number, array: T[]) => Q) {
        return array.reduce((map, value, index, array) => {
            const key = predicate(value, index, array);
            map.get(key)?.push(value) ?? map.set(key, [value]);
            return map;
        }, new Map<Q, T[]>())
    };
}
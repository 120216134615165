import React from "react";
import { Utils } from "../../classes/Utils";
import { codeHistorySelector, navigatedBackwards, navigatedForwards } from "../../store/codeSlice";
import { SettingsSection } from "./SettingsSection";
import styles from "./CodeHistorySettingsSection.module.css";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { logMajorComponentRender } from "../../classes/Logger";

export const CodeHistorySettingsSection: React.FunctionComponent = () => {

    logMajorComponentRender(CodeHistorySettingsSection.name);

    const { history, index, canGoForward, canGoBack, address, currentLineStartAddress, currentLineEndAddress } = useAppSelector(codeHistorySelector);

    const dispatch = useAppDispatch();

    const handleBack = () => {
        dispatch(navigatedBackwards());
    }

    const handleForward = () => {
        dispatch(navigatedForwards());
    }

    const breadCrumbs = history
        .map((h, i) => {
            const addressStr = `$${Utils.to4DigitHexString(h)}`;
            const selected = (i === index) ? styles.selected : '';
            const finalStr = <span key={i} className={`${styles.breadcrumb} ${selected}`}>{addressStr}</span>;
            return finalStr;
        });

    const showDebug = false;

    const debug = !showDebug ? null :
        <>
            <div>{breadCrumbs}</div>
            <div>Index : {index}</div>
            <div>Address : ${Utils.to4DigitHexString(address)}</div>
            <div>Current line start/end addresses : ${Utils.to4DigitHexString(currentLineStartAddress)}/${Utils.to4DigitHexString(currentLineEndAddress)}</div>
        </>;


    return (
        <SettingsSection name="History">
            <button disabled={!canGoBack} onClick={() => handleBack()}>BACK</button>
            <button disabled={!canGoForward} onClick={() => handleForward()}>FORWARD</button>
            {debug}
        </SettingsSection>
    );
}
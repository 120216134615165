import React, { useCallback } from 'react';
import { ToolTypes, activeToolSet } from '../store/toolSlice';

import styles from './SettingsTypePanel.module.css';
import settingStyles from './SettingsPanel/Setting.module.css';
import cx from 'classnames';
import { selectActiveTool } from '../store/toolSlice';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { logMajorComponentRender } from '../classes/Logger';





interface ITabsSettingProps<T> {
    options: { [label: string]: T };
    selectedOption: T;
    order: string[];
    onChange: (v: T) => void;
}


const TabsSetting: React.FunctionComponent<ITabsSettingProps<ToolTypes>> = (props) => {

    logMajorComponentRender(TabsSetting.name);

    let items = props.order.map((key, index) => {

        let selected = (props.options[key] === props.selectedOption);

        let value = <>{key}</>;

        if (selected)
            value = <span>{value}</span>
        else
            value = <button className={settingStyles.button} onClick={() => props.onChange(props.options[key])}>{value}</button>;

        value = <li key={index} className={cx(styles.li, selected ? styles.selected : '')}>{value}</li>;

        return value;
    });

    return (
        <ul className={styles.ul}>{items}</ul>
    );
}





export interface ISettingsTypePanelProps {
    gridArea: string;
}

const options: { [label: string]: ToolTypes } = { 'proj': 'Project', 'gfx': 'Graphics', 'code': 'Code', 'map': 'Map', '~?~': 'About' };
const order = ['~?~', 'proj', 'gfx', 'map', 'code'];

export const SettingsTypePanel: React.FunctionComponent<ISettingsTypePanelProps> = (props) => {

    const dispatch = useAppDispatch();
    const activeTool = useAppSelector(selectActiveTool);

    logMajorComponentRender(SettingsTypePanel.name, `with active tool ${activeTool} in gridArea ${props.gridArea}`);

    const onChange = useCallback(
        (v: ToolTypes) => dispatch(activeToolSet(v))
        , [dispatch]
    );

    return (
        <div style={{ gridArea: props.gridArea }} className={styles.panel}>
            <TabsSetting
                selectedOption={activeTool}
                options={options}
                order={order}
                onChange={onChange}
            />
        </div>
    );
}

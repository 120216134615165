import { PayloadAction, createSelector, createSlice } from "@reduxjs/toolkit";
import { RootState } from ".";
import { logReducer } from "../classes/Logger";
import { Utils } from "../classes/Utils";

export type CodeState = {
    history: number[];
    index: number;
    currentLineStartAddress: number;
    currentLineEndAddress: number;
}

const initialState: CodeState = {
    history: [0x0000],
    index: 0,
    currentLineStartAddress: -1,
    currentLineEndAddress: -1,
}

const addressListToHex = (addresses: number[], highlightIndex: number) => {
    return `${addresses.map((h, i) => {
        const x = i === highlightIndex ? '*' : '';
        return `${x}${Utils.to4DigitHexString(h)}${x}`;
    }).join(", ")}`
}

const isDirty = (state: CodeState) => (state.currentLineStartAddress > state.history[state.index]) || (state.currentLineEndAddress < state.history[state.index]);

const codeSlice = createSlice({
    name: 'code',
    initialState,
    reducers: {
        addressesAdded(state, action: PayloadAction<number[]>) {
            logReducer('codeSlice', 'addressesAdded', `Adding ${addressListToHex(action.payload, -1)} to [${addressListToHex(state.history, state.index)}]`);
            if (action.payload.length === 0) { return; }

            const singleAddress = (action.payload.length === 1);

            if (isDirty(state) && singleAddress) {
                const alreadyAtThisAddress = state.history[state.index] === action.payload[0];
                if (alreadyAtThisAddress) { return; }

                // Insert current location after current index, then chop off the rest of the forward history
                // and glue the new address onto the end
                state.history.splice(state.index + 1, 0, state.currentLineStartAddress).splice(state.index + 2).push(action.payload[0]);
            } else {
                state.history.splice(state.index + 1);

                for (let index = 0; index < action.payload.length; index++) {
                    if (state.history[state.index + index] !== action.payload[index]) {
                        state.history.push(action.payload[index]);
                    }
                }
            }

            state.index = state.history.length - 1;
            logReducer('codeSlice', 'addressesAdded', `History after: [${addressListToHex(state.history, state.index)}]`);
            document.dispatchEvent(new CustomEvent<number>('navigate-to-address', { detail: state.history[state.index] }));
        },

        currentLineAddressBoundsSet(state, action: PayloadAction<{ startAddress: number, endAddress: number }>) {
            //logReducer('codeSlice', 'currentLineAddressBoundsSet');
            if (state.currentLineStartAddress === action.payload.startAddress && state.currentLineEndAddress === action.payload.endAddress) { return; }
            state.currentLineStartAddress = action.payload.startAddress;
            state.currentLineEndAddress = action.payload.endAddress;
        },

        navigatedBackwards(state) {
            logReducer('codeSlice', 'navigatedBackwards', `History before: [${addressListToHex(state.history, state.index)}]`);
            if (state.index === 0) { return; }
            if (!isDirty(state)) {
                state.index -= 1;
            } else {
                state.history.splice(state.index + 1, 0, state.currentLineStartAddress);
            }

            logReducer('codeSlice', 'navigatedBackwards', `History after: [${addressListToHex(state.history, state.index)}]`);
            document.dispatchEvent(new CustomEvent<number>('navigate-to-address', { detail: state.history[state.index] }));
        },

        navigatedForwards(state) {
            logReducer('codeSlice', 'navigatedForwards', `History before: [${addressListToHex(state.history, state.index)}]`);
            if (state.index >= state.history.length) { return; }
            if (!isDirty(state)) {
                state.index += 1;
            } else {
                state.history.splice(state.index + 1, 0, state.currentLineStartAddress);
                state.index += 2;
            }

            logReducer('codeSlice', 'navigatedForwards', `History after: [${addressListToHex(state.history, state.index)}]`);
            document.dispatchEvent(new CustomEvent<number>('navigate-to-address', { detail: state.history[state.index] }));
        }
    }
})

export const { addressesAdded, currentLineAddressBoundsSet, navigatedBackwards, navigatedForwards } = codeSlice.actions;
export default codeSlice.reducer;


export const codeHistorySelector = createSelector(
    (state: RootState) => state.code.history,
    (state: RootState) => state.code.index,
    (state: RootState) => state.code.currentLineStartAddress,
    (state: RootState) => state.code.currentLineEndAddress,
    (history, index, currentLineStartAddress, currentLineEndAddress) => {

        const address = history[index];

        const canGoBack = index > 0;
        const canGoForward = index < history.length - 1;

        return { history, index, address, currentLineStartAddress, currentLineEndAddress, canGoBack, canGoForward };
    }
)

export const selectCurrentHistoryAddress = (state: RootState) => state.code.history[state.code.index];
import React from 'react';
import styles from './LogoPanel.module.css';
import img from './LogoPanel/logo.png';
import { logMajorComponentRender } from '../classes/Logger';


export interface ILogoPanelProps {
    gridArea: string;
}


export const LogoPanel: React.FunctionComponent<ILogoPanelProps> = (props) => {

    logMajorComponentRender(LogoPanel.name);

    const sha1 = process.env.REACT_APP_SHA1;
    const flag = process.env.NODE_ENV.substring(0, 1);

    return (
        <div style={{ gridArea: props.gridArea }} className={styles.panel}>
            <img alt="" src={img} className={styles.logo} />
            <p className={styles.subheading}>..reversing the c64..</p>
            <p className={styles.details}>@46cio | #{sha1}-{flag}</p>
        </div>
    );
}
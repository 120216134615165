import React from 'react';
import { SettingsSection } from './SettingsSection'
import { SettingsGroup } from './SettingsGroup';
import { ColourProperties, characterMappedCharacterSetModeSet, characterMappedCharacterSetAddressSet, selectCharacterSetInfo } from '../../store/graphicsSlice';
import { EnumSetting } from './EnumSetting';
import { ColourSetting } from './ColourSetting';
import { HexNumberInput } from './HexNumberInput';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { logMajorComponentRender } from '../../classes/Logger';


export const CharacterSetSettingsSection: React.FunctionComponent = () => {

    logMajorComponentRender(CharacterSetSettingsSection.name);

    const dispatch = useAppDispatch();
    const { viewLayout, address, mode, hiresBackground00, hiresForeground01, multiBackground00, multiForeground01, multiForeground10, multiForeground11 } = useAppSelector(selectCharacterSetInfo);

    const order = ['00', '01', '10', '11'];
    const swatches: { [label: string]: { colour: number, prop: ColourProperties } } = {};
    if (viewLayout === 'CharacterMapped') {

        if (mode === 'HiRes') {
            swatches['00'] = { colour: hiresBackground00, prop: ColourProperties.CharacterMappedCharacterSetHiresBackground00 };
            swatches['01'] = { colour: hiresForeground01, prop: ColourProperties.CharacterMappedCharacterSetHiresForeground01 };
        } else {
            swatches['00'] = { colour: multiBackground00, prop: ColourProperties.CharacterMappedCharacterSetMultiColourBackground00 };
            swatches['01'] = { colour: multiForeground01, prop: ColourProperties.CharacterMappedCharacterSetMultiColourForeground01 };
            swatches['10'] = { colour: multiForeground10, prop: ColourProperties.CharacterMappedCharacterSetMultiColourForeground10 };
            swatches['11'] = { colour: multiForeground11, prop: ColourProperties.CharacterMappedCharacterSetMultiColourForeground11 };
        }
    }

    return (
        <SettingsSection name="Character Set">

            <SettingsGroup>
                <HexNumberInput
                    label={"address"}
                    enabled={viewLayout === 'CharacterMapped'}
                    number={address}
                    onChange={n => dispatch(characterMappedCharacterSetAddressSet(n))}
                    forceFourDigits={true}
                />
            </SettingsGroup>

            <SettingsGroup>
                <EnumSetting
                    label="mode"
                    selectedOption={mode}
                    options={viewLayout === 'CharacterMapped' ? { 'hires': 'HiRes', 'multicol': 'MultiColour' } : {}}
                    order={['hires', 'multicol']}
                    onChange={v => dispatch(characterMappedCharacterSetModeSet(v))}
                />
                <ColourSetting
                    label="colours"
                    swatches={swatches}
                    order={order}
                />
            </SettingsGroup>

        </SettingsSection>
    );
}
import { GoogleAuthProvider, User, getAuth, signInWithPopup } from 'firebase/auth';
import React, { MouseEvent, useEffect, useState } from 'react';
import { Modal } from './SettingsPanel/Modal';
import styles from './UserPanel.module.css';

import { GithubAuthProvider } from 'firebase/auth';
import { makeEmpty } from '../classes/ProjectDescription';
import { descriptionSet, selectProjectStatus } from '../store/projectSlice';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGithub, faGoogle, } from '@fortawesome/free-brands-svg-icons';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { logError, logMajorComponentRender } from '../classes/Logger';

interface IUserPanel {
    gridArea: string;
}

// Admin users
// Use firestore to check uid : https://firebase.blog/posts/2022/09/announcing-cross-service-security-rules
// Add custom claims : https://medium.com/firebase-tips-tricks/how-to-create-an-admin-module-for-managing-users-access-and-roles-34a94cf31a6e

export const UserPanel: React.FunctionComponent<IUserPanel> = (props) => {

    logMajorComponentRender(UserPanel.name);

    const dispatch = useAppDispatch();
    const { isCloud, dirty } = useAppSelector(selectProjectStatus);

    const [signInModalIsOpen, SetSignInModalIsOpen] = useState<boolean>(false);
    const [confirmationModalIsOpen, SetConfirmationModalIsOpen] = useState<boolean>(false);

    const handleCloseSignInModal = () => {
        SetSignInModalIsOpen(false);
    }

    const handleCloseConfirmationModal = () => {
        SetConfirmationModalIsOpen(false);
    }

    const handleOpenSignInPanel = (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        SetSignInModalIsOpen(true);
    }

    const [user, SetUser] = useState<User | null>(null);
    const signedIn = (user != null);

    useEffect(() => {
        const unsubscribe = getAuth().onAuthStateChanged(authUser => SetUser(authUser));
        return () => unsubscribe();
    }, []);

    const handleSignOut = (e: MouseEvent) => {

        e.preventDefault();
        e.stopPropagation();

        // We're working on a local file, so it doesn't matter whether we're logged in or not
        if (!isCloud) {
            getAuth().signOut();
            return;
        }

        // We're working on a cloud file but there are no changes to save so it's safe to log out
        // Reset the project when we've logged out
        if (!dirty) {
            getAuth().signOut();
            dispatch(descriptionSet({ description: makeEmpty(), isCloud: false }));
            return;
        }

        // We're working on a cloud file and there are changes which need saving, so work could be lost
        SetConfirmationModalIsOpen(true);
    }

    const handleForceSignOut = () => {
        getAuth().signOut();
        dispatch(descriptionSet({ description: makeEmpty(), isCloud: false }));
        SetConfirmationModalIsOpen(false);
    }

    const hasProviderData = (signedIn && user.providerData.length > 0);
    const displayName = hasProviderData ? `Hi ${user.providerData[0].displayName?.split(' ')[0]}` : '(no name)';
    const email = hasProviderData ? `(${user.providerData[0].email})` : '';

    const handleGithubSignIn = async () => {
        try {
            const provider = new GithubAuthProvider();
            provider.addScope('user');
            provider.setCustomParameters({ 'allow_signup': 'false' });
            const auth = getAuth();
            await signInWithPopup(auth, provider);
            handleCloseSignInModal();
        } catch (error) {
            logError(String(error));
        }
    };

    const handleGoogleSignIn = async () => {
        try {
            const provider = new GoogleAuthProvider();
            provider.addScope('https://www.googleapis.com/auth/userinfo.email');
            provider.addScope('https://www.googleapis.com/auth/userinfo.profile');
            provider.setCustomParameters({ 'allow_signup': 'false' });
            const auth = getAuth();
            await signInWithPopup(auth, provider);
            handleCloseSignInModal();
        } catch (error) {
            logError(String(error));
        }
    };

    return (
        <div className={styles.panel} style={{ gridArea: props.gridArea }}>

            <div className={styles.row}>
                {signedIn &&
                    <>
                        <div style={{ gridArea: 'name' }} className={styles.name}>{displayName} <span className={styles.nouser}>{email}</span></div>
                        <button style={{ gridArea: 'action' }} onClick={handleSignOut} className={styles.button}>Sign out</button>
                    </>
                }

                {!signedIn &&
                    <>
                        <div style={{ gridArea: 'name' }} className={styles.name}><span className={styles.nouser}>Not signed in</span></div>
                        <button style={{ gridArea: 'action' }} onClick={handleOpenSignInPanel} className={styles.button}>Sign in...</button>
                    </>
                }
            </div>

            {signInModalIsOpen &&
                <Modal onClose={handleCloseSignInModal} style={{ backgroundColor: '#1e242e', padding: '6px' }}>
                    <div className={styles.section}>
                        <h4>Sign in</h4>
                        <ul>
                            <li><button onClick={handleGoogleSignIn} className={`${styles.signInButton} ${styles.google}`}><FontAwesomeIcon icon={faGoogle} /> Sign in with Google</button></li>
                            <li><button onClick={handleGithubSignIn} className={`${styles.signInButton} ${styles.github}`}><FontAwesomeIcon icon={faGithub} /> Sign in with GitHub</button></li>
                        </ul>
                    </div>
                </Modal>
            }

            {confirmationModalIsOpen &&
                <Modal onClose={handleCloseConfirmationModal} style={{ backgroundColor: '#1e242e', padding: '6px' }}>
                    <div className={styles.section}>
                        <h4>Unsaved changes</h4>
                        <p>Signing out now will lose unsaved changes</p>
                        <div>
                            <button onClick={handleCloseConfirmationModal}>Cancel</button>
                            <button onClick={handleForceSignOut} className={styles.button}>Sign out</button>
                        </div>
                    </div>
                </Modal>
            }
        </div>
    );
}
import React, { CSSProperties } from 'react';
import styles from './MainPanel.module.css';
import { Sprites } from './MainPanel/Sprites';
import { Bitmaps } from './MainPanel/Bitmaps';
import { Screens } from './MainPanel/Screens';
import { selectActiveTool } from '../store/toolSlice';
import { selectViewLayout } from '../store/graphicsSlice';
import { Bytes } from './MainPanel/Bytes';
import { Map } from './MainPanel/Map';
import { GraphicsViewport } from './graphics/GraphicsViewport';
import { ViewportLocation } from '../store/rendererSlice';
import { useAppSelector } from '../store/hooks';
import { logMajorComponentRender } from '../classes/Logger';


export interface IMainPanelProps {
    gridArea: string;
}


export const MainPanel: React.FunctionComponent<IMainPanelProps> = ((props) => {

    logMajorComponentRender(MainPanel.name);

    const mainPanelStyle: CSSProperties = {
        gridArea: props.gridArea,
    };

    return (
        <GraphicsViewport location={ViewportLocation.Main} ignoreScrollbars={{ h: true, v: false }} show={{ marked: true, selected: true }} render={r =>
            <div style={mainPanelStyle} className={styles.panel} ref={r}>
                <div className={styles.inner}>
                    <MainPanelContents />
                </div>
            </div>
        } />
    );
})

export const MainPanelContents: React.FunctionComponent = () => {

    logMajorComponentRender(MainPanelContents.name);

    const tool = useAppSelector(selectActiveTool);
    const layout = useAppSelector(selectViewLayout);

    const showScreens = (tool === 'Graphics') && (layout === 'CharacterMapped');
    const showBitmaps = (tool === 'Graphics') && (layout === 'Interleaved');
    const showSprites = (tool === 'Graphics') && (layout === 'Continuous');
    const showBytes = (tool === 'Code');
    const showMap = (tool === 'Map');
    const showProj = (tool === 'Project');

    return (
        <>
            {showSprites && <Sprites />}
            {showBitmaps && <Bitmaps />}
            {showScreens && <Screens />}
            {showBytes && <Bytes />}
            {showMap && <Map />}
            {showProj && <div></div>}
        </>

    );
}
import React from 'react';
import cx from 'classnames';
import { ISettingProps } from './ISettingProps';
import styles from './Setting.module.css';
import { logMinorComponentRender } from '../../classes/Logger';


export interface IEnumSettingProps extends ISettingProps {
    options: { [label: string]: any };
    selectedOption: any;
    order: string[];
    onChange: (v: any) => void;
}


export const EnumSetting: React.FunctionComponent<IEnumSettingProps> = (props) => {

    logMinorComponentRender(EnumSetting.name);

    let items = props.order.map((key, index) => {

        let enabled = key in props.options;
        let selected = (props.options[key] === props.selectedOption);
        let value = <>{key}</>;

        const selectedStyle = selected ? styles.selected : '';
        const disabledStyle = !enabled ? styles.disabled : '';

        if (selected || !enabled)
            value = <span className={cx(disabledStyle, selectedStyle)}>{value}</span>
        else
            value = <button className={styles.button} onClick={() => props.onChange(props.options[key])}>{value}</button>;

        if (index > 0)
            value = <><span className={styles.enumSpacer}>/</span>{value}</>;

        value = <li key={index} className={styles.li}>{value}</li>;

        return value;
    });

    return (
        <ul className={styles.ul}>{items}</ul>
    );
}

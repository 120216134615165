import * as React from "react";
import cx from 'classnames';
import styles from './HexNumberInput.module.css';

import { ISettingProps } from "./ISettingProps";
import { logMinorComponentRender } from "../../classes/Logger";


export interface ITextInputProps extends ISettingProps {
    onChange: (newText: string) => void;
    text: string;
    enabled: boolean;
    long?: boolean;
    variableWidth?: boolean;
}

export const TextInput: React.FunctionComponent<ITextInputProps> = (props) => {

    logMinorComponentRender(TextInput.name);

    const [textString, setTextString] = React.useState<string>(props.text);
    const [textStringIsValid, setTextStringIsValid] = React.useState<boolean>(true);
    const [isFocused, setIsFocused] = React.useState<boolean>(false);

    const inputRef = React.useRef<HTMLInputElement>(null);

    React.useEffect(() => {
        setTextString(props.text);
    }, [props.text])

    React.useEffect(() => {
        if (isFocused) {
            inputRef.current?.select();
        }
    }, [inputRef, isFocused]);


    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {

        const str = e.target.value;
        setTextString(str);

        const valid = (str.length > 0);
        setTextStringIsValid(valid);
    }

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {

        if (e.key === "Escape") {
            e.preventDefault();
            setTextString(props.text);
            setTextStringIsValid(true);
        }
    }

    const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {

        if (e.key === "Enter") {
            e.preventDefault();
            changeValueIfValid();
        }
    }

    const handleBlur = () => {

        setIsFocused(false);
        changeValueIfValid();
    }

    const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {

        setIsFocused(true);
    }

    const changeValueIfValid = () => {
        if (!textStringIsValid) { return; }
        if (textString === props.text) { return; }
        props.onChange(textString);
    }

    const isLong = (props.long == null) ? false : props.long;
    const isVariableWidth = props.variableWidth ?? false;

    return (
        <input
            ref={inputRef}
            type="text"
            disabled={!props.enabled}
            value={textString}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            onKeyPress={handleKeyPress}
            onBlur={handleBlur}
            onFocus={handleFocus}
            className={cx(styles.input, textStringIsValid ? '' : styles.bad, isLong ? styles.long : '', isVariableWidth ? styles.variableWidth : '')}
        />
    );
}
import { ProjectDescription } from "./ProjectDescription";
import { buildHeader } from "./ProjectDescriptionHelpers";
import { splitToKV } from "./Utils";

export type ProjectInfo = {
    location?: 'local' | 'cloud',
    guid: string,
    name: string,
    timestamp: number,
    commandCount: number
}

export const serialise = (description: ProjectDescription, time: number) => {
    return [
        ...buildHeader(description, time),
        `c|${description.commands.length}`
    ].join("\n");
}

export const deserialise = (contents: string | null): ProjectInfo | undefined => {

    if (contents == null) { return; }

    const lines = contents.split(/[\r\n]+/);
    if (lines.length < 1) { return; }

    const firstline = splitToKV(lines.shift());
    if (firstline == null) { return; }

    const [magic, magicArgs] = firstline;
    if (magic !== '46c') { return; }
    if (magicArgs.length !== 1) { return; }
    const version = magicArgs[0];

    if (version === '1') { return deserialise_v01(lines); }
}

const deserialise_v01 = (lines: string[]): ProjectInfo | undefined => {

    let guid = '';
    let name = '';
    let timestamp = NaN;
    let commandCount = NaN;

    lines
        .map(l => splitToKV(l))
        .filter((kv): kv is [string, string[]] => kv != null)
        .forEach(kv => {
            const [key, values] = kv;
            if (key === "guid") { guid = values[0]; }
            if (key === "name") { name = values[0]; }
            if (key === "t") { timestamp = Number(values[0]); }
            if (key === 'c') { commandCount = Number(values[0]); }
        });

    if (guid === '' || name === '' || isNaN(timestamp) || isNaN(commandCount)) { return; }

    return { guid, name, timestamp, commandCount };
}

import React from 'react';
import { newGraphicCommand } from '../../classes/commands/GraphicCommandHelpers';
import { characterMappedCharacterSetAddressSet, allSettingsUpdatedFromCommand, selectCharacterSetData } from '../../store/graphicsSlice';
import { ViewportLocation } from '../../store/rendererSlice';
import { Graphic } from '../graphics/Graphic';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { logMinorComponentRender } from '../../classes/Logger';
import { selectionSet } from '../../store/toolSlice';

export const CharacterSet: React.FunctionComponent<{ startAddress: number }> = (props) => {

    logMinorComponentRender(CharacterSet.name);

    const dispatch = useAppDispatch();

    const { startAddress } = props;

    const { type, colour00, colour01, colour10, colour11 } = useAppSelector(selectCharacterSetData);

    const settings = newGraphicCommand({ address: startAddress, countBytes: 0x0800, widthPx: 128, heightPx: 128, scale: 1, graphicsType: type, referenceAddress1: 0x0000, referenceAddress2: 0x0000, colour00, colour01, colour10, colour11 });

    const handleOnClick = () => {
        dispatch(characterMappedCharacterSetAddressSet(props.startAddress));
    }

    const handleOnDoubleClick = () => {
        dispatch(allSettingsUpdatedFromCommand(settings!));
        dispatch(selectionSet({ startAddress: settings!.address, count: settings.countBytes }));
    }

    return (
        <Graphic
            settings={settings}
            location={ViewportLocation.Main}
            onClick={handleOnClick}
            onDoubleClick={handleOnDoubleClick}
            focusable={false}
        />
    );
}
import * as React from "react";

import { VirtualList } from "./VirtualList";
import { useCallback, useEffect, useState } from "react";
import { currentLineAddressBoundsSet } from "../../store/codeSlice";
import { CentralHighlight } from "./CentralHightlight";
import { selectLineDatas } from "../../store/extraSelectors";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { logInfo, logMajorComponentRender } from "../../classes/Logger";
import { Utils } from "../../classes/Utils";

export const Bytes: React.FunctionComponent = () => {

    logMajorComponentRender(Bytes.name);

    const dispatch = useAppDispatch();
    const { lineDatas, itemSizes, totalItemSize, renderLine } = useAppSelector(selectLineDatas);

    const [currentLineHeight, SetCurrentLineHeight] = useState(0);

    // const handleClickOutside = (e: React.MouseEvent<HTMLElement>) => {

    //     e.preventDefault();
    //     e.stopPropagation();
    //     e.nativeEvent.stopImmediatePropagation();

    //     dispatch(setSelection(0, 0));
    // }

    const setMiddleItem = useCallback((i: number) => {
        const lineData = lineDatas[i];
        if (lineData === undefined) { return; }

        const middleLineHeight = lineData.heightPx;
        SetCurrentLineHeight(middleLineHeight);

        const middleLineStartAddress = lineData.baseAddress;
        const middleLineEndAddress = middleLineStartAddress + Math.max(lineData.count - 1, 0);
        dispatch(currentLineAddressBoundsSet({ startAddress: middleLineStartAddress, endAddress: middleLineEndAddress }));
    }, [lineDatas, dispatch]);


    const navigateToAddress = useCallback((e: CustomEvent<number>) => {
        logInfo(`${Bytes.name} : navigating to address ${Utils.to4DigitHexString(e.detail)}`);
        const address = e.detail;
        const middleLineData = lineDatas.filter(l => l.baseAddress <= address && l.baseAddress + l.count > address).shift();
        if (middleLineData !== undefined) {
            const halfFirstItemHeight = lineDatas[0].heightPx / 2;
            const offsetAtMiddleOfLine = middleLineData.offsetPx + (middleLineData.heightPx / 2) - halfFirstItemHeight;
            document.dispatchEvent(new CustomEvent<number>('navigate', { detail: offsetAtMiddleOfLine }));
        }
    }, [lineDatas])

    // Subscribe to navigate-to-address
    useEffect(() => {
        document.addEventListener<any>('navigate-to-address', navigateToAddress);
        return () => document.removeEventListener<any>('navigate-to-address', navigateToAddress);
    }, [navigateToAddress])

    return (
        <>
            <CentralHighlight height={currentLineHeight} />
            <VirtualList
                itemSizes={itemSizes}
                totalItemSize={totalItemSize}
                renderItem={renderLine}
                setMiddleItem={setMiddleItem}
            />
        </>
    );
}

import { GraphicCommand } from '../commands/GraphicCommand';
import { MemoryLocation } from './MemoryLocation';
import { Range, DataRange, CodeRange, GraphicsRange } from './Range';

export enum LineType {
    Unknown,
    Code,
    Data,
    Graphics
}

export class ByteLineData {

    heightPx = 20;

    constructor(
        readonly type: LineType,
        readonly baseAddress: number,
        readonly count: number,
        readonly isUndefined: boolean,
        readonly offsetPx: number,
        extraHeight: number
    ) {
        this.heightPx += extraHeight;
    }
}

export class GraphicsLineData {

    heightPx = 20;

    constructor(
        readonly baseAddress: number,
        readonly count: number,
        readonly offsetPx: number
    ) { }
}


export class CodeLineData {

    heightPx = 20;

    constructor(
        readonly type: LineType,
        readonly baseAddress: number,
        readonly count: number,
        readonly offsetPx: number
    ) { }
}

export class GraphicsRangeData {
    type = LineType.Graphics;

    constructor(
        readonly heightPx: number,
        readonly baseAddress: number,
        readonly count: number,
        readonly offsetPx: number,
        readonly lineDatas: LineData[],
        readonly command: GraphicCommand
    ) { }
}

export class GapLineData {

    heightPx = 6;

    constructor(
        readonly type: LineType,
        readonly baseAddress: number,
        readonly count: number,
        readonly offsetPx: number
    ) { }
}

export class ElipsisLineData {
    heightPx = 20;
    constructor(
        readonly baseAddress: number,
        readonly count: number,
        readonly offsetPx: number
    ) { }
}

export class LabelLineData {

    readonly heightPx: number;
    readonly incomingReads: number[];
    readonly incomingWrites: number[];
    readonly incomingPointers: number[];
    readonly label: string;


    constructor(
        readonly type: LineType,
        memory: MemoryLocation[],
        readonly baseAddress: number,
        readonly endAddress: number,
        readonly count: number,
        readonly offsetPx: number,
        readonly isBig: boolean
    ) {
        this.incomingReads = memory[baseAddress].IncomingReads;
        this.incomingWrites = memory[baseAddress].IncomingWrites;
        this.incomingPointers = memory[baseAddress].IncomingPointers;
        this.label = memory[baseAddress].Label ?? '';
        this.heightPx = isBig ? 51 : 30;
    }
}

export class CommentLineData {
    heightPx = 21;

    constructor(
        readonly type: LineType,
        readonly baseAddress: number,
        readonly text: string,
        readonly offsetPx: number
    ) { }
}



export class BigLabelLineData {

    heightPx = 51;

    constructor(
        readonly type: LineType,
        readonly label: string,
        readonly baseAddress: number,
        readonly endAddress: number,
        readonly count: number,
        readonly offsetPx: number
    ) { }
}


export class BigCodeLabelLineData {

    heightPx = 51;

    constructor(
        readonly type: LineType,
        readonly label: string,
        readonly baseAddress: number,
        readonly count: number,
        readonly incomingBranches: number[],
        readonly incomingJMPs: number[],
        readonly incomingJSRs: number[],
        readonly incomingPointers: number[],
        readonly offsetPx: number
    ) { }
}


export class SmallCodeLabelLineData {

    heightPx = 30;

    constructor(
        readonly type: LineType,
        readonly label: string,
        readonly baseAddress: number,
        readonly count: number,
        readonly incomingBranches: number[],
        readonly incomingJMPs: number[],
        readonly incomingJSRs: number[],
        readonly offsetPx: number
    ) { }
}


export type LineData = CodeLineData | GraphicsRangeData | ByteLineData | GraphicsLineData | BigLabelLineData | BigCodeLabelLineData | SmallCodeLabelLineData | GapLineData | ElipsisLineData | CommentLineData;

export const borderWidthPx = 1;
export const marginPx = 3;



export class LineDataManager {

    /** Convert ranges to lines for rendering */
    static makeLineDatas(memory: MemoryLocation[], ranges: Range[], bytesPerLine: number) {

        const allRangesSorted: Range[] = ranges.slice().sort((a, b) => a.StartAddress - b.EndAddress);

        const lineDatas: LineData[] = [];

        let addressCounter = 0x0000;
        let offsetPx = 0;
        allRangesSorted.forEach(r => {

            // Is there any undefined space before this range?
            if (r.StartAddress > addressCounter) {
                offsetPx = this.makeLineDatasForData(LineType.Unknown, memory, new DataRange(`undefined-${r.StartAddress}`, addressCounter, r.StartAddress - 1), bytesPerLine, offsetPx, lineDatas);
            }

            // Make lines for the range itself
            if (r instanceof DataRange)
                offsetPx = this.makeLineDatasForData(LineType.Data, memory, r as DataRange, bytesPerLine, offsetPx, lineDatas);

            if (r instanceof GraphicsRange)
                offsetPx = this.makeLineDatasForGraphicsRange(r as GraphicsRange, bytesPerLine, offsetPx, lineDatas);

            if (r instanceof CodeRange)
                offsetPx = this.makeLineDatasForCodeRange(r as CodeRange, memory, offsetPx, lineDatas);

            addressCounter = r.EndAddress + 0x0001;
        });

        if (addressCounter < 0x10000) {
            offsetPx = this.makeLineDatasForData(LineType.Unknown, memory, new DataRange(`undefined-${addressCounter}`, addressCounter, 0xffff), bytesPerLine, offsetPx, lineDatas);
        }

        return lineDatas;
    }


    /** Convert a data range to lines for rendering */
    private static makeLineDatasForData(type: LineType, memory: MemoryLocation[], range: DataRange, bytesPerLine: number, offsetPx: number, lineDatas: LineData[]) {

        const startAddress = range.StartAddress;
        const endAddress = range.EndAddress;
        const isUndefined = (type === LineType.Unknown);

        const label = new LabelLineData(type, memory, startAddress, endAddress, 0, offsetPx, true);
        lineDatas.push(label);
        offsetPx += label.heightPx;

        const hasComment = memory[range.StartAddress].Comment != null;
        if (hasComment) {
            const commentAddress = range.StartAddress;
            const commentLines = memory[commentAddress].Comment ?? [];
            for (let index = 0; index < commentLines.length; index++) {
                const comment = new CommentLineData(type, commentAddress, commentLines[index], offsetPx);
                lineDatas.push(comment);
                offsetPx += comment.heightPx;
            }
        }

        // Range bytes
        let indexWithinLine = 0;
        for (let address = startAddress; address <= endAddress; address++) {

            const lineShouldWrapAfterThisByte = indexWithinLine === (bytesPerLine - 1);

            const thisByteHasFormatting = memory[address].Formatting !== 'None';
            const nextByteHasLabel = (address < endAddress) && memory[address + 1].Label != null;
            const nextByteHasComment = (address < endAddress) && memory[address + 1].Comment != null;
            const nextByteIsAPointer = (address < endAddress) && memory[address + 1].OutgoingPointer != null;
            const thisByteIsAShortPointer = (memory[address].OutgoingPointer != null) && (memory[address].OutgoingPointer?.part !== '16bit');
            const prevByteIsALongPointer = (address > 0x0000) && memory[address - 1].OutgoingPointer?.part === '16bit';
            const nextByteIsPointedAt = (address < endAddress) && memory[address + 1].IncomingPointers.length > 0;
            const thisByteIsPointedAt = (address < endAddress) && memory[address].IncomingPointers.length > 0;
            const nextByteHasReadOrWriteRefs = (address < endAddress) && ((memory[address + 1].IncomingReads.length > 0) || (memory[address + 1].IncomingWrites.length > 0));
            const thisByteHasReadOrWriteRefs = (address < endAddress) && ((memory[address].IncomingReads.length > 0) || (memory[address].IncomingWrites.length > 0));
            const lineShouldBreak =
                thisByteHasFormatting
                || nextByteHasLabel
                || nextByteHasComment
                || nextByteIsAPointer
                || thisByteIsAShortPointer
                || prevByteIsALongPointer
                || nextByteIsPointedAt
                || thisByteIsPointedAt
                || nextByteHasReadOrWriteRefs
                || thisByteHasReadOrWriteRefs
                ;

            const lineShouldEnd = address === endAddress;

            if (lineShouldWrapAfterThisByte || lineShouldBreak || lineShouldEnd) {
                const lineStartAddress = address - indexWithinLine;
                const gapInRange = ['GapAfter', 'ManualGapAfter'].indexOf(memory[address].Formatting) !== -1;
                const extraHeight = (gapInRange || nextByteHasLabel || nextByteHasComment) ? 8 : 0;
                const byteLineData = new ByteLineData(type, lineStartAddress, indexWithinLine + 1, isUndefined, offsetPx, extraHeight);
                lineDatas.push(byteLineData);
                offsetPx += byteLineData.heightPx;

                if (nextByteHasLabel) {
                    const labelAddress = address + 1;
                    const label = new LabelLineData(type, memory, labelAddress, 0, 0, offsetPx, false);
                    lineDatas.push(label);
                    offsetPx += label.heightPx;
                }

                if (nextByteHasComment) {
                    const commentAddress = address + 1;
                    const commentLines = memory[commentAddress].Comment ?? [];
                    for (let index = 0; index < commentLines.length; index++) {
                        const comment = new CommentLineData(type, commentAddress, commentLines[index], offsetPx);
                        lineDatas.push(comment);
                        offsetPx += comment.heightPx;
                    }
                }

                indexWithinLine = 0;
            }
            else {
                indexWithinLine++;
            }
        }

        return offsetPx;
    }


    /** Convert a data range to lines for rendering */
    private static makeLineDatasForGraphicsRange(range: GraphicsRange, bytesPerLine: number, offsetPx: number, lineDatas: LineData[]) {

        const { Name, StartAddress, EndAddress, Type } = range;

        // Range label
        const friendlyName = `${Name} (${Type})`;
        const labelLineData = new BigLabelLineData(LineType.Graphics, friendlyName, StartAddress, EndAddress, 0, offsetPx);
        lineDatas.push(labelLineData);
        offsetPx += labelLineData.heightPx;

        const localLineDatas: LineData[] = [];
        let localOffsetPx = 0;

        const graphicHeightPx = (range.Command.heightPx * range.Command.scale) + 3;

        // Range bytes
        let hasSkippedLines = false;
        for (let address = StartAddress; address <= EndAddress; address += bytesPerLine) {

            const remainingBytes = (EndAddress + 1) - address;

            const filledUp = (localOffsetPx + 20 + 20) >= graphicHeightPx;
            const isLastLine = (remainingBytes <= bytesPerLine);

            if (filledUp && !isLastLine) {
                hasSkippedLines = true;
                continue;
            }

            if (isLastLine && hasSkippedLines) {
                const gapLineData = new ElipsisLineData(address, EndAddress - StartAddress + 1, localOffsetPx);
                localLineDatas.push(gapLineData);
                localOffsetPx += gapLineData.heightPx;
            }

            const count = isLastLine ? remainingBytes : bytesPerLine;
            const byteLineData = new GraphicsLineData(address, count, localOffsetPx);

            localLineDatas.push(byteLineData);
            localOffsetPx += byteLineData.heightPx;
        }

        const contentsHeight = Math.max(localOffsetPx, graphicHeightPx);

        const heightPx = contentsHeight + (marginPx + borderWidthPx + borderWidthPx + marginPx);
        const countBytes = range.EndAddress - range.StartAddress + 1;
        lineDatas.push(new GraphicsRangeData(heightPx, range.StartAddress, countBytes, offsetPx, localLineDatas, range.Command));

        return offsetPx + heightPx;
    }

    /** Convert a code range to lines for rendering */
    private static makeLineDatasForCodeRange(range: CodeRange, memory: MemoryLocation[], offsetPx: number, lineDatas: LineData[]) {

        const labelText = memory[range.StartAddress].Label ?? 'undefined';
        const hasLabel = memory[range.StartAddress].Label != null;
        const hasComment = memory[range.StartAddress].Comment != null;
        const startAddress = range.StartAddress;
        const endAddress = range.EndAddress;

        const isBranchedTo = range.Branches.length > 0;
        const isJMPedTo = range.JMPs.length > 0;
        const isJSRedTo = range.JSRs.length > 0;
        const isFlowedTo = range.Flow != null;

        let needsBigLabel = false;
        let needsSmallLabel = false;

        // What components do we want at the start of the range
        if (!isFlowedTo) {
            if (!isBranchedTo)
                needsBigLabel = true;
            else
                needsSmallLabel = true;
        }
        else {
            if (isBranchedTo || isJMPedTo || isJSRedTo || hasLabel)
                needsSmallLabel = true;
        }

        if (needsBigLabel) {
            const label = new BigCodeLabelLineData(LineType.Code, labelText, startAddress, 0, range.Branches, range.JMPs, range.JSRs, range.Pointers, offsetPx)
            lineDatas.push(label);
            offsetPx += label.heightPx;
        }

        if (needsSmallLabel) {
            const label = new SmallCodeLabelLineData(LineType.Code, labelText, startAddress, 0, range.Branches, range.JMPs, range.JSRs, offsetPx);
            lineDatas.push(label);
            offsetPx += label.heightPx;
        }

        if (hasComment) {
            const commentAddress = range.StartAddress;
            const commentLines = memory[commentAddress].Comment ?? [];
            for (let index = 0; index < commentLines.length; index++) {
                const comment = new CommentLineData(LineType.Code, commentAddress, commentLines[index], offsetPx);
                lineDatas.push(comment);
                offsetPx += comment.heightPx;
            }
        }

        // Range code
        let indexWithinLine = 0;
        for (let address = startAddress; address <= endAddress; address++) {

            const lineShouldBreak = memory[address].Formatting !== 'None';
            const nextByteHasLabel = (address < endAddress) && memory[address + 1].Label != null;
            const nextByteHasComment = (address < endAddress) && memory[address + 1].Comment != null;
            const lineShouldEnd = address === endAddress;
            if (lineShouldBreak || lineShouldEnd || nextByteHasLabel || nextByteHasComment) {

                const lineStartAddress = address - indexWithinLine;
                const lineByteLength = indexWithinLine + 1;
                const codeLineData = new CodeLineData(LineType.Code, lineStartAddress, lineByteLength, offsetPx);

                lineDatas.push(codeLineData);
                offsetPx += codeLineData.heightPx;

                indexWithinLine = 0;

                if (nextByteHasLabel) {
                    const labelAddress = address + 1;
                    const labelText = memory[labelAddress].Label ?? '[unknown]';
                    const labelPointers = memory[labelAddress].IncomingPointers;
                    const label = new SmallCodeLabelLineData(LineType.Code, labelText, labelAddress, 0, labelPointers, [], [], offsetPx);
                    lineDatas.push(label);
                    offsetPx += label.heightPx;
                }

                if (nextByteHasComment) {
                    const commentAddress = address + 1;
                    const commentLines = memory[commentAddress].Comment ?? [];
                    for (let index = 0; index < commentLines.length; index++) {
                        const comment = new CommentLineData(LineType.Code, commentAddress, commentLines[index], offsetPx);
                        lineDatas.push(comment);
                        offsetPx += comment.heightPx;
                    }
                }

            }
            else {
                indexWithinLine++;
            }
        }

        return offsetPx;
    }

}
import { MemoryLocation } from "./MemoryLocation";
import { Utils } from "../Utils";
import { Palette } from '../Palette';
import { OpCodes } from './OpCodes';
import { Range, CodeRange } from './Range';
import { logWarning } from "../Logger";

export type EntryPointData = {
    address: number,
    addressesDiscovered: number,
    discoveredBy?: number
}

export type Note = {
    address: number,
    type: 'info' | 'warning' | 'error' | 'debug',
    note: string
}

export const setDefaultLabels = (memory: MemoryLocation[]) => {
    memory[0x0314].Label = "<IRQ";
    memory[0x0315].Label = ">IRQ";

    memory[0xd000].Label = "VICII Sprite 0 X-pos lo";
    memory[0xd001].Label = "VICII Sprite 0 Y-pos";
    memory[0xd002].Label = "VICII Sprite 1 X-pos lo";
    memory[0xd003].Label = "VICII Sprite 1 Y-pos";
    memory[0xd004].Label = "VICII Sprite 2 X-pos lo";
    memory[0xd005].Label = "VICII Sprite 2 Y-pos";
    memory[0xd006].Label = "VICII Sprite 3 X-pos lo";
    memory[0xd007].Label = "VICII Sprite 3 Y-pos";
    memory[0xd008].Label = "VICII Sprite 4 X-pos lo";
    memory[0xd009].Label = "VICII Sprite 4 Y-pos";
    memory[0xd00a].Label = "VICII Sprite 5 X-pos lo";
    memory[0xd00b].Label = "VICII Sprite 5 Y-pos";
    memory[0xd00c].Label = "VICII Sprite 6 X-pos lo";
    memory[0xd00d].Label = "VICII Sprite 6 Y-pos";
    memory[0xd00e].Label = "VICII Sprite 7 X-pos lo";
    memory[0xd00f].Label = "VICII Sprite 7 Y-pos";
    memory[0xd010].Label = "VICII Sprites X-pos hi";
    memory[0xd011].Label = "VICII Screen control 1";
    memory[0xd012].Label = "VICII Current raster line";
    memory[0xd013].Label = "VICII Light pen X-pos";
    memory[0xd014].Label = "VICII Light pen Y-pos";
    memory[0xd015].Label = "VICII Enable sprites";
    memory[0xd016].Label = "VICII Screen control 2";
    memory[0xd017].Label = "VICII Sprites expand Y";
    memory[0xd018].Label = "VICII Memory control";
    memory[0xd019].Label = "VICII Interrupt flag";
    memory[0xd01a].Label = "VICII Interrupt mask";
    memory[0xd01b].Label = "VICII Sprite-bg priority";
    memory[0xd01c].Label = "VICII Sprites multicolour";
    memory[0xd01d].Label = "VICII Sprites expand X";
    memory[0xd01e].Label = "VICII Sprite-sprite col";
    memory[0xd01f].Label = "VICII Sprite-bg col";
    memory[0xd020].Label = "VICII Border col";
    memory[0xd021].Label = "VICII Background col 0";
    memory[0xd022].Label = "VICII Background col 1";
    memory[0xd023].Label = "VICII Background col 2";
    memory[0xd024].Label = "VICII Background col 3";
    memory[0xd025].Label = "VICII Sprite multicol 1";
    memory[0xd026].Label = "VICII Sprite multicol 2";
    memory[0xd027].Label = "VICII Sprite 0 colour";
    memory[0xd028].Label = "VICII Sprite 1 colour";
    memory[0xd029].Label = "VICII Sprite 2 colour";
    memory[0xd02a].Label = "VICII Sprite 3 colour";
    memory[0xd02b].Label = "VICII Sprite 4 colour";
    memory[0xd02c].Label = "VICII Sprite 5 colour";
    memory[0xd02d].Label = "VICII Sprite 6 colour";
    memory[0xd02e].Label = "VICII Sprite 7 colour";

    memory[0xd400].Label = "SID Voice 1 <freq control";
    memory[0xd401].Label = "SID Voice 1 >freq control";
    memory[0xd402].Label = "SID Voice 1 <pulse width";
    memory[0xd403].Label = "SID Voice 1 >pulse width";
    memory[0xd404].Label = "SID Voice 1 control";
    memory[0xd405].Label = "SID Voice 1 AD";
    memory[0xd406].Label = "SID Voice 1 SR";
    memory[0xd407].Label = "SID Voice 2 <freq control";
    memory[0xd408].Label = "SID Voice 2 >freq control";
    memory[0xd409].Label = "SID Voice 2 <pulse width";
    memory[0xd40a].Label = "SID Voice 2 >pulse width";
    memory[0xd40b].Label = "SID Voice 2 control";
    memory[0xd40c].Label = "SID Voice 2 AD";
    memory[0xd40d].Label = "SID Voice 2 SR";
    memory[0xd40e].Label = "SID Voice 3 <freq control";
    memory[0xd40f].Label = "SID Voice 3 >freq control";
    memory[0xd410].Label = "SID Voice 3 <pulse width";
    memory[0xd411].Label = "SID Voice 3 >pulse width";
    memory[0xd412].Label = "SID Voice 3 control";
    memory[0xd413].Label = "SID Voice 3 AD";
    memory[0xd414].Label = "SID Voice 3 SR";
    memory[0xd415].Label = "SID <Filter cutoff frequency";
    memory[0xd416].Label = "SID >Filter cutoff frequency";
    memory[0xd417].Label = "SID Filter/voices control";
    memory[0xd418].Label = "SID Filter mode + volume";
    memory[0xd419].Label = "SID Paddle 1 adc";
    memory[0xd41A].Label = "SID Paddle 2 adc";
    memory[0xd41B].Label = "SID Oscillator 3 rng";
    memory[0xd41C].Label = "SID Envelope 3 output";

    // 0xdc10 - 0xdcff : CIA1 registers are mirrored every 0x10 bytes
    memory[0xdc00].Label = "CIA1 Data port A";
    memory[0xdc01].Label = "CIA1 Data port B";
    memory[0xdc02].Label = "CIA1 Data dir port A";
    memory[0xdc03].Label = "CIA1 Data dir port B";
    memory[0xdc04].Label = "CIA1 Timer A lo";
    memory[0xdc05].Label = "CIA1 Timer A hi";
    memory[0xdc06].Label = "CIA1 Timer B lo";
    memory[0xdc07].Label = "CIA1 Timer B hi";
    memory[0xdc08].Label = "CIA1 Realtime clock 1/10s";
    memory[0xdc09].Label = "CIA1 Realtime clock seconds";
    memory[0xdc0a].Label = "CIA1 Realtime clock minutes";
    memory[0xdc0b].Label = "CIA1 Realtime clock hours";
    memory[0xdc0c].Label = "CIA1 Serial shift";
    memory[0xdc0d].Label = "CIA1 IRQ Control + status";
    memory[0xdc0e].Label = "CIA1 Timer A control";
    memory[0xdc0f].Label = "CIA1 Timer B control";

    // 0xdd10 - 0xddff : CIA2 registers are mirrored every 0x10 bytes
    memory[0xdd00].Label = "CIA2 Data port A";
    memory[0xdd01].Label = "CIA2 Data port B";
    memory[0xdd02].Label = "CIA2 Data dir port A";
    memory[0xdd03].Label = "CIA2 Data dir port B";
    memory[0xdd04].Label = "CIA2 Timer A lo";
    memory[0xdd05].Label = "CIA2 Timer A hi";
    memory[0xdd06].Label = "CIA2 Timer B lo";
    memory[0xdd07].Label = "CIA2 Timer B hi";
    memory[0xdd08].Label = "CIA2 Realtime clock 1/10s";
    memory[0xdd09].Label = "CIA2 Realtime clock seconds";
    memory[0xdd0a].Label = "CIA2 Realtime clock minutes";
    memory[0xdd0b].Label = "CIA2 Realtime clock hours";
    memory[0xdd0c].Label = "CIA2 Serial shift";
    memory[0xdd0d].Label = "CIA2 NMI Control + status";
    memory[0xdd0e].Label = "CIA2 Timer A control";
    memory[0xdd0f].Label = "CIA2 Timer B control";
}

export class MemoryManager {


    /** Get the memory array object */
    initialiseMemory() {

        const memory: MemoryLocation[] = [];
        for (let i = 0; i < 0x10000; i++) {
            memory.push(new MemoryLocation(0x00));
        }

        return memory;
    }


    /** Get the colour RAM object */
    initialiseColourRAM() {

        const colValue = Palette.LightBlue.index;
        const colourRAM: number[] = [];
        for (let i = 0; i < 0x03e8; i++) {
            colourRAM.push(colValue);
        }

        return colourRAM;
    }


    /** Sets the byte values of a section of memory */
    setMemoryFromBytes(memory: MemoryLocation[], bytes: Uint8Array, startAddress: number) {

        const count = bytes.length;

        // Early out if the data is dodgy
        if (startAddress + count > 0x10000) {
            return memory;
        }

        // Shallow copy the array
        memory = memory.slice();

        // Replace the bytes
        for (let i = 0; i < count; i++) {
            memory[i + startAddress].Value = bytes[i];
        }

        // Return the new object
        return memory;
    }


    /** Sets colour ram values */
    setColourRAMFromBytes(colourRAM: number[], bytes: Uint8Array) {

        // Shallow copy the array
        colourRAM = colourRAM.slice();

        // Replace the bytes
        for (let i = 0; i < 0x03e8; i++) {
            colourRAM[i] = bytes[i];
        }

        // Return the new object
        return colourRAM;
    }


    /** Disassemble from an entry point address */
    disassemble(memory: MemoryLocation[], ranges: Range[], entryPoints: number[], entryPointsData: EntryPointData[], notes: Note[]) {

        const startTime = Date.now();

        const redundantEntryPoints: { address: number, discoveredBy: number }[] = [];
        const entryPointsWithSomeData: { address: number, addressesDiscovered: number }[] = [];

        // Disassemble from each entry point
        entryPoints.forEach(thisEntryPoint => {

            notes.push({ address: thisEntryPoint, type: 'info', note: `Disassembling from here` });

            // Seed the list of addresses to investigate with this initial entry point
            const addressesToInvestigate = [thisEntryPoint];

            // Keep track of what this entry point changed
            const addressesChangedByThisEntryPoint: number[] = [];

            // Disassemble (with an infinite loop check)
            let counter = 0x10000;
            while (addressesToInvestigate.length > 0) {

                // Get an address from the pool
                const addressToInvestigate = addressesToInvestigate.pop();
                if (addressToInvestigate === undefined) { continue; }

                // If this entrypoint has discovered another entrypoint, the latter is redundant
                if (addressToInvestigate !== thisEntryPoint && entryPoints.includes(addressToInvestigate)) {
                    redundantEntryPoints.push({ address: addressToInvestigate, discoveredBy: thisEntryPoint });
                }

                this.disassembleAddress(memory, thisEntryPoint, addressToInvestigate, addressesToInvestigate, addressesChangedByThisEntryPoint, notes);

                // Sanity check
                if (counter-- < 0) {
                    logWarning("Disassembler is caught in a loop");
                    break;
                }
            }

            entryPointsWithSomeData.push({ address: thisEntryPoint, addressesDiscovered: addressesChangedByThisEntryPoint.length });
        });

        // Match up redundant entrypoints with other data we gathered about each entrypoint
        entryPointsWithSomeData
            .map(entryPointWithSomeData => {
                const discovererOrUndefined = redundantEntryPoints.filter(redundantEntryPoint => redundantEntryPoint.address === entryPointWithSomeData.address)[0]?.discoveredBy;
                return { ...entryPointWithSomeData, discoveredBy: discovererOrUndefined };
            })
            .forEach(e => entryPointsData.push(e));

        // Post-process the memmory to find code ranges
        ranges.push(...this.findCodeRanges(memory));

        const duration = Date.now() - startTime;
        return duration;
    }


    /** Disassemble from an entry point address, keeping track of what addresses changed */
    private disassembleAddress(memory: MemoryLocation[], entrypoint: number, address: number, addressPool: number[], changedAddresses: number[], notes: Note[]) {

        // Info about this address
        const location = memory[address];

        const makeNotes = (location.EntryPoint === undefined);

        // Info about the following two addresses, if we need it later
        const location1 = memory[address + 1];
        const location2 = memory[address + 2];

        if (location1 === undefined || location2 === undefined) {
            if (makeNotes) { notes.push({ address, type: 'error', note: `Undefined memory at ${Utils.to4DigitHexString(address + 1)} or ${Utils.to4DigitHexString(address + 2)}` }) };
            return;
        }

        // Following two addresses interpreted as 8 and 16-bit addresses, if we need them later 
        const arg8 = location1.Value;
        const locationArg8 = memory[arg8];

        const arg16 = location1.Value + (location2.Value * 0x0100);
        const locationArg16 = memory[arg16];

        // Have we already visited this address?
        if (location.IsCode && location.EntryPoint === entrypoint) {
            notes.push({ address, type: 'info', note: `Went in a circle after XXX - exiting` });
            return;
        }

        // Have we already visited this address when it was an argument?
        if (location.IsArgument && location.EntryPoint === entrypoint) {
            notes.push({ address, type: 'warning', note: `Code flows to an existing argument from XXX` });
            return;
        }

        // Get the opcode at this address, and its details
        const opcode = OpCodes[location.Value];
        const byteLength = opcode.ByteLength;

        // Check it wouldn't overlap with an existing instruction (a sign of incorrect disassembly)
        const argsAreCode = (byteLength === 2 && location1.IsCode) || (byteLength === 3 && (location1.IsCode || location2.IsCode));
        if (argsAreCode) {
            if (makeNotes) { notes.push({ address, type: 'warning', note: `Argument(s) overlap existing code` }) };
        }

        // Mark this as code
        location.IsCode = true;
        location.EntryPoint = entrypoint;

        const mode = opcode.Mode;
        const flow = opcode.ProgramFlow;
        const opref = opcode.OperandReference;
        const nextAddress = address + byteLength;
        const locationNext = memory[nextAddress];

        if (location.Formatting !== 'None') {
            addressPool.push(address + 1);
        }


        // Deal with program flow
        let branchAddress = 0;
        switch (flow) {

            case 'Continue':

                // Add the next address to the pool
                addressPool.push(nextAddress);
                if (makeNotes) { notes.push({ address, type: 'debug', note: `Adding ${Utils.to4DigitHexString(nextAddress)} to list (continue)` }) };
                locationNext.IncomingFlow = address;
                break;


            case 'Branch':

                // Add the next address to the pool
                addressPool.push(nextAddress);
                if (makeNotes) { notes.push({ address, type: 'debug', note: `Adding ${Utils.to4DigitHexString(nextAddress)} to list (branch fail)` }) };
                locationNext.IncomingFlow = address;

                // Calculate the relative branch address and add it to the pool
                let branchAddressRelative = arg8;
                if (branchAddressRelative >= 128)
                    branchAddressRelative -= 256;
                branchAddress = address + branchAddressRelative + 2;
                addressPool.push(branchAddress);
                if (makeNotes) { notes.push({ address, type: 'info', note: `Adding ${Utils.to4DigitHexString(branchAddress)} to list (branch take)` }) };

                // Make a reference in the branched address back to here
                let locationBranch = memory[branchAddress];
                if (!locationBranch.IncomingBranches.includes(address)) {
                    locationBranch.IncomingBranches.push(address);
                }

                // Note that this address has outgoing refs
                location.HasOutgoingRefs = true;
                break;


            case 'GoSub':

                // Add the next address to the pool
                addressPool.push(nextAddress);
                if (makeNotes) { notes.push({ address, type: 'debug', note: `Adding ${Utils.to4DigitHexString(nextAddress)} to list (gosub continue)` }) };
                locationNext.IncomingFlow = address;

                // Add the referenced address to the pool
                addressPool.push(arg16);
                if (makeNotes) { notes.push({ address, type: 'info', note: `Adding ${Utils.to4DigitHexString(arg16)} to list (gosub)` }) };

                // Make a reference in the visited address back to here
                if (!locationArg16.IncomingJSRs.includes(address)) {
                    locationArg16.IncomingJSRs.push(address);
                }

                // Note that this address has outgoing refs
                location.HasOutgoingRefs = true;
                break;


            case 'GoTo':

                if (mode === 'Absolute') {

                    // Add the referenced address to the pool
                    addressPool.push(arg16);
                    if (makeNotes) { notes.push({ address, type: 'info', note: `Adding ${Utils.to4DigitHexString(arg16)} to list (jump)` }) };

                    // Make a reference in the visited address back to here
                    if (!locationArg16.IncomingJMPs.includes(address)) {
                        locationArg16.IncomingJMPs.push(address);
                    }
                }
                else {
                    if (makeNotes) { notes.push({ address, type: 'error', note: `Indirect jump at $${Utils.to4DigitHexString(address)}` }) };
                }

                // Note that this address has outgoing refs
                location.HasOutgoingRefs = true;
                break;


            case 'Stop':
            default:
                break;
        }


        // Store the calculated argument, to avoid recalculating it when rendering
        if (mode === 'Relative') {
            location.Argument = branchAddress;
            location.ArgumentLength = '16bit';
        }
        else if (mode !== 'Implicit' && mode !== 'Accumulator') {
            if (byteLength === 2) {
                location.Argument = arg8;
                location.ArgumentLength = '8bit';
            }
            if (byteLength === 3) {
                location.Argument = arg16;
                location.ArgumentLength = '16bit';
            }
        }


        // Deal with references
        if (opref === 'Read' || opref === 'ReadWrite') {
            if (byteLength === 2) {
                if (!locationArg8.IncomingReads.includes(address)) {
                    locationArg8.IncomingReads.push(address)
                }
                location.OutgoingRead = arg8;
            }
            if (byteLength === 3) {
                if (!locationArg16.IncomingReads.includes(address)) {
                    locationArg16.IncomingReads.push(address);
                }
                location.OutgoingRead = arg16;
            }
        }

        if (opref === 'Write' || opref === 'ReadWrite') {
            if (byteLength === 2) {
                if (!locationArg8.IncomingWrites.includes(address)) {
                    locationArg8.IncomingWrites.push(address)
                }
                location.OutgoingWrite = arg8;
            }
            if (byteLength === 3) {
                if (!locationArg16.IncomingWrites.includes(address)) {
                    locationArg16.IncomingWrites.push(address);
                }
                location.OutgoingWrite = arg16;
            }
        }


        // Keep track of changed addresses 
        for (let i = 0; i < byteLength; i++)
            changedAddresses.push(address + i);

        // Update counts and types
        if (argsAreCode || byteLength === 1)
            location.CountWithinBlock = 1;
        else if (byteLength === 2) {
            location.CountWithinBlock = 2;
            location1.CountWithinBlock = -1;
            location1.IsArgument = true;
            location1.EntryPoint = entrypoint;
        }
        else if (byteLength === 3) {
            location.CountWithinBlock = 3;
            location1.CountWithinBlock = -1;
            location2.CountWithinBlock = -2;
            location1.IsArgument = true;
            location1.EntryPoint = entrypoint;
            location2.IsArgument = true;
            location2.EntryPoint = entrypoint;
        }

        return;
    }


    findCodeRanges(memory: MemoryLocation[]) {

        const ranges: Range[] = [];

        const memoryLength = memory.length;
        let address = 0;
        const visitedAddresses: boolean[] = [];
        visitedAddresses.fill(false, 0, memoryLength);
        while (address < memoryLength) {

            if (visitedAddresses[address]) {
                logWarning(`Assembling ranges and found address ${Utils.to4DigitHexString(address)} again.`)
                break;
            }
            visitedAddresses[address] = true;

            // First of all, take this opportunity to sort reads & writes
            const locationStart = memory[address];
            locationStart.IncomingReads.sort();
            locationStart.IncomingWrites.sort();

            // If this isn't code, skip the rest
            if (!memory[address].IsCode) {
                address += memory[address].CountWithinBlock;
                continue;
            }

            // We've got code, so start a range
            const rangeStartAddress = address;
            const breakAfterThis = false;
            let isFirst = true;

            const branches = locationStart.IncomingBranches.slice().sort();
            const jmps = locationStart.IncomingJMPs.slice().sort();
            const jsrs = locationStart.IncomingJSRs.slice().sort();
            const flow = locationStart.IncomingFlow;
            const pointers = locationStart.IncomingPointers.slice().sort();

            // Keep going till we hit an opcode which would end this range
            while (!breakAfterThis && address < memoryLength) {

                const location = memory[address];
                if (!location.IsCode) { break; }

                // Save the number of bytes in this instruction
                const endOfLineAddress = address + location.CountWithinBlock - 1;
                memory[endOfLineAddress].Formatting = 'LineBreakAfter';

                const thisBranches = location.IncomingBranches.slice().sort();
                const thisJmps = location.IncomingJMPs.slice().sort();
                const thisJsrs = location.IncomingJSRs.slice().sort();

                // Break range before a JMP/JSR/branch target 
                if (!isFirst && (thisJmps.length > 0 || thisJsrs.length > 0 || thisBranches.length > 0))
                    break;

                isFirst = false;
                address += location.CountWithinBlock;

                // Break range if the flow stops/branches/gosubs here
                if (OpCodes[location.Value].ProgramFlow !== 'Continue')
                    break;
            }

            const isEntrypoint = rangeStartAddress === locationStart.EntryPoint;
            const needsLabel = jmps.length > 0 || jsrs.length > 0 || branches.length > 0 || isEntrypoint;

            // Assemble the range
            if (memory[rangeStartAddress].Label == null && needsLabel) {
                memory[rangeStartAddress].Label = 'L' + Utils.to4DigitHexString(rangeStartAddress);
            }

            const rangeEndAddress = address - 1;
            const range = new CodeRange(rangeStartAddress, rangeEndAddress, branches, jmps, jsrs, pointers, flow);
            ranges.push(range);
        }

        return ranges;
    }
}
import React, { useEffect, useRef } from 'react';
import styles from './ColourPicker.module.css';
import { pickingColourFinished, colourSet, selectPickingColour } from '../../store/graphicsSlice';
import { Colour, Palette } from '../../classes/Palette';
import { BigColourSwatch } from './ColourSwatch';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { logMajorComponentRender } from '../../classes/Logger';

export const ColourPicker: React.FunctionComponent = (props) => {

    logMajorComponentRender(ColourPicker.name);

    const dispatch = useAppDispatch();
    const colourPickerRef = useRef<HTMLTableElement>(null);
    const currentPickingColour = useAppSelector(selectPickingColour);

    useEffect(() => {
        const handleEscapeKey = (e: KeyboardEvent) => {
            if (e.key !== 'Escape') { return; }
            dispatch(pickingColourFinished());
        }

        const handleOutsideClick = (e: MouseEvent) => {
            if (colourPickerRef?.current?.contains(e.target as Element)) { return; }
            dispatch(pickingColourFinished());
        }

        document.addEventListener('click', handleOutsideClick, false);
        document.addEventListener('keyup', handleEscapeKey, false);

        return () => {
            document.removeEventListener('click', handleOutsideClick, false);
            document.removeEventListener('keyup', handleEscapeKey, false);
        };
    }, [colourPickerRef, dispatch])


    const makeSwatch = (c: Colour) => (
        <BigColourSwatch colour={c.index} onClick={() => {
            dispatch(colourSet({ colourIndex: c.index, property: currentPickingColour }));
            dispatch(pickingColourFinished());
        }} />
    );

    return (
        <>
            <div className={styles.background} />
            <table className={styles.picker} ref={colourPickerRef}>
                <tbody>
                    <tr>
                        <td>{makeSwatch(Palette.Black)}</td>
                        <td>{makeSwatch(Palette.White)}</td>
                        <td>{makeSwatch(Palette.Red)}</td>
                        <td>{makeSwatch(Palette.Cyan)}</td>
                        <td>{makeSwatch(Palette.Magenta)}</td>
                        <td>{makeSwatch(Palette.Green)}</td>
                        <td>{makeSwatch(Palette.Blue)}</td>
                        <td>{makeSwatch(Palette.Yellow)}</td>
                    </tr>
                    <tr>
                        <td>{makeSwatch(Palette.Orange)}</td>
                        <td>{makeSwatch(Palette.Brown)}</td>
                        <td>{makeSwatch(Palette.LightRed)}</td>
                        <td>{makeSwatch(Palette.DarkGrey)}</td>
                        <td>{makeSwatch(Palette.Grey)}</td>
                        <td>{makeSwatch(Palette.LightGreen)}</td>
                        <td>{makeSwatch(Palette.LightBlue)}</td>
                        <td>{makeSwatch(Palette.LightGrey)}</td>
                    </tr>
                </tbody>
            </table>
        </>
    );
}
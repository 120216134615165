import React from 'react';
import { ISettingProps } from './ISettingProps';
import styles from './Setting.module.css';
import { logMinorComponentRender } from '../../classes/Logger';


export interface IButtonSettingProps extends ISettingProps {
    buttonText: string;
    onClick: () => void;
}

export const ButtonSetting: React.FunctionComponent<IButtonSettingProps> = (props) => {

    logMinorComponentRender(ButtonSetting.name);

    return (
        <button className={styles.button} onClick={e => props.onClick()}>{props.buttonText}</button>
    );
}

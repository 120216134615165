import React from 'react';
import { Utils } from '../../classes/Utils';
import { SettingsSection } from './SettingsSection';
import { SettingsComment, SettingsGroup, SettingsRow } from './SettingsGroup';
import { commandAdded, formattingCleared, commandRemoved, commandUpdated, selectIndexedFormattingCommands, selectIndexedPointerCommands, selectIndexedDataCommands, selectIndexedLabelCommands, selectIndexedEntryPointCommands, selectMarkedUpMemory, selectIndexedCommentCommands } from '../../store/projectSlice';
import { HexNumberInput } from './HexNumberInput';
import { EnumSetting } from './EnumSetting';
import { ReferencePart } from '../../classes/code/MemoryLocation';
import { addressesAdded } from '../../store/codeSlice';
import { TextInput } from './TextInput';
import { DataCommand } from '../../classes/commands/DataCommand';
import { newEntryPointCommand } from '../../classes/commands/EntryPointHelpers';
import { newDataCommand } from '../../classes/commands/DataCommandHelpers';
import { newLabelCommand } from '../../classes/commands/LabelCommandHelpers';
import { newPointerCommand } from '../../classes/commands/PointerCommandHelpers';
import { selectSelection } from '../../store/toolSlice';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { logInfo, logMajorComponentRender } from '../../classes/Logger';
import { newCommentCommand } from '../../classes/commands/CommentCommandHelpers';


export const MarkupSettingsSection: React.FunctionComponent = () => {

    logMajorComponentRender(MarkupSettingsSection.name);

    const { selectionAddress, selectionCount } = useAppSelector(selectSelection);
    const selectionEndAddress = selectionAddress + selectionCount - 1;
    const dispatch = useAppDispatch();

    // Formatting
    const formattingCommands = useAppSelector(selectIndexedFormattingCommands)
        .filter(c => c.command.address >= selectionAddress && c.command.address <= selectionEndAddress);

    const canClearFormatting = formattingCommands.length > 0;

    const handleClearFormatting = () => {
        dispatch(formattingCleared({ startAddress: selectionAddress, endAddress: selectionEndAddress }));
    }


    // Pointers
    const { memory, durationMS } = useAppSelector(selectMarkedUpMemory);
    const pointerCommands = useAppSelector(selectIndexedPointerCommands)
        .filter(c =>
            (selectionCount === 1 && c.command.address === selectionAddress)
            ||
            (selectionCount === 1 && c.command.address === selectionAddress - 1 && c.command.part === '16bit')
            ||
            (selectionCount === 2 && c.command.address === selectionAddress && c.command.part === '16bit')
        );
    const pointerCommandIndex = pointerCommands.length === 1 ? pointerCommands[0].index : -1;
    const pointerCommand = pointerCommands.length === 1 ? pointerCommands[0].command : undefined;
    const hasPointer = pointerCommand != null;
    const target = hasPointer ? pointerCommand.target : 0x0000;
    const part = hasPointer ? pointerCommand.part : 'lo';

    const handleChangePointerTarget = (newTarget: number) => {
        if (!hasPointer) { return; }
        const newCommand = newPointerCommand(pointerCommand.address, newTarget, pointerCommand.part);
        dispatch(commandUpdated({ commandIndexToUpdate: pointerCommandIndex, command: newCommand }));
        dispatch(addressesAdded([selectionAddress]));
    }

    const handleChangePointerPart = (newPart: ReferencePart) => {
        if (!hasPointer) { return; }
        const newCommand = newPointerCommand(pointerCommand.address, pointerCommand.target, newPart);
        dispatch(commandUpdated({ commandIndexToUpdate: pointerCommandIndex, command: newCommand }));
        dispatch(addressesAdded([selectionAddress]));
    }

    const handleDeletePointer = () => {
        if (!hasPointer) { return; }
        dispatch(commandRemoved(pointerCommandIndex));
        dispatch(addressesAdded([selectionAddress]));
    }

    const handleAddPointer = () => {
        const isShort = (selectionCount === 1);

        const type = isShort ? 'lo' : '16bit';
        const target = isShort ? memory[selectionAddress].Value : memory[selectionAddress].Value + (0x100 * memory[selectionAddress + 1].Value);

        const command = newPointerCommand(selectionAddress, target, type);
        dispatch(commandAdded(command));
        dispatch(addressesAdded([selectionAddress]));
    }

    // Data ranges
    const selectedMemory = memory.slice(selectionAddress, selectionAddress + selectionCount); // end is *exclusive*
    const { codeCount, gfxCount, dataCount } = selectedMemory.reduce(
        (accumulator, currentValue) => {
            return {
                codeCount: accumulator.codeCount + ((currentValue.IsCode || currentValue.IsArgument) ? 1 : 0),
                gfxCount: accumulator.gfxCount + (currentValue.IsGfx ? 1 : 0),
                dataCount: accumulator.dataCount + (currentValue.IsData ? 1 : 0)
            }
        },
        { codeCount: 0, gfxCount: 0, dataCount: 0 }
    );

    const calculateEdges = (command?: DataCommand) => {
        const commandStart = (command != null) ? command.address : Number.MAX_SAFE_INTEGER;
        const commandEnd = (command != null) ? command.address + command.countBytes - 1 : Number.MIN_SAFE_INTEGER;

        const startsBeforeCommand = selectionAddress < commandStart;
        const endsWithinCommand = selectionEndAddress >= commandStart && selectionEndAddress <= commandEnd;
        const startsWithinCommand = selectionAddress >= commandStart && selectionAddress <= commandEnd;
        const endsAfterCommand = selectionEndAddress > commandEnd;

        const startsAtCommandStart = selectionAddress === commandStart;
        const endsAtCommandEnd = selectionEndAddress === commandEnd;

        return { startsBeforeCommand, endsWithinCommand, startsWithinCommand, endsAfterCommand, startsAtCommandStart, endsAtCommandEnd };
    }

    const dataCommands = useAppSelector(selectIndexedDataCommands);
    const touchedDataCommands = dataCommands.filter(c => {
        const { startsBeforeCommand, endsWithinCommand, startsWithinCommand, endsAfterCommand } = calculateEdges(c.command);

        return (startsBeforeCommand && endsWithinCommand)
            || (startsWithinCommand && endsWithinCommand)
            || (startsWithinCommand && endsAfterCommand)
            || (startsBeforeCommand && endsAfterCommand);
    });
    const isSingleDataCommand = (touchedDataCommands.length === 1);
    const touchedDataCommand = isSingleDataCommand ? touchedDataCommands[0] : undefined;
    const dataCommandName = touchedDataCommand?.command.name ?? 'unknown';
    const canCreateDataCommand = (codeCount === 0) && (gfxCount === 0) && (dataCount === 0) && (selectionCount > 0);
    const canDeleteDataCommand = isSingleDataCommand;

    const { startsBeforeCommand, endsWithinCommand, startsWithinCommand, endsAfterCommand, startsAtCommandStart, endsAtCommandEnd } = calculateEdges(touchedDataCommand?.command);
    const selectionOverlapsDataStart = startsBeforeCommand && endsWithinCommand;
    const selectionOverlapsDataEnd = startsWithinCommand && endsAfterCommand;
    const selectionAtStartOfCommand = startsAtCommandStart && endsWithinCommand;
    const selectionAtEndOfCommand = startsWithinCommand && endsAtCommandEnd;
    const canUnionDataCommand = isSingleDataCommand ? (selectionOverlapsDataStart || selectionOverlapsDataEnd) : false;
    const canTrimDataCommand = isSingleDataCommand ? (selectionAtStartOfCommand || selectionAtEndOfCommand) : false;

    const handleDataCommandNameChanged = (name: string) => {
        if (touchedDataCommand == null) { return; }

        const { address, countBytes } = touchedDataCommand.command;
        const cmd = newDataCommand(address, countBytes, name);
        dispatch(commandUpdated({ commandIndexToUpdate: touchedDataCommand.index, command: cmd }));
    }

    const handleDataCommandAdd = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();

        dispatch(commandAdded(newDataCommand(selectionAddress, selectionCount, "unnamed")));
        dispatch(addressesAdded([selectionAddress]));
    }

    const handleDeleteDataCommand = () => {
        if (touchedDataCommand == null) { return; }
        dispatch(commandRemoved(touchedDataCommand?.index));
    }

    const handleDataUnion = () => {
        if (touchedDataCommand == null) { return; }

        const newStartAddress = Math.min(touchedDataCommand.command.address, selectionAddress);
        const newEndAddress = Math.max(touchedDataCommand.command.address + touchedDataCommand.command.countBytes - 1, selectionEndAddress);
        const cmd = newDataCommand(newStartAddress, newEndAddress - newStartAddress + 1, touchedDataCommand.command.name);
        dispatch(commandUpdated({ commandIndexToUpdate: touchedDataCommand.index, command: cmd }));
    }

    const handleDataTrim = () => {
        if (touchedDataCommand == null) { return; }

        const { address, countBytes, name } = touchedDataCommand.command;
        const endAddress = address + countBytes - 1;

        if (selectionAtStartOfCommand && selectionAtEndOfCommand) {
            dispatch(commandRemoved(touchedDataCommand?.index));
        }
        else {
            const newStartAddress = selectionAtStartOfCommand ? selectionEndAddress + 1 : address;
            const newEndAddress = selectionAtStartOfCommand ? endAddress : selectionAddress - 1;
            const cmd = newDataCommand(newStartAddress, newEndAddress - newStartAddress + 1, name);
            dispatch(commandUpdated({ commandIndexToUpdate: touchedDataCommand.index, command: cmd }));
        }
    }


    // Labels
    const labelCommands = useAppSelector(selectIndexedLabelCommands);
    const touchedLabelCommand = labelCommands.filter(c => c.command.address === selectionAddress && selectionCount === 1).shift();
    const hasLabelCommand = touchedLabelCommand !== undefined;
    const labelCommandText = hasLabelCommand ? touchedLabelCommand.command.text : 'unnamed';
    const canCreateLabelCommand = !hasLabelCommand && (selectionCount === 1);
    const canDeleteLabelCommand = hasLabelCommand && (selectionCount === 1);

    const handleLabelCommandTextChanged = (text: string) => {
        if (touchedLabelCommand == null) { return; }

        const { address } = touchedLabelCommand.command;
        const cmd = newLabelCommand(address, text);
        dispatch(commandUpdated({ commandIndexToUpdate: touchedLabelCommand.index, command: cmd }));
    }

    const handleAddLabel = () => {
        dispatch(commandAdded(newLabelCommand(selectionAddress, 'unnamed')));
        dispatch(addressesAdded([selectionAddress]));
    }

    const handleDeleteLabel = () => {
        if (touchedLabelCommand == null) { return; }
        dispatch(commandRemoved(touchedLabelCommand.index));
    }

    // Comments
    const commentCommands = useAppSelector(selectIndexedCommentCommands);
    const touchedCommentCommand = commentCommands.filter(c => c.command.address === selectionAddress && selectionCount === 1).shift();
    const hasCommentCommand = touchedCommentCommand !== undefined;
    const commentCommandText = hasCommentCommand ? touchedCommentCommand.command.text : 'no comment';
    const canCreateCommentCommand = !hasCommentCommand && (selectionCount === 1);
    const canDeleteCommentCommand = hasCommentCommand && (selectionCount === 1);

    const handleCommentCommandTextChanged = (text: string) => {
        if (touchedCommentCommand == null) { return; }

        const { address } = touchedCommentCommand.command;
        const cmd = newCommentCommand(address, text);
        dispatch(commandUpdated({ commandIndexToUpdate: touchedCommentCommand.index, command: cmd }));
    }

    const handleAddComment = () => {
        dispatch(commandAdded(newCommentCommand(selectionAddress, 'no comment')));
        dispatch(addressesAdded([selectionAddress]));
    }

    const handleDeleteComment = () => {
        if (touchedCommentCommand == null) { return; }
        dispatch(commandRemoved(touchedCommentCommand.index));
    }

    // Entrypoints
    const entrypointCommands = useAppSelector(selectIndexedEntryPointCommands);
    const epTouchedCommand = entrypointCommands.filter(c => c.command.address === selectionAddress && selectionCount === 1).shift();
    const epHasCommand = epTouchedCommand !== undefined;
    const epName = `$${Utils.to4DigitHexString(selectionAddress)}`;

    const handleSubmitEntryPointCommand = (e: React.MouseEvent<HTMLButtonElement>) => {

        logInfo("Adding entrypoint : " + epName);
        dispatch(commandAdded(newEntryPointCommand(selectionAddress)));
        dispatch(addressesAdded([selectionAddress]));
    }


    return (
        <SettingsSection name="Markup">

            <SettingsGroup>
                <SettingsRow label="spacing">
                    <button onClick={handleClearFormatting} disabled={!canClearFormatting}>Clear</button>
                </SettingsRow>

                <SettingsRow label="pointer">
                    <HexNumberInput
                        enabled={hasPointer}
                        label="target"
                        number={target}
                        onChange={handleChangePointerTarget}
                        forceFourDigits={true}
                    />
                    <EnumSetting
                        label="part"
                        selectedOption={part}
                        options={{ '<': 'lo', '>': 'hi', '<>': '16bit' }}
                        order={['<', '>', '<>']}
                        onChange={handleChangePointerPart}
                    />
                    <button onClick={handleAddPointer} disabled={hasPointer || (selectionCount !== 1 && selectionCount !== 2)}>+</button>
                    <button onClick={handleDeletePointer} disabled={!hasPointer}>X</button>
                </SettingsRow>

                <SettingsRow label="data range">
                    <TextInput
                        enabled={isSingleDataCommand}
                        label="name"
                        text={dataCommandName}
                        long={true}
                        onChange={handleDataCommandNameChanged}
                    />
                    <button onClick={handleDataUnion} hidden={!canUnionDataCommand}>&gt;</button>
                    <button onClick={handleDataTrim} hidden={!canTrimDataCommand}>&lt;</button>
                    <button onClick={handleDataCommandAdd} hidden={!canCreateDataCommand}>+</button>
                    <button onClick={handleDeleteDataCommand} hidden={!canDeleteDataCommand}>X</button>
                </SettingsRow>

                <SettingsRow label="entry point">
                    <TextInput
                        enabled={false}
                        label="address"
                        text={epName}
                        onChange={s => { }}
                    />
                    <button onClick={handleSubmitEntryPointCommand} disabled={epHasCommand || selectionCount !== 1}>+</button>
                </SettingsRow>

                <SettingsRow label="label">
                    <TextInput
                        enabled={hasLabelCommand}
                        long={true}
                        label='add'
                        text={labelCommandText}
                        onChange={handleLabelCommandTextChanged}
                    />
                    <button onClick={handleAddLabel} disabled={!canCreateLabelCommand}>+</button>
                    <button onClick={handleDeleteLabel} disabled={!canDeleteLabelCommand}>X</button>
                </SettingsRow>

                <SettingsRow label="comment">
                    <TextInput
                        enabled={hasCommentCommand}
                        long={true}
                        label='add'
                        text={commentCommandText}
                        onChange={handleCommentCommandTextChanged}
                    />
                    <button onClick={handleAddComment} disabled={!canCreateCommentCommand}>+</button>
                    <button onClick={handleDeleteComment} disabled={!canDeleteCommentCommand}>X</button>
                </SettingsRow>

            </SettingsGroup>


            <SettingsComment>
                Disassembly took {durationMS}ms
            </SettingsComment>

        </SettingsSection>
    );


}
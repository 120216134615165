import React, { Dispatch } from 'react';
import { SettingsSection } from './SettingsSection'
import { SettingsGroup } from './SettingsGroup';
import { RootState } from '../../store';
import {
    ColourProperties, GraphicsState,
    characterMappedMainColourSourceSet,
    characterMappedMainColourAddressCOLRAMSet,
    characterMappedMainColourAddressRAMSet,
    interleavedMainColourSourceSet,
    interleavedMainColourAddressRAMSet,
    interleavedOtherColourSourceSet,
    interleavedOtherColourAddressCOLRAMSet,
    interleavedOtherColourAddressRAMSet

} from '../../store/graphicsSlice';
import { EnumSetting } from './EnumSetting';
import { ColourSetting } from './ColourSetting';
import { HexNumberInput } from './HexNumberInput';
import { ColourSource } from '../../classes/graphics/GraphicsEnums';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { logMajorComponentRender } from '../../classes/Logger';


const getColourSettings = (s: GraphicsState) => {

    let foreOrder: string[] = [];
    let foreSwatches: { [label: string]: { colour: number, prop: ColourProperties } } = {};
    let backOrder: string[] = [];
    let backSwatches: { [label: string]: { colour: number, prop: ColourProperties } } = {};

    switch (s.ViewLayout) {

        case 'CharacterMapped':

            switch (s.CharacterMappedMode) {
                case 'HiRes':

                    backOrder.push('0');
                    backSwatches['0'] = { colour: s.CharacterMappedHiresBackground00, prop: ColourProperties.CharacterMappedHiresBackground00 };

                    foreOrder.push('1');
                    if (s.CharacterMappedMainColourSource === 'Fixed') {
                        foreSwatches['1'] = { colour: s.CharacterMappedHiresForeground01, prop: ColourProperties.CharacterMappedHiresForeground01 };
                    }
                    break;

                case 'MultiColour':

                    backOrder.push('00');
                    backSwatches['00'] = { colour: s.CharacterMappedMultiColourBackground00, prop: ColourProperties.CharacterMappedMultiColourBackground00 };

                    foreOrder.push('01', '10', '11');
                    foreSwatches['01'] = { colour: s.CharacterMappedMultiColourForeground01, prop: ColourProperties.CharacterMappedMultiColourForeground01 };
                    foreSwatches['10'] = { colour: s.CharacterMappedMultiColourForeground10, prop: ColourProperties.CharacterMappedMultiColourForeground10 };
                    if (s.CharacterMappedMainColourSource === 'Fixed')
                        foreSwatches['11'] = { colour: s.CharacterMappedMultiColourForeground11, prop: ColourProperties.CharacterMappedMultiColourForeground11 };

                    break;

                case 'Extended':

                    backOrder.push('00', '01', '10', '11');
                    backSwatches['00'] = { colour: s.CharacterMappedExtendedColourBackground00, prop: ColourProperties.CharacterMappedExtendedColourBackground00 };
                    backSwatches['01'] = { colour: s.CharacterMappedExtendedColourBackground01, prop: ColourProperties.CharacterMappedExtendedColourBackground01 };
                    backSwatches['10'] = { colour: s.CharacterMappedExtendedColourBackground10, prop: ColourProperties.CharacterMappedExtendedColourBackground10 };
                    backSwatches['11'] = { colour: s.CharacterMappedExtendedColourBackground11, prop: ColourProperties.CharacterMappedExtendedColourBackground11 };

                    foreOrder.push('1');
                    if (s.CharacterMappedMainColourSource === 'Fixed')
                        foreSwatches['1'] = { colour: s.CharacterMappedExtendedColourForeground01, prop: ColourProperties.CharacterMappedExtendedColourForeground01 };

                    break;
            }

            break;

        case 'Interleaved':

            switch (s.InterleavedMode) {
                case 'HiRes':

                    backOrder.push('0');
                    foreOrder.push('1');

                    if (s.InterleavedMainColourSource === 'Fixed') {
                        backSwatches['0'] = { colour: s.InterleavedHiresBackground00, prop: ColourProperties.InterleavedHiresBackground00 };
                        foreSwatches['1'] = { colour: s.InterleavedHiresForeground01, prop: ColourProperties.InterleavedHiresForeground01 };
                    }

                    break;

                case 'MultiColour':

                    backOrder.push('00');
                    backSwatches['00'] = { colour: s.InterleavedMultiColourBackground00, prop: ColourProperties.InterleavedMultiColourBackground00 };

                    foreOrder.push('01', '10', '11');
                    if (s.InterleavedMainColourSource === 'Fixed') {
                        foreSwatches['01'] = { colour: s.InterleavedMultiColourForeground01, prop: ColourProperties.InterleavedMultiColourForeground01 };
                        foreSwatches['10'] = { colour: s.InterleavedMultiColourForeground10, prop: ColourProperties.InterleavedMultiColourForeground10 };
                    }
                    if (s.InterleavedOtherColourSource === 'Fixed') {
                        foreSwatches['11'] = { colour: s.InterleavedMultiColourForeground11, prop: ColourProperties.InterleavedMultiColourForeground11 };
                    }

                    break;

                case 'Extended':
                    break;
            }

            break;

        case 'Continuous':
        default:

            switch (s.ContinuousMode) {
                case 'HiRes':

                    backOrder.push('0');
                    backSwatches['0'] = { colour: s.ContinuousHiresBackground00, prop: ColourProperties.ContinuousHiresBackground00 };

                    foreOrder.push('1');
                    foreSwatches['1'] = { colour: s.ContinuousHiresForeground01, prop: ColourProperties.ContinuousHiresForeground01 };

                    break;

                case 'MultiColour':

                    backOrder.push('00');
                    backSwatches['00'] = { colour: s.ContinuousMultiColourBackground00, prop: ColourProperties.ContinuousMultiColourBackground00 };

                    foreOrder.push('01', '10', '11');
                    foreSwatches['01'] = { colour: s.ContinuousMultiColourForeground01, prop: ColourProperties.ContinuousMultiColourForeground01 };
                    foreSwatches['10'] = { colour: s.ContinuousMultiColourForeground10, prop: ColourProperties.ContinuousMultiColourForeground10 };
                    foreSwatches['11'] = { colour: s.ContinuousMultiColourForeground11, prop: ColourProperties.ContinuousMultiColourForeground11 };

                    break;
            }

            break;
    }

    return { foreOrder, foreSwatches, backOrder, backSwatches };
}


const getSourceSettings = (settings: GraphicsState, dispatch: Dispatch<any>) => {

    let mainLabel: string;
    let mainOrder: string[] = ['fixed', 'col ram', 'main ram'];
    let mainOptions: { [label: string]: ColourSource } = {};
    let mainSelectedOption: ColourSource;
    let onMainChange: (v: ColourSource) => void;
    let otherLabel: string;
    let otherOrder: string[] = ['fixed', 'col ram', 'main ram'];
    let otherOptions: { [label: string]: ColourSource } = {};
    let otherSelectedOption: ColourSource;
    let onOtherChange: (v: ColourSource) => void;

    switch (settings.ViewLayout) {
        case 'CharacterMapped':

            if (settings.CharacterMappedMode === 'HiRes') {
                mainLabel = '1 source';
            }
            else if (settings.CharacterMappedMode === 'MultiColour') {
                mainLabel = '11 source';
            }
            else {
                mainLabel = '1 source';
            }

            mainOptions['fixed'] = 'Fixed';
            mainOptions['col ram'] = 'ColourRAM';
            mainOptions['main ram'] = 'MainRAM';
            mainSelectedOption = settings.CharacterMappedMainColourSource;
            onMainChange = v => dispatch(characterMappedMainColourSourceSet(v));

            otherLabel = 'unused';
            otherSelectedOption = 'Fixed';
            onOtherChange = v => { };
            break;

        case 'Interleaved':

            if (settings.InterleavedMode === 'HiRes') {
                mainLabel = '0 / 1 source';
                mainOptions['fixed'] = 'Fixed';
                mainOptions['main ram'] = 'MainRAM';
                mainSelectedOption = settings.InterleavedMainColourSource;

                otherLabel = 'unused';
                otherSelectedOption = 'None';
            }
            else {
                mainLabel = '01 / 10 source';
                mainOptions['fixed'] = 'Fixed';
                mainOptions['main ram'] = 'MainRAM';
                mainSelectedOption = settings.InterleavedMainColourSource;

                otherLabel = '11 source';
                otherOptions['fixed'] = 'Fixed';
                otherOptions['col ram'] = 'ColourRAM';
                otherOptions['main ram'] = 'MainRAM';
                otherSelectedOption = settings.InterleavedOtherColourSource;
            }

            onMainChange = v => dispatch(interleavedMainColourSourceSet(v));
            onOtherChange = v => dispatch(interleavedOtherColourSourceSet(v));
            break;

        case 'Continuous':
        default:

            mainLabel = '1 source';

            mainOptions['fixed'] = 'Fixed';
            mainSelectedOption = 'Fixed';
            onMainChange = v => { };

            otherLabel = 'unused';
            otherSelectedOption = 'Fixed';
            onOtherChange = v => { };
            break;
    }

    return {
        mainLabel, mainOrder, mainOptions, mainSelectedOption, onMainChange,
        otherLabel, otherOrder, otherOptions, otherSelectedOption, onOtherChange
    };
}


const getMemorySettings = (settings: GraphicsState, dispatch: Dispatch<any>) => {

    let mainColourAddressRAMEnabled: boolean = false;
    let mainColourAddressRAM: number = 0x0000;
    let mainColourAddressRAMOnChange: (v: number) => void = v => { };
    let mainColourAddressCOLRAMEnabled: boolean = false;
    let mainColourAddressCOLRAM: number = 0x0000;
    let mainColourAddressCOLRAMOnChange: (v: number) => void = v => { };
    let otherColourAddressRAMEnabled: boolean = false;
    let otherColourAddressRAM: number = 0x0000;
    let otherColourAddressRAMOnChange: (v: number) => void = v => { };
    let otherColourAddressCOLRAMEnabled: boolean = false;
    let otherColourAddressCOLRAM: number = 0x0000;
    let otherColourAddressCOLRAMOnChange: (v: number) => void = v => { };

    switch (settings.ViewLayout) {
        case 'CharacterMapped':

            switch (settings.CharacterMappedMainColourSource) {
                case 'ColourRAM':
                    mainColourAddressCOLRAMEnabled = true;
                    break;
                case 'MainRAM':
                    mainColourAddressRAMEnabled = true;
                    break;
                case 'Fixed':
                default:
                    break;
            }

            if (mainColourAddressCOLRAMEnabled) {
                mainColourAddressCOLRAM = settings.CharacterMappedMainColourAddressCOLRAM;
                mainColourAddressCOLRAMOnChange = v => dispatch(characterMappedMainColourAddressCOLRAMSet(v));
            }

            if (mainColourAddressRAMEnabled) {
                mainColourAddressRAM = settings.CharacterMappedMainColourAddressRAM;
                mainColourAddressRAMOnChange = v => dispatch(characterMappedMainColourAddressRAMSet(v));
            }

            break;

        case 'Interleaved':

            switch (settings.InterleavedMainColourSource) {
                case 'MainRAM':
                    mainColourAddressRAMEnabled = true;
                    break;
                case 'Fixed':
                default:
                    break;
            }

            if (mainColourAddressRAMEnabled) {
                mainColourAddressRAM = settings.InterleavedMainColourAddressRAM;
                mainColourAddressRAMOnChange = v => dispatch(interleavedMainColourAddressRAMSet(v));
            }

            let showOtherSource = (settings.InterleavedMode === 'MultiColour');
            if (showOtherSource) {
                switch (settings.InterleavedOtherColourSource) {
                    case 'ColourRAM':
                        otherColourAddressCOLRAMEnabled = true;
                        break;
                    case 'MainRAM':
                        otherColourAddressRAMEnabled = true;
                        break;
                    case 'Fixed':
                    default:
                        break;
                }

                if (otherColourAddressCOLRAMEnabled) {
                    otherColourAddressCOLRAM = settings.InterleavedOtherColourAddressCOLRAM;
                    otherColourAddressCOLRAMOnChange = v => dispatch(interleavedOtherColourAddressCOLRAMSet(v));
                }

                if (otherColourAddressRAMEnabled) {
                    otherColourAddressRAM = settings.InterleavedOtherColourAddressRAM;
                    otherColourAddressRAMOnChange = v => dispatch(interleavedOtherColourAddressRAMSet(v));
                }
            }

            break;

        case 'Continuous':
        default:
            break;
    }

    return {
        mainColourAddressRAMEnabled, mainColourAddressRAM, mainColourAddressRAMOnChange,
        mainColourAddressCOLRAMEnabled, mainColourAddressCOLRAM, mainColourAddressCOLRAMOnChange,
        otherColourAddressRAMEnabled, otherColourAddressRAM, otherColourAddressRAMOnChange,
        otherColourAddressCOLRAMEnabled, otherColourAddressCOLRAM, otherColourAddressCOLRAMOnChange
    };
}


export const ColoursSettingsSection: React.FunctionComponent = () => {

    logMajorComponentRender(ColoursSettingsSection.name);

    const settings = useAppSelector((state: RootState) => state.graphics);
    const dispatch = useAppDispatch();

    const cols = getColourSettings(settings);
    const source = getSourceSettings(settings, dispatch);
    const mem = getMemorySettings(settings, dispatch);

    return (
        <SettingsSection name="Colours">

            <SettingsGroup>
                <EnumSetting
                    label={source.mainLabel}
                    selectedOption={source.mainSelectedOption}
                    options={source.mainOptions}
                    order={source.mainOrder}
                    onChange={source.onMainChange}
                />
                <HexNumberInput
                    label="COLRAM address"
                    number={mem.mainColourAddressCOLRAM}
                    forceFourDigits={true}
                    enabled={mem.mainColourAddressCOLRAMEnabled}
                    onChange={mem.mainColourAddressCOLRAMOnChange}
                />
                <HexNumberInput
                    label="RAM address"
                    number={mem.mainColourAddressRAM}
                    forceFourDigits={true}
                    enabled={mem.mainColourAddressRAMEnabled}
                    onChange={mem.mainColourAddressRAMOnChange}
                />
            </SettingsGroup>

            <SettingsGroup>
                <EnumSetting
                    label={source.otherLabel}
                    selectedOption={source.otherSelectedOption}
                    options={source.otherOptions}
                    order={source.otherOrder}
                    onChange={source.onOtherChange}
                />
                <HexNumberInput
                    label="COLRAM address"
                    number={mem.otherColourAddressCOLRAM}
                    forceFourDigits={true}
                    enabled={mem.otherColourAddressCOLRAMEnabled}
                    onChange={mem.otherColourAddressCOLRAMOnChange}
                />
                <HexNumberInput
                    label="RAM address"
                    number={mem.otherColourAddressRAM}
                    forceFourDigits={true}
                    enabled={mem.otherColourAddressRAMEnabled}
                    onChange={mem.otherColourAddressRAMOnChange}
                />
            </SettingsGroup>

            <SettingsGroup>
                <ColourSetting
                    label="fore colours"
                    swatches={cols.foreSwatches}
                    order={cols.foreOrder}
                />
                <ColourSetting
                    label="back colours"
                    swatches={cols.backSwatches}
                    order={cols.backOrder}
                />
            </SettingsGroup>

        </SettingsSection>
    );
}
export type ReferencePart = 'hi' | 'lo' | '16bit';

export class MemoryLocation {

    Value: number; // The byte value at this location
    CountWithinBlock: number; // +ve = it's the first location in a block of this length, -ve = jump back this many locations to reach the block's start
    Label?: string;
    Comment?: string[];
    Formatting: 'None' | 'LineBreakAfter' | 'GapAfter' | 'ManualLineBreakAfter' | 'ManualGapAfter';

    IsCode: boolean;
    IsArgument: boolean;
    IsData: boolean;
    IsGfx: boolean;

    Argument?: number; // The argument part of code at this location
    ArgumentLength: 'None' | '8bit' | '16bit'; // The argument type of code at this location
    EntryPoint?: number; // The entry point which discovered this address

    IncomingJMPs: number[]; // Addresses which JMP to this location
    IncomingJSRs: number[]; // Addresses which JSR to this location
    IncomingBranches: number[]; // Addresses which Bxx to this location
    IncomingFlow?: number; // Address which flowed here
    IncomingReads: number[]; // Addresses which read from this location
    IncomingWrites: number[]; // Addresses which write to this location
    IncomingPointers: number[]; // Addresses which point to this location

    OutgoingRead?: number; // Address which code at this location reads from
    OutgoingWrite?: number; // Address which code at this location writes to
    OutgoingPointer?: { part: ReferencePart, target: number }; // Address which a pointer at this location targets
    HasOutgoingRefs: boolean; // Does this location read or write to another address?
    OutgoingPointerIsValid: boolean; // Does this location's value match the pointer's target?


    constructor(value: number) {
        this.Value = value;
        this.CountWithinBlock = 1;
        this.Label = undefined;
        this.Comment = undefined;
        this.Formatting = "None";

        this.IsCode = false;
        this.IsArgument = false;
        this.IsData = false;
        this.IsGfx = false;

        this.Argument = undefined;
        this.ArgumentLength = 'None';
        this.EntryPoint = undefined;

        this.IncomingJMPs = [];
        this.IncomingJSRs = [];
        this.IncomingBranches = [];
        this.IncomingFlow = undefined;
        this.IncomingReads = [];
        this.IncomingWrites = [];
        this.IncomingPointers = [];

        this.OutgoingRead = undefined;
        this.OutgoingWrite = undefined;
        this.OutgoingPointer = undefined;
        this.HasOutgoingRefs = false;
        this.OutgoingPointerIsValid = false;
    }
}
import { Utils, splitStringToMaxWidthLines } from "../Utils";
import { MemoryLocation } from "../code/MemoryLocation";
import { Range } from "../code/Range";
import { CommentCommand } from "./CommentCommand";

const hex = Utils.to4DigitHexString;

export const version = "1";

export const newCommentCommand = (address: number, text: string): CommentCommand => {
    return {
        type: 'comment',
        address,
        text
    }
}

export const markUpMemory = (command: CommentCommand, memory: MemoryLocation[], ranges: Range[]) => {

    const css = 'font-family: "Source Sans Pro"; font-weight: 300; font-size: 1em; font-style: italic;';

    const lines = splitStringToMaxWidthLines(command.text, 580, css);
    memory[command.address].Comment = lines;
}

export const equals = (command: CommentCommand, otherCommand: CommentCommand): boolean => {

    let res = true;
    res &&= (command.address === otherCommand.address);
    res &&= (command.text === otherCommand.text);

    return res;
}

export const serialise = (command: CommentCommand): string => {
    return `cm|${hex(command.address)}|${command.text}`;
}

const deserialise_v01 = (components: string[]): CommentCommand | undefined => {
    if (components.length < 2) { return; }
    const address = Number(`0x${components[0]}`);
    const text = components[1];

    if (isNaN(address) || text == null) { return; }

    return newCommentCommand(address, text);
}

export const deserialisers: { [version: string]: (c: string[]) => CommentCommand | undefined } = {
    '1': deserialise_v01
};


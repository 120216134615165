import { PayloadAction, createSelector, createSlice } from "@reduxjs/toolkit";
import { ProjectDescription, makeGuid } from "../classes/ProjectDescription";
import { Command } from "../classes/commands/types";
import { FormattingCommand } from "../classes/commands/FormattingCommand";
import { newFormattingCommand, markUpMemory as markUpMemoryFromFormattingCommand } from "../classes/commands/FormattingCommandHelpers";
import { RootState } from ".";
import { Utils } from "../classes/Utils";
import { GraphicCommand } from "../classes/commands/GraphicCommand";
import { EntryPointCommand } from "../classes/commands/EntryPointCommand";
import { DataCommand } from "../classes/commands/DataCommand";
import { PointerCommand } from "../classes/commands/PointerCommand";
import { LabelCommand } from "../classes/commands/LabelCommand";
import { MemoryLocation } from "../classes/code/MemoryLocation";
import { newDataCommand, markUpMemory as markUpMemoryFromDataCommand } from "../classes/commands/DataCommandHelpers";
import { EntryPointData, MemoryManager, Note, setDefaultLabels } from "../classes/code/MemoryManager";
import { Range } from "../classes/code/Range";
import { markUpMemory as markupFromPointerCommand } from '../classes/commands/PointerCommandHelpers';
import { markUpMemory as markupFromGraphicCommand } from "../classes/commands/GraphicCommandHelpers";
import { markUpMemory as markupFromLabelCommand } from '../classes/commands/LabelCommandHelpers';
import { markUpMemory as markupFromCommentCommand } from '../classes/commands/CommentCommandHelpers';
import { logReducer, logSelector, logWarning } from "../classes/Logger";
import { CommentCommand } from "../classes/commands/CommentCommand";


export type ProjectState = {
    name: string;
    guid: string;
    RAM: number[];
    COLRAM: number[];
    commands: Command[];
    isCloud: boolean;
    dirty: boolean;
    currentCommandIndex?: number;
}

const initialState: ProjectState = {
    name: '',
    guid: makeGuid(),
    RAM: new Array(0x10000).fill(0),
    COLRAM: new Array(0x03e8).fill(0),
    commands: [],
    isCloud: false,
    dirty: false,
}

const projectSlice = createSlice({
    name: 'project',
    initialState,
    reducers: {
        nameSet(state, action: PayloadAction<string>) {
            logReducer('projectSlice', 'nameSet');
            state.name = action.payload;
            state.dirty = true;
        },

        guidSet(state, action: PayloadAction<string>) {
            logReducer('projectSlice', 'guidSet');
            state.guid = action.payload;
            state.dirty = true;
        },

        dirtySet(state, action: PayloadAction<boolean>) {
            logReducer('projectSlice', 'dirtySet');
            state.dirty = action.payload;
        },

        descriptionSet(state, action: PayloadAction<{ description: ProjectDescription, isCloud: boolean }>) {
            logReducer('projectSlice', 'descriptionSet');
            const { description } = action.payload;
            state.name = description.name;
            state.guid = description.guid;
            state.RAM = Array.from(description.RAM);
            state.COLRAM = Array.from(description.COLRAM);
            state.commands = description.commands;
            state.isCloud = action.payload.isCloud;
            state.dirty = false;
        },

        memorySet(state, action: PayloadAction<{ RAM: Uint8Array, COLRAM: Uint8Array }>) {
            logReducer('projectSlice', 'memorySet');
            state.RAM = Array.from(action.payload.RAM);
            state.COLRAM = Array.from(action.payload.COLRAM);
            state.dirty = true;
        },

        commandAdded(state, action: PayloadAction<Command>) {
            logReducer('projectSlice', 'commandAdded');
            state.commands.push(action.payload);
            state.dirty = true;
        },

        commandsAdded(state, action: PayloadAction<Command[]>) {
            logReducer('projectSlice', 'commandsAdded');
            state.commands.push(...action.payload);
            state.dirty = true;
        },

        commandUpdated(state, action: PayloadAction<{ commandIndexToUpdate: number, command: Command }>) {
            logReducer('projectSlice', 'commandUpdated');
            state.commands[action.payload.commandIndexToUpdate] = action.payload.command;
            state.dirty = true;
        },

        commandUpdatedById(state, action: PayloadAction<{ commandIdToUpdate: number, command: Command }>) {
            logReducer('projectSlice', 'commandUpdatedById');
            const index = state.commands.findIndex(c => c.type === 'graphic' && (c as GraphicCommand).id === action.payload.commandIdToUpdate);
            (action.payload.command as GraphicCommand).id = (state.commands[index] as GraphicCommand).id;
            state.commands[index] = action.payload.command;
            state.dirty = true;
        },

        commandRemoved(state, action: PayloadAction<number>) {
            logReducer('projectSlice', 'commandRemoved');
            state.commands.splice(action.payload, 1);

            state.dirty = true;
        },

        commandRemovedById(state, action: PayloadAction<number>) {
            const idToRemove = action.payload;
            logReducer('projectSlice', 'commandRemovedById', `with id ${idToRemove}`);

            const index = state.commands.findIndex(c => c.type === 'graphic' && (c as GraphicCommand).id === idToRemove);

            state.commands.splice(index, 1);

            if (idToRemove === state.currentCommandIndex) {
                state.currentCommandIndex = undefined;
            }

            state.dirty = true;
        },

        currentCommandIndexSet(state, action: PayloadAction<number | undefined>) {
            logReducer('projectSlice', 'currentCommandIndexSet', `with payload ${action.payload}`);
            state.currentCommandIndex = action.payload;
        },

        formattingToggled(state, action: PayloadAction<{ address: number, toggleType: boolean }>) {
            logReducer('projectSlice', 'formattingToggled');
            const { address, toggleType } = action.payload;

            const existingCommandIndex = state.commands.findIndex(c => c.type === 'formatting' && (c as FormattingCommand).address === address);

            if (existingCommandIndex === -1) {
                state.commands.push(newFormattingCommand(address, toggleType ? 'gap' : 'line-break'));
            } else {
                if (!toggleType) {
                    state.commands.splice(existingCommandIndex, 1);
                } else {
                    const toggledType = ((state.commands[existingCommandIndex] as FormattingCommand).format === 'line-break') ? 'gap' : 'line-break';
                    (state.commands[existingCommandIndex] as FormattingCommand).format = toggledType;
                }
            }

            state.dirty = true;
        },

        formattingCleared(state, action: PayloadAction<{ startAddress: number, endAddress: number }>) {
            logReducer('projectSlice', 'formattingCleared');
            const { startAddress, endAddress } = action.payload;
            state.commands = state.commands
                .filter(c => !((c.type === 'formatting') && (c.address >= startAddress && c.address <= endAddress)));

            state.dirty = true;
        }
    }
})

export const { nameSet, commandAdded, commandRemoved, commandRemovedById, commandUpdated, commandUpdatedById, commandsAdded, currentCommandIndexSet, descriptionSet, dirtySet, formattingCleared, formattingToggled, guidSet, memorySet } = projectSlice.actions;
export default projectSlice.reducer;

export const selectRAM = (state: RootState) => state.project.RAM;
export const selectCOLRAM = (state: RootState) => state.project.COLRAM;
export const selectCommands = (state: RootState) => state.project.commands;

export const selectProjectStatus = createSelector(
    (state: RootState) => state.project.isCloud,
    (state: RootState) => state.project.dirty,
    (isCloud, dirty) => {
        logSelector('projectSlice', 'selectProjectStatus');
        return { isCloud, dirty };
    }
)

export const selectCurrentCommandIndex = (state: RootState) => state.project.currentCommandIndex;

export const selectIndexedCommands = createSelector(
    selectCommands,
    commands => {
        logSelector('projectSlice', 'selectIndexedCommands');
        return commands
            .map((c, i) => { return { index: i, command: c } })
    }
)

export const selectIndexedGraphicsCommands = createSelector(
    selectCommands,
    commands => {
        logSelector('projectSlice', 'selectIndexedGraphicsCommands');
        return commands
            .map((c, i) => { return { index: i, command: c as GraphicCommand } })
            .filter(c => c.command.type === 'graphic')
    }
)

export const selectIndexedEntryPointCommands = createSelector(
    selectCommands,
    commands => {
        logSelector('projectSlice', 'selectIndexedEntryPointCommands');
        return commands
            .map((c, i) => { return { index: i, command: c as EntryPointCommand } })
            .filter(c => c.command.type === 'entry-point')
    }
)

export const selectIndexedDataCommands = createSelector(
    selectCommands,
    commands => {
        logSelector('projectSlice', 'selectIndexedDataCommands');
        return commands
            .map((c, i) => { return { index: i, command: c as DataCommand } })
            .filter(c => c.command.type === 'data')
    }
)

export const selectIndexedFormattingCommands = createSelector(
    selectCommands,
    commands => {
        logSelector('projectSlice', 'selectIndexedFormattingCommands');
        return commands
            .map((c, i) => { return { index: i, command: c as FormattingCommand } })
            .filter(c => c.command.type === 'formatting')
    }
)

export const selectIndexedPointerCommands = createSelector(
    selectCommands,
    commands => {
        logSelector('projectSlice', 'selectIndexedPointerCommands');
        return commands
            .map((c, i) => { return { index: i, command: c as PointerCommand } })
            .filter(c => c.command.type === 'pointer')
    }
)

export const selectIndexedLabelCommands = createSelector(
    selectCommands,
    commands => {
        logSelector('projectSlice', 'selectIndexedLabelCommands');
        return commands
            .map((c, i) => { return { index: i, command: c as LabelCommand } })
            .filter(c => c.command.type === 'label')
    }
)

export const selectIndexedCommentCommands = createSelector(
    selectCommands,
    commands => {
        logSelector('projectSlice', 'selectIndexedCommentCommands');
        return commands
            .map((c, i) => { return { index: i, command: c as CommentCommand } })
            .filter(c => c.command.type === 'comment')
    }
)

export const selectMarkedUpMemory = createSelector(
    selectRAM,
    selectCommands,
    (RAM, commands) => {
        logSelector('projectSlice', 'selectMarkedUpMemory');

        const memory = Array<MemoryLocation>(0x10000);
        for (let index = 0; index < memory.length; index++) {
            memory[index] = new MemoryLocation(RAM[index]);
        }

        setDefaultLabels(memory);

        const ranges = new Array<Range>();

        const colram = newDataCommand(0xd800, 0x03e8, "COLRAM");
        markUpMemoryFromDataCommand(colram, memory, ranges);

        commands
            .filter(c => ['data', 'entrypoint', 'formatting', 'graphic', 'label', 'pointer', 'comment'].indexOf(c.type) > -1)
            .forEach(c => {
                if (c.type === 'data') {
                    markUpMemoryFromDataCommand(c as DataCommand, memory, ranges)
                } else if (c.type === 'entrypoint') {

                } else if (c.type === 'formatting') {
                    markUpMemoryFromFormattingCommand(c as FormattingCommand, memory, ranges)
                } else if (c.type === 'graphic') {
                    markupFromGraphicCommand(c as GraphicCommand, memory, ranges);
                } else if (c.type === 'label') {
                    markupFromLabelCommand(c as LabelCommand, memory, ranges);
                } else if (c.type === 'pointer') {
                    markupFromPointerCommand(c as PointerCommand, memory, ranges);
                } else if (c.type === 'comment') {
                    markupFromCommentCommand(c as CommentCommand, memory, ranges);
                }
            });

        const entrypoints = commands.filter(c => c.type === 'entry-point').map(c => (c as EntryPointCommand).address);
        const entryPointsData: EntryPointData[] = [];
        const notes: Note[] = [];
        const durationMS = new MemoryManager().disassemble(memory, ranges, entrypoints, entryPointsData, notes);

        notes
            .filter(n => n.type === 'warning' || n.type === 'error')
            .map(n => logWarning(`$${Utils.to4DigitHexString(n.address)} : [${n.type}] ${n.note}`));

        return { memory, ranges, entryPointsData, durationMS };
    }
)

import React, { MouseEvent, useState } from 'react';
import { RootState } from '../store';
import { dirtySet, nameSet } from '../store/projectSlice';
import styles from './ProjectNamePanel.module.css';
import { FilePicker } from './SettingsPanel/FilePicker';
import { saveAsync } from '../classes/LocalStorageHelper';
import { saveAsync as saveCloudAsync } from '../classes/CloudStorageHelper';
import { Modal } from './SettingsPanel/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloud, faFloppyDisk } from '@fortawesome/free-solid-svg-icons';
import { newProjectDescription } from '../classes/ProjectDescriptionHelpers';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { logError, logMajorComponentRender } from '../classes/Logger';

export type IProjectNamePanel = {
    gridArea: string;
}

export const ProjectNamePanel: React.FunctionComponent<IProjectNamePanel> = (props) => {

    logMajorComponentRender(ProjectNamePanel.name);

    const project = useAppSelector((state: RootState) => state.project);
    const [modalIsOpen, SetModalIsOpen] = useState<boolean>(false);
    const dispatch = useAppDispatch();

    const handleNameChange = (name: string) => {

        if (name === project.name) { return; }

        dispatch(nameSet(name));
    }

    const handleOnSave = async () => {

        try {
            const t = Date.now();
            const saveMethod = project.isCloud ? saveCloudAsync : saveAsync;
            await saveMethod(newProjectDescription(project.name, project.guid, project.RAM, project.COLRAM, project.commands), t);
        } catch (error) {
            logError(String(error));
        }

        dispatch(dirtySet(false));
    }

    const handleCloseModal = () => {
        SetModalIsOpen(false);
    }

    const handleOnOpen = (e: MouseEvent) => {

        e.preventDefault();
        e.stopPropagation();
        SetModalIsOpen(true);
    }

    const saveButtonDisabled = (!project.dirty) || (project.name === '');
    const symbol = project.isCloud ? faCloud : faFloppyDisk;

    return (
        <>
            <div style={{ gridArea: props.gridArea }} className={styles.panel}>

                <div className={styles.row}>
                    <input
                        style={{ gridArea: 'name' }}
                        type="text"
                        value={project.name}
                        onChange={e => handleNameChange(e.target.value)}
                        className={styles.input}
                        placeholder="untitled"
                        spellCheck={false}
                    />
                    <button style={{ gridArea: 'save-button' }} onClick={handleOnSave} className={styles.button} disabled={saveButtonDisabled}><FontAwesomeIcon icon={symbol} /> save</button>
                    <button style={{ gridArea: 'open-button' }} onClick={handleOnOpen} className={styles.button} >open</button>
                </div>

                {modalIsOpen &&
                    <Modal onClose={handleCloseModal} style={{ backgroundColor: '#1e242e' }}>
                        <FilePicker onClose={handleCloseModal} />
                    </Modal>
                }
            </div>
        </>
    );
}
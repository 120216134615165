// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { initializeAppCheck, ReCaptchaEnterpriseProvider } from 'firebase/app-check';
import { getAnalytics, logEvent } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCtscOL2QNPJ2GaOP8iHLRQY4DIHOY1G2U",
  authDomain: "46c.io",
  databaseURL: "https://proj-46cio.firebaseio.com",
  projectId: "proj-46cio",
  storageBucket: "proj-46cio.appspot.com",
  messagingSenderId: "899853004931",
  appId: "1:899853004931:web:5fb6c9351fa9924caaebb6",
  measurementId: "G-M49S05ZJT9"
};

const isDebug = (window.location.hostname === "localhost");


// Initialize Firebase
// -------------------
const app = initializeApp(firebaseConfig);


// Setup AppCheck
// --------------
declare global {
  // eslint-disable-next-line no-var
  var FIREBASE_APPCHECK_DEBUG_TOKEN: boolean | string | undefined;
}

if (isDebug) {
  // eslint-disable-next-line no-restricted-globals
  self.FIREBASE_APPCHECK_DEBUG_TOKEN = true;
}
initializeAppCheck(app, {
  provider: new ReCaptchaEnterpriseProvider("6LeQfMIpAAAAAKUMPjFcmS2rESgIjftB6tipG8l0"),
  isTokenAutoRefreshEnabled: true
});


// Setup Analytics
// ---------------
const analytics = getAnalytics();

export const logAnalyticsEvent = (message: string) => {

  const isDevelopment = process.env.NODE_ENV === 'development';
  const pre = isDevelopment ? '[dev] ' : '';

  logEvent(analytics, `${pre}${message}`);
}
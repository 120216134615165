import React, { ReactNode } from 'react';
import { GraphicsViewport } from './graphics/GraphicsViewport';
import tabStyles from './SettingsPanelTab.module.css';
import previewStyles from './SettingsPanelTabWithPreview.module.css';
import { ViewportLocation } from '../store/rendererSlice';
import { logMajorComponentRender } from '../classes/Logger';

export interface ISettingsPanelWithPreviewTabProps {
    gridArea: string;
    settings?: ReactNode;
    preview?: ReactNode;

}

export const SettingsPanelWithPreviewTab: React.FunctionComponent<ISettingsPanelWithPreviewTabProps> = ((props) => {

    logMajorComponentRender(SettingsPanelWithPreviewTab.name);

    const panelStyle = `${tabStyles.panel} ${previewStyles.panel}`;
    const settingsStyle = `${tabStyles.scroll} ${previewStyles.settings}`;

    return (
        <div style={{ gridArea: props.gridArea, gridTemplateRows: `1fr 350px` }} className={panelStyle}>

            {/* <GraphicsViewport location={ViewportLocation.Settings} ignoreScrollbars={{ h: true, v: false }} show={{ marked: false, selected: false }} render={r =>
                <div className={settingsStyle} ref={r}>
                    <div className={tabStyles.inner}>
                        {props.settings}
                    </div>
                </div>
            } /> */}

            <div className={settingsStyle}>
                <div className={tabStyles.inner}>
                    {props.settings}
                </div>
            </div>

            <GraphicsViewport location={ViewportLocation.Preview} ignoreScrollbars={{ h: true, v: true }} show={{ marked: false, selected: false }} render={r =>
                <div className={previewStyles.preview} ref={r}>
                    {props.preview}
                </div>
            } />

        </div>
    );
});

export interface ISplitPanelWithFixedUpperProps {
    gridArea: string;
    upper: ReactNode;
    lower: ReactNode;
    upperHeight: string;
    scroll: boolean;
}

export const SplitPanelWithFixedUpper: React.FunctionComponent<ISplitPanelWithFixedUpperProps> = ((props) => {

    logMajorComponentRender(SplitPanelWithFixedUpper.name);

    const panelStyle = `${tabStyles.panel} ${previewStyles.panel}`;
    const upperStyle = `${props.scroll ? tabStyles.scroll : tabStyles.noscroll} ${previewStyles.settings}`;

    return (
        <div style={{ gridArea: props.gridArea, gridTemplateRows: `${props.upperHeight} 1fr` }} className={panelStyle}>

            <div className={upperStyle}>
                <div className={tabStyles.inner}>
                    {props.upper}
                </div>
            </div>

            <div className={previewStyles.preview}>
                {props.lower}
            </div>

        </div>
    );
});
